import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Row, Col, Checkbox, Icon, Popover, Menu, Button } from "antd";

// Branding icon
import BrandingLogo from "../../assets/img/tag_icon.svg";

import ActionBtn from "Core/API/ActionBtn";
import OrgsForm from "components/Forms/OrgsForm";
import OrgInOrgView from "components/Forms/OrgInOrgView";

import _ from "lodash";
import SearchInput from "components/UI-Components/SearchInput";
import {
  highlightSearchText,
  copyObject,
  getCurrentlocaleText,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import propTypes from "prop-types";
import OrgController from "controller/OrgController";
import CursorMovementInModal from "../CursorMovementInModal";
import TableLayoutV1 from "../layout/TableLayoutV1";

const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
};

@inject("AuthStore", "UiStore", "OrgViewModel")
@observer
class OrgsList extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.searchTimeout = null;
    this.state = {
      selectedOrg: {},
      selectedRowKeys: [], // for table to identify selected rows
      deletableOrgs: {
        orgs: [],
      },
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      expandedRowKeys: [],
      autoOpen: false,
      autoOpenOrgID: null,
      modelAction: "create",
      currOrgRecord: {},
      currentPage: 1,
      isTableModalToggle: false,
    };
  }

  componentDidMount() {
    if (this.props.isFromDashboard) {
      this.props.UiStore.showChildOrgs = true;
    }
    this.orgList(true);
    let pathname = location.pathname;

    setTimeout(() => {
      if (pathname) {
        let path = pathname.split("/");
        if (
          path[1] === "orgs" &&
          path[2] &&
          path[3] === "orgs" &&
          path[4] &&
          path[5] === "edit"
        ) {
          this.setState({
            autoOpen: true,
            autoOpenOrgID: path[4],
            modelAction: "edit",
          });
          this.getCurrentOrg(path[4]);
        } else if (
          path[1] === "orgs" &&
          path[2] &&
          path[3] === "orgs" &&
          path[4] &&
          path[5] === "view"
        ) {
          this.setState({
            autoOpen: true,
            autoOpenOrgID: path[4],
            modelAction: "view",
          });
          this.getCurrentOrg(path[4]);
        } else if (
          path[1] === "orgs" &&
          path[2] &&
          path[3] === "orgs" &&
          path[4] === "create"
        ) {
          this.setState({
            autoOpen: true,
            currOrgRecord: {},
            modelAction: "create",
          });
        } else {
          this.setState({ autoOpen: false, modelAction: "create" });
        }
      }
    }, 200);
  }

  getCurrentOrg = id => {
    if (id) {
      OrgController.getOrg(id, false, true).then(resp => {
        this.setState({ currOrgRecord: resp });
      });
    }
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId !== props.orgId) {
      this.orgList(
        true,
        props.params,
        0,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }
  }

  componentWillUnmount() {
    this.props.UiStore.orgParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };
  }

  orgList = (
    loading = true,
    qry_params = this.props.params,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    let params = copyObject(qry_params || {});
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (this.props.UiStore.showChildOrgs) delete params.own;
    else params.own = true;

    OrgController.getOrgs(params, loading);
  };

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.orgList(
      true,
      this.props.params,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.resetState();
    this.loadOrgState();
    if (this.props.match.params && this.props.match.params.id) {
      this.props.history.push(`/orgs/${this.props.match.params.id}/orgs`);
    }
  };

  onAfterClick = () => {
    if (this.props.match.params && this.props.match.params.id) {
      this.props.history.push(`/orgs/${this.props.match.params.id}/orgs`);
    }
  };

  deleteAfterCall = () => {
    let page = Math.floor(
      this.orgModel.orgs.length / this.orgModel.pageable.size,
    );
    // check for orgId and current deleted org id are same
    if (this.props.params.org_id === this.props.orgId) {
      // redirects to home page to avoid 404 api get
      this.props.history.push("/home");
    } else {
      this.orgList(
        true,
        this.props.params,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
      this.resetState();
      this.loadOrgState();
    }
  };

  setSelectedStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let id = selectedRows[i].id;

      this.setState(() => {
        let newObj = {
            deletableOrgs: {
              orgs: [],
            },
          },
          selectedOrg = this.state.selectedOrg,
          item = selectedRows[i],
          tempArray = [];

        selectedOrg[id] = {
          value: true,
          name: item.name,
          id: item.id,
        };

        //Temp array for deletable orgs list

        for (let i in selectedRows) {
          selectedOrg[selectedRows[i].id]
            ? tempArray.push(selectedOrg[selectedRows[i].id])
            : "";
        }

        // To set the value : false , when orgs is deselected and remove it from delete list

        !selected && selectedOrg[record.id]
          ? ((selectedOrg[record.id].value = false),
            (tempArray = _.filter(tempArray, val => {
              return val.id !== record.id;
            })))
          : null;

        newObj["selectedOrg"] = selectedOrg;
        newObj.deletableOrgs["orgs"] = tempArray;
        return newObj;
      });
    }
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };

  orgListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.props.UiStore.orgParams.searchText = searchText;
    this.searchTimeout = setTimeout(() => {
      this.orgList(
        false,
        this.props.params,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({
      searchText: "",
      currentPage: 1,
    });
    this.props.UiStore.orgParams.searchText = "";
    this.orgList(
      true,
      this.props.params,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.props.UiStore.orgParams.filterInfo = filter;
    this.props.UiStore.orgParams.sorterInfo = { sorter: sorter, sort: sort };
    this.orgList(
      true,
      this.props.params,
      page,
      this.state.searchText,
      sort.by,
      sort.order,
    );
  };

  loadOrgState = () => {
    if (this.props.loadOrgState) {
      OrgController.getOrg(this.props.orgId, false, true).then(resp => {
        if (resp.parent_organization)
          this.props.UiStore.parentOrgData = resp.parent_organization;
        this.props.UiStore.setOrgName(resp.name);
      });
    }
  };

  resetState = () => {
    this.setState({
      selectedOrg: {},
      selectedRowKeys: [],
      modelAction: "create",
      currOrgRecord: {},
      deletableOrgs: {
        orgs: [],
      },
    });
  };

  changeListview = e => {
    this.props.UiStore.showChildOrgs = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.orgList(
      true,
      this.props.params,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      e.target.checked,
    );
  };

  lazyLoad = () => {
    if (this.orgModel.pageable.next) {
      let page = this.orgModel.pageable.page + 1;
      this.orgList(
        true,
        this.props.params,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  expandRow = () => {
    let orgsData = this.props.isChildOrgs
      ? this.orgModel.childOrgsData
      : this.orgModel.orgsData;
    let keys = orgsData.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getMoreInfo(record) {
    return (
      <div>
        <Row>
          <Col span={24}>
            <strong className="mr-10">
              {getCurrentlocaleText("domain.name.text")} :{" "}
            </strong>
            {record.domain_name}
          </Col>
        </Row>
      </div>
    );
  }

  changeRoute = (resourse, action, id) => {
    if (action === "create" && this.props.match.params.id) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/orgs/${action}`,
      );
    } else if (action && this.props.match.params.id && id) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/orgs/${id}/${action}`,
      );
    }
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle:
        record && record.value && record.value.name ? record.value.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { selectedRowKeys, isTableModalToggle } = this.state;
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};

    let orgsData = this.props.isChildOrgs
      ? this.orgModel.childOrgsData
      : this.orgModel.orgsData;

    //table columns
    this.columns = [
      {
        title: (
          <span title={getCurrentlocaleText("orgs.tablelist.namecolumn.text")}>
            {getCurrentlocaleText("orgs.tablelist.namecolumn.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "22%",
        ellipsis: true,
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          return (
            <div className="col-wrap">
              <Link
                to={"/dashboard/" + record.id}
                onClick={e => e.stopPropagation()}
              >
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(text, this.state.searchText)
                  : text}
              </Link>
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("orgs.tablelist.c_d_column.text")}>
            {getCurrentlocaleText("orgs.tablelist.c_d_column.text")}
          </span>
        ),
        dataIndex: "billing_name",
        key: "billing_name",
        width: "20%",
        render: (text, record, index) => {
          return (
            <Popover
              content={<p>{record.billing_email}</p>}
              title="Billing Email"
              placement="topLeft"
              trigger="hover"
            >
              <div style={{ cursor: "pointer" }}>{record.billing_name}</div>
            </Popover>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("inodes.navigation_menu.text")}>
            {getCurrentlocaleText("inodes.navigation_menu.text")}
          </span>
        ),
        dataIndex: "inodes",
        key: "inodes",
        width: "10%",
        render: (text, record, index) => {
          return <div style={{ marginLeft: 15 }}>{record.inodes}</div>;
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("users.navigation_menu.text")}>
            {getCurrentlocaleText("users.navigation_menu.text")}
          </span>
        ),
        dataIndex: "user",
        key: "user",
        width: "10%",
        render: (text, record, index) => {
          return <div style={{ marginLeft: 15 }}>{record.user}</div>;
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("orgs.tablelist.secrets.text")}>
            {getCurrentlocaleText("orgs.tablelist.secrets.text")}
          </span>
        ),
        dataIndex: "secret_count",
        key: "secret_count",
        width: "10%",
        render: (text, record, index) => {
          return <div style={{ marginLeft: 15 }}>{record.secret_count}</div>;
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("columns.2fa.title")}>
            {getCurrentlocaleText("columns.2fa.title")}
          </span>
        ),
        dataIndex: "tfa",
        key: "tfa",
        width: "10%",
        render: (text, record, index) => {
          let x = record.tfa ? (
            <Icons
              type="ai"
              name="AiOutlineCheck"
              className={
                record.value &&
                record.value.policy &&
                record.value.policy.two_factor &&
                record.value.policy.two_factor.force &&
                record.value.policy.two_factor.force == true
                  ? "middle iot-primary-color-style"
                  : "middle"
              }
              style={{
                iconStyle,
              }}
            />
          ) : (
            "-"
          );

          return <span style={{ marginLeft: 15 }}>{x}</span>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "deleteOrg",
        width: "10%",
        render: (text, record, index) => {
          const view = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineEye" />{" "}
                    {getCurrentlocaleText(
                      "orgs.tablelist.action_button.view.text",
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                iconButton
                icon="AiOutlineEye"
                map={{ id: record.id }}
                resource="orgs"
                action="view"
                width={600}
                actionForm={OrgsForm}
                initialVal={record.value}
                afterCall={this.onAfterClick}
                changeRoute={this.changeRoute}
                disabled={!this.props.AuthStore.IsPermitted("ORG:VIEW")}
                controller={OrgController}
                iconBtnStyle={{ fontSize: 14, opacity: 0.7 }}
              >
                Close
              </ActionBtn>
            </span>
          );

          const edit = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" />{" "}
                    {getCurrentlocaleText(
                      "orgs.tablelist.action_button.edit.text",
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                iconButton
                icon="AiOutlineEdit"
                map={{ id: record.id }}
                resource="orgs"
                action="edit"
                width={600}
                actionForm={OrgsForm}
                initialVal={record.value}
                afterCall={this.onAfterClick}
                changeRoute={this.changeRoute}
                disabled={!this.props.AuthStore.IsPermitted("ORG:CREATE")}
                okText={
                  <span>
                    <span>
                      <Icons type="ai" name="AiOutlineEdit" />{" "}
                      {getCurrentlocaleText("update.text")}{" "}
                    </span>
                  </span>
                }
                controller={OrgController}
              />
            </span>
          );
          const del = (
            <span>
              {" "}
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      {
                        0: "Org",
                      },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      {
                        0: "Org",
                      },
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                iconButton
                icon="AiOutlineDelete"
                formData={record}
                map={{ id: record.id }}
                resource="orgs"
                action="delete"
                actionForm={OrgsForm}
                afterCall={this.deleteAfterCall.bind()}
                type={"high"}
                disabled={
                  !(
                    this.props.AuthStore.IsPermitted("ORG:DELETE") &&
                    !record.is_me
                  )
                }
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        "single.org.delete.confirmation.message",
                      )}
                    </p>
                  </span>
                }
                controller={OrgController}
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  {
                    0: "Org",
                  },
                )}
                warningMessage={getCurrentlocaleText(
                  "single.org.delete.warning.message",
                )}
                okText={
                  <span>
                    <Icon
                      style={{ margin: 0 }}
                      type="delete"
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0: "Org",
                    })}
                  </span>
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          );
          const branding = (
            <div className="action-btn">
              <Link
                to={
                  this.props.isFromDashboard
                    ? "/dashboard/" + record.id + "/branding"
                    : "/orgs/" + record.id + "/branding"
                }
              >
                <Button title={getCurrentlocaleText("branding.text")}>
                  <BrandingLogo
                    width="14"
                    height="14"
                    style={{ opacity: 0.5, marginTop: 7 }}
                  />
                </Button>
              </Link>
            </div>
          );
          const policies = (
            <div className="action-btn">
              <Link
                to={
                  this.props.isFromDashboard
                    ? "/dashboard/" + record.id + "/policies"
                    : "/orgs/" + record.id + "/policies"
                }
              >
                <Button title={getCurrentlocaleText("policies.text")}>
                  <Icons type="fa" name="FaWrench" />
                </Button>
              </Link>
            </div>
          );
          const menu = (
            <Menu component={CursorMovementInModal}>
              <Menu.Item key={0}>{view}</Menu.Item>
              <Menu.Item key={1}>{edit}</Menu.Item>
              {record.branding && <Menu.Item key={2}>{branding}</Menu.Item>}
              {this.props.AuthStore.IsPermitted("ORG:CREATE") && (
                <Menu.Item key={3}>{policies}</Menu.Item>
              )}
              <Menu.Item key={4}>{del}</Menu.Item>
            </Menu>
          );
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    if (
      !this.props.AuthStore.IsPermitted("SUPER:ADMIN") ||
      !this.props.AuthStore.IsPermitted("USER:ADMIN") ||
      !this.props.AuthStore.IsPermitted("ORG:SUPPORT")
    ) {
      /* show TFA for all orgs for admin and support only */
      let indx = null;
      this.columns.forEach((val, i) => {
        if (val.key.toLowerCase() === "tfa") {
          indx = i;
        }
      });
      indx && this.columns.splice(indx, 1);
    }
    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "50%" }}>
          <span className="headertext">
            {getCurrentlocaleText("general.child_orgs.label.text")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildOrgs}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("orgs.list.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              minWidth: 250,
            }}
          >
            <SearchInput
              placeholder={
                this.props.isChildOrgs
                  ? "Filter child orgs by name"
                  : "Filter orgs by name"
              }
              onChange={this.orgListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",

              justifyContent: "flex-end",
            }}
          >
            {this.props.AuthStore.IsPermitted("ORG:CREATE") &&
              !this.props.isFromDashboard && (
                <ActionBtn
                  type="primary"
                  title={
                    <span>
                      {this.state.modelAction == "create" && (
                        <span>
                          <Icons type="fa" name="FaSitemap" />{" "}
                          {getCurrentlocaleText("org.create.button_title.text")}
                        </span>
                      )}

                      {this.state.modelAction == "edit" && (
                        <span>
                          <Icons type="fa" name="FaSitemap" />{" "}
                          {getCurrentlocaleText(
                            "orgs.tablelist.action_button.edit.text",
                          )}
                        </span>
                      )}

                      {this.state.modelAction == "view" && (
                        <span>
                          <Icons type="fa" name="FaSitemap" />{" "}
                          {getCurrentlocaleText(
                            "orgs.tablelist.action_button.view.text",
                          )}
                        </span>
                      )}
                    </span>
                  }
                  resource="orgs"
                  icon="AiOutlinePlus"
                  iconButton
                  action={this.state.modelAction}
                  map={{ id: this.state.currOrgRecord.id }}
                  initialVal={this.state.currOrgRecord}
                  autoOpenModel={this.state.autoOpen}
                  width={600}
                  orgId={this.props.orgId}
                  actionForm={OrgsForm}
                  changeRoute={this.changeRoute}
                  afterCall={this.createAfterCall}
                  controller={OrgController}
                >
                  {this.state.modelAction == "create" && (
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlinePlus"
                        style={{ margin: 0 }}
                      />{" "}
                      {getCurrentlocaleText("org.create.button_title.text")}
                    </span>
                  )}

                  {this.state.modelAction == "edit" && (
                    <span>
                      <Icons type="ai" name="AiOutlineEdit" />{" "}
                      {getCurrentlocaleText("update.text")}{" "}
                    </span>
                  )}

                  {this.state.modelAction == "view" && <span>Close</span>}
                </ActionBtn>
              )}
            {/* {this.props.AuthStore.IsPermitted("ORG:DELETE") &&
              !this.props.isFromDashboard && (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineDelete" /> Delete Org
                    </span>
                  }
                  orgId={this.props.orgId}
                  icon="AiOutlineDelete"
                  formData={this.state.deletableOrgs.orgs}
                  iconButton
                  resource="orgs"
                  action="delete"
                  afterCall={this.deleteAfterCall}
                  disabled={this.state.deletableOrgs.orgs.length === 0}
                  isChildOrg={this.props.isChildOrgs}
                  controller={OrgController}
                  type="high"
                  cancelText={getCurrentlocaleText(
                    "delete.confirmation.cancel.text",
                  )}
                >
                  <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                  {getCurrentlocaleText("delete.confirmation.ok.text")}
                </ActionBtn>
              )} */}
            {this.props.AuthStore.IsPermitted("ORG:VIEW") && (
              <ActionBtn
                title={
                  <span>
                    <Icons
                      type="fa"
                      name="FaSitemap"
                      style={{ fontSize: 16 }}
                    />{" "}
                    Organization Hierarchy
                  </span>
                }
                icon="info-circle"
                orgId={this.props.orgId}
                formData={this.state.deletableOrgs.orgs}
                iconButton={false}
                resource="orgs"
                actionForm={OrgInOrgView}
                action="view"
                width={500}
                footer={null}
                controller={OrgController}
              >
                <Icons
                  type="fa"
                  name="FaSitemap"
                  style={{ margin: 0, fontSize: 14 }}
                />
              </ActionBtn>
            )}
          </div>
        </div>
        {(this.props.isChildOrgs ? (
          this.orgModel.childOrgsData.length > 0
        ) : (
          this.orgModel.orgs.length > 0
        )) ? (
          <div>
            <TableLayoutV1
              columns={this.columns}
              dataSource={orgsData}
              pagination={{
                pageSize: this.orgModel && this.orgModel.pageable.size,
                total: this.orgModel && this.orgModel.pageable.total_count,
                current: this.state.currentPage,
                pageSizeOptions: [25, 50, 100],
              }}
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              hideRowSelection={true}
              selectedRows={this.handleTableChange}
              selectAll={this.onSelectAllChange}
              onChange={this.onTableChange}
              handleModalOk={e => this.handleModalOk(e)}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              isModalLoading={false}
              disableFooter={true}
              className="orgList"
            />
          </div>
        ) : (
          <div>
            {!this.orgModel.loading &&
              (this.props.isChildOrgs ? (
                <h3>No child orgs to display</h3>
              ) : (
                <h3>No orgs to display</h3>
              ))}
          </div>
        )}
      </div>
    );

    return (
      <LoadingComponent loading={this.orgModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

OrgsList.propTypes = {
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
  orgId: propTypes.string,
};

export default OrgsList;
