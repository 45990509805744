import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { observer, inject } from "mobx-react";

import { Row, Col, Menu } from "antd";
import InodesList from "components/DataLists/InodesList";
import OrgsList from "components/DataLists/OrgsList";

import OrgUsers from "components/DataLists/OrgUsers";

import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import LoadingComponent from "components/UI-Components/LoadingComponent";

import SerialList from "components/DataLists/SerialList";
import CertificateList from "components/DataLists/CertificateList";
import RolesList from "components/DataLists/RolesList";

import LicenseComponent from "./License";
import NetworkList from "./Networks";
import ServiceList from "./Services";
import FirewallList from "components/DataLists/FirewallList";
import Icons from "components/UI-Components/Icons";

import ServiceListenerList from "../DataLists/ServiceListenerList";

import OrgController from "controller/OrgController";
import { getCurrentlocaleText } from "Core/Utils";
import OrgSelector from "components/Orgs/OrgSelector";
import propTypes from "prop-types";
import ActivityList from "components/DataLists/ActivityList";
import ClusterController from "controller/ClusterController";

@inject(
  "UiStore",
  "AuthStore",
  "OrgViewModel",
  "RoleViewModel",
  "InodeViewModel",
)
@observer
class OrgDetails extends Component {
  constructor(props) {
    super(props);
    this.AuthStore = this.props.AuthStore;
    this.uistore = this.props.UiStore;
    this.orgModel = this.props.OrgViewModel;
    this.roleModel = this.props.RoleViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.TAB_KEYS = {
      default: props.AuthStore.IsPermitted("ORG:VIEW") ? "orgs" : "users",
      inodes: "inodes",
      users: "users",
      roles: "roles",
      orgs: "orgs",
      serials: "serials",
      certificates: "certificates",
      licenses: "licenses",
      securitypolicies: "securitypolicies",
      networks: "networks",
      serial: "serial",
      pod: "services",
      peer: "peer",
      volumes: "volumes",
      activities: "activities",
      servicelistener: "servicelistener",
    };
    this.BreadCrumb_Keys = {
      default: props.AuthStore.IsPermitted("ORG:VIEW")
        ? getCurrentlocaleText("general.child_orgs.label.text")
        : getCurrentlocaleText("users.navigation_menu.text"),
      inodes: getCurrentlocaleText("orgs.details.tabs.inodes.text"),
      users: getCurrentlocaleText("users.navigation_menu.text"),
      roles: getCurrentlocaleText("general.roles.label.text"),
      orgs: getCurrentlocaleText("general.child_orgs.label.text"),
      serials: getCurrentlocaleText("general.assets_serialnumber.label.text"),
      licenses: getCurrentlocaleText("general.service_secrets.label.text"),
      volumes: getCurrentlocaleText("general.volumes.label.text"),
      securitypolicies: getCurrentlocaleText("general.component.firewall.text"),
      networks: getCurrentlocaleText("networks.tabheader.label.text"),
      serial: getCurrentlocaleText("general.assets_serialnumber.label.text"),
      certificates: getCurrentlocaleText(
        "general.assets_certificates.label.text",
      ),
      services: getCurrentlocaleText(
        "node.list.table.node_services.label.text",
      ),
      pod: getCurrentlocaleText("node.list.table.node_services.label.text"),
      peer: "Peer",
      activities: getCurrentlocaleText("activity.text"),
      servicelistener: getCurrentlocaleText(
        "node.list.table.node_service_listener.label.text",
      ),
    };
    this.state = {
      tabKey: this.TAB_KEYS.default,
      BreadcrumbList: [],
    };
  }

  renderSearchResults = () => {
    if (this.state.orglist && this.state.orglist.length > 0) {
      return this.state.orglist.map((item, i) => {
        return (
          <Menu.Item key={item.id} style={menuItem}>
            <NavLink to={this.getCurrentRoute(item.id)}>
              {
                <span>
                  <span style={{ lineHeight: "1.5", width: 100 }}>
                    {item.name}
                  </span>
                </span>
              }
            </NavLink>
          </Menu.Item>
        );
      });
    }
  };

  UNSAFE_componentWillMount() {
    let paths = this.props.location.pathname.split("/");
    let tabKey = paths[paths.length - 1];

    // for cluster iNode status navigation
    if (paths.length > 4) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "create" &&
      paths[paths.length - 2] === "users"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 1] === "bulkconfig" &&
      paths[paths.length - 2] === "users"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 2] === "upload" &&
      paths[paths.length - 2] === "bulkconfig" &&
      paths[paths.length - 3] === "users"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 2] === "view-history" &&
      paths[paths.length - 2] === "bulkconfig" &&
      paths[paths.length - 3] === "users"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "bulkconfig" &&
      paths[paths.length - 2] === "inodes"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 2] === "upload" &&
      paths[paths.length - 2] === "bulkconfig" &&
      paths[paths.length - 3] === "inodes"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 2] === "view-history" &&
      paths[paths.length - 2] === "bulkconfig" &&
      paths[paths.length - 3] === "inodes"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "bulkconfig" &&
      paths[paths.length - 2] === "networks"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 2] === "upload" &&
      paths[paths.length - 2] === "bulkconfig" &&
      paths[paths.length - 3] === "networks"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 2] === "view-history" &&
      paths[paths.length - 2] === "bulkconfig" &&
      paths[paths.length - 3] === "networks"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "create" &&
      paths[paths.length - 2] === "securitypolicies"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 1] === "create" &&
      paths[paths.length - 2] === "roles"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 1] === "create" &&
      paths[paths.length - 2] === "inodes"
    ) {
      tabKey = paths[paths.length - 2];
    }
    if (
      paths[paths.length - 1] === "edit" &&
      paths[paths.length - 3] === "users"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "edit" &&
      paths[paths.length - 3] === "securitypolicies"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "edit" &&
      paths[paths.length - 3] === "roles"
    ) {
      tabKey = paths[paths.length - 3];
    }
    if (
      paths[paths.length - 1] === "view" &&
      paths[paths.length - 3] === "roles"
    ) {
      tabKey = paths[paths.length - 3];
    }

    if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
      this.setState({ tabKey: tabKey });
      if (tabKey === "services") {
        this.inodeModel.resetInodes();
      }
    }
    this.loadInitalState(
      this.props.match.params.id
        ? this.props.match.params.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "",
    );
  }

  componentDidMount() {
    this.props.UiStore.SetStoreData("currentRouteLocation", location.pathname);
  }

  loadInitalState(orgId) {
    // Get selected org info
    if (orgId === undefined) {
      orgId =
        this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore.loggedInUser.organization
          ? this.props.AuthStore.loggedInUser.organization.id
          : "";
    }

    OrgController.getOrg(orgId).then(res => {
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.cluster_id
      ) {
        if (this.props.location && !this.props.location.state) {
          ClusterController.getCluster(this.props.match.params.cluster_id).then(
            cluster_response => {
              this.setState({
                orgDetails: res,
                BreadcrumbList: [
                  {
                    name: this.orgModel.org.name,
                    link: "dashboard/" + orgId,
                  },
                  {
                    name: "Clusters",
                    link: "orgs/" + orgId + "/clusters",
                  },
                  {
                    name: cluster_response.name,
                    link:
                      "orgs/" +
                      orgId +
                      "/clusters/" +
                      this.props.match.params.cluster_id,
                  },
                  {
                    name: this.BreadCrumb_Keys[this.state.tabKey],
                    link: "orgs/" + orgId + "/" + this.state.tabKey,
                  },
                ],
              });
              this.props.UiStore.setOrgName(this.orgModel.org.name);
              if (
                !res.parent_organization ||
                (res.parent_organization && !res.parent_organization.name)
              ) {
                OrgController.getOrgById(orgId, true).then(res => {
                  if (res.parent_organization && res.parent_organization.id) {
                    this.props.UiStore.parentOrgData = res.parent_organization;
                  }
                });
              } else {
                this.props.UiStore.parentOrgData = res.parent_organization;
              }
            },
          );
        } else {
          this.setState({
            orgDetails: res,
            BreadcrumbList: [
              {
                name: this.orgModel.org.name,
                link: "dashboard/" + orgId,
              },
              {
                name: "Clusters",
                link: "orgs/" + orgId + "/clusters",
              },
              {
                name: this.props.location.state.cluster_name,
                link:
                  "orgs/" +
                  orgId +
                  "/clusters/" +
                  this.props.match.params.cluster_id,
              },
              {
                name: this.BreadCrumb_Keys[this.state.tabKey],
                link: "orgs/" + orgId + "/" + this.state.tabKey,
              },
            ],
          });
          this.props.UiStore.setOrgName(this.orgModel.org.name);
          if (
            !res.parent_organization ||
            (res.parent_organization && !res.parent_organization.name)
          ) {
            OrgController.getOrgById(orgId, true).then(res => {
              if (res.parent_organization && res.parent_organization.id) {
                this.props.UiStore.parentOrgData = res.parent_organization;
              }
            });
          } else {
            this.props.UiStore.parentOrgData = res.parent_organization;
          }
        }
      } else {
        this.setState({
          orgDetails: res,
          BreadcrumbList: [
            {
              name: this.orgModel.org.name,
              link: "dashboard/" + orgId,
            },
            {
              name: this.BreadCrumb_Keys[this.state.tabKey],
              link: "orgs/" + orgId + "/" + this.state.tabKey,
            },
          ],
        });
        this.props.UiStore.setOrgName(this.orgModel.org.name);
        if (
          !res.parent_organization ||
          (res.parent_organization && !res.parent_organization.name)
        ) {
          OrgController.getOrgById(orgId, true).then(res => {
            if (res && res.parent_organization && res.parent_organization.id) {
              this.props.UiStore.parentOrgData = res.parent_organization;
            }
          });
        } else {
          this.props.UiStore.parentOrgData = res.parent_organization;
        }
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      let paths = nextProps.location.pathname.split("/");
      let tabKey = paths[paths.length - 1];
      if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
        this.setState({
          tabKey: tabKey,
          BreadcrumbList: [
            {
              name: this.orgModel.org.name,
              link: "dashboard/" + nextProps.match.params.id,
            },
            {
              name: this.BreadCrumb_Keys[tabKey],
              link: "orgs/" + nextProps.match.params.id + "/" + tabKey,
            },
          ],
        });
        this.updateCurrentOrg(nextProps.match.params.id);
        if (tabKey === "services") {
          this.inodeModel.resetInodes();
        }
      } else if (
        paths[paths.length - 1] === "create" &&
        paths[paths.length - 2] === "users"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "create" &&
        paths[paths.length - 2] === "securitypolicies"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "create" &&
        paths[paths.length - 2] === "roles"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "create" &&
        paths[paths.length - 2] === "inodes"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "edit" &&
        paths[paths.length - 3] === "users"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "edit" &&
        paths[paths.length - 3] === "securitypolicies"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "edit" &&
        paths[paths.length - 3] === "roles"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "view" &&
        paths[paths.length - 3] === "securitypolicies"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "view" &&
        paths[paths.length - 3] === "roles"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "bulkconfig" &&
        paths[paths.length - 2] === "users"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "upload" &&
        paths[paths.length - 2] === "bulkconfig" &&
        paths[paths.length - 3] === "users"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "view-history" &&
        paths[paths.length - 2] === "bulkconfig" &&
        paths[paths.length - 3] === "users"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "bulkconfig" &&
        paths[paths.length - 2] === "inodes"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "upload" &&
        paths[paths.length - 2] === "bulkconfig" &&
        paths[paths.length - 3] === "inodes"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "view-history" &&
        paths[paths.length - 2] === "bulkconfig" &&
        paths[paths.length - 3] === "inodes"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "bulkconfig" &&
        paths[paths.length - 2] === "networks"
      ) {
        this.setState({ tabKey: paths[paths.length - 2] });
      } else if (
        paths[paths.length - 1] === "upload" &&
        paths[paths.length - 2] === "bulkconfig" &&
        paths[paths.length - 3] === "networks"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else if (
        paths[paths.length - 1] === "view-history" &&
        paths[paths.length - 2] === "bulkconfig" &&
        paths[paths.length - 3] === "networks"
      ) {
        this.setState({ tabKey: paths[paths.length - 3] });
      } else {
        this.setState({ tabKey: this.TAB_KEYS.default });
      }
    }
  }

  onAfterClick = resourse => {
    if (resourse === "firewallgroup") resourse = "securitypolicies";
    if (this.props.match.params && this.props.match.params.id && resourse) {
      this.props.history.push(
        `/orgs/${this.props.match.params.id}/${resourse}`,
      );
    }
  };

  changeRoute = (resourse, action, id = null) => {
    if (resourse === "firewallgroup") resourse = "securitypolicies";
    if (
      this.props.match.params &&
      this.props.match.params.id &&
      resourse &&
      action
    ) {
      if (action === "create") {
        this.props.history.push(
          `/orgs/${this.props.match.params.id}/${resourse}/${action}`,
        );
      } else if (action === "edit" && id) {
        this.props.history.push(
          `/orgs/${this.props.match.params.id}/${resourse}/${id}/${action}`,
        );
      } else if (action === "view" && id) {
        this.props.history.push(
          `/orgs/${this.props.match.params.id}/${resourse}/${id}/${action}`,
        );
      } else {
        this.props.history.push(
          `/orgs/${this.props.match.params.id}/${resourse}/${action}`,
        );
      }
    }
  };

  updateCurrentOrg = orgId => {
    OrgController.getOrg(orgId).then(response => {
      if (response && response.parent_organization) {
        this.props.UiStore.parentOrgData = response.parent_organization;
      } else {
        this.props.UiStore.parentOrgData = {};
      }
      this.props.UiStore.setOrgName(response.name);
    });
  };

  render() {
    let org = this.orgModel.org;
    let breadCrumbList = this.state.BreadcrumbList;
    if (breadCrumbList && breadCrumbList[0]) {
      breadCrumbList[0].name = org ? org.name : "";
    }
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 17 }} xl={{ span: 17 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaSitemap" />
              {"  "}
              {org ? org.name : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={breadCrumbList}
            />
          </Col>
          <Col lg={{ span: 7 }} xl={{ span: 7 }} className="flex flex-h-right">
            <OrgSelector {...this.props} className="orgslector-ellipse-menu" />
          </Col>
        </Row>
        <div style={{ background: "#fff", minHeight: 360 }}>
          <Row gutter={20} type="flex" align="top">
            <Col span={24}>
              {this.props.AuthStore.IsPermitted("ORG:VIEW") &&
                this.state.tabKey === "orgs" && (
                  <OrgsList
                    isFromDashboard={false}
                    cache={false}
                    icon={<Icons type="ai" name="AiOutlineSolution" />}
                    title="Organizations"
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    {...this.props}
                    loadOrgState={true}
                  />
                )}
              {this.props.AuthStore.IsPermitted("NODE:VIEW") &&
                this.state.tabKey === "inodes" && (
                  <InodesList
                    resource="inodes"
                    onAfterClick={this.onAfterClick}
                    changeRoute={this.changeRoute}
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                    cache={false}
                    icon={<Icons type="ai" name="AiOutlineUser" />}
                    title="Inodes"
                    loadOrgState={true}
                    {...this.props}
                  />
                )}
              {this.props.AuthStore.IsPermitted("NODE:VIEW") &&
                this.state.tabKey === "securitypolicies" && (
                  <FirewallList
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    onAfterClick={this.onAfterClick}
                    changeRoute={this.changeRoute}
                    resource="firewallgroup"
                    tab={this.state.tabKey}
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                  />
                )}
              {this.props.AuthStore.IsPermitted("NODE:VIEW") &&
                this.state.tabKey === "networks" && (
                  <NetworkList
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                    changeRoute={this.changeRoute}
                    onAfterClick={this.onAfterClick}
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    {...this.props}
                  />
                )}
              {this.props.AuthStore.IsPermitted("SERVICE:VIEW") &&
                this.state.tabKey === "services" && (
                  <ServiceList
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                    {...this}
                  />
                )}{" "}
              {this.props.AuthStore.IsPermitted("NODE:VIEW") &&
                this.state.tabKey === "licenses" && (
                  <LicenseComponent
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    {...this}
                  />
                )}
              {org &&
              org.id &&
              (this.props.AuthStore.IsPermitted("SERIAL:CREATE") ||
                this.props.AuthStore.IsPermitted("ORG:VIEW")) &&
              this.state.tabKey === "serials" ? (
                <SerialList
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineSolution" /> Add Serial
                    </span>
                  }
                  icon={<Icons type="ai" name="AiOutlinePlus" />}
                  orgId={this.props.match.params.id}
                  params={{
                    org_id: this.props.match.params
                      ? this.props.match.params.id
                      : null,
                    own: true,
                  }}
                  resource="pki"
                  action="create"
                />
              ) : null}
              {org &&
              org.id &&
              (this.props.AuthStore.IsPermitted("SERIAL:CREATE") ||
                this.props.AuthStore.IsPermitted("ORG:VIEW")) &&
              this.state.tabKey === "certificates" ? (
                <CertificateList
                  orgId={this.props.match.params.id}
                  params={{
                    org_id: this.props.match.params
                      ? this.props.match.params.id
                      : null,
                    own: true,
                  }}
                />
              ) : null}
              {this.props.AuthStore.IsPermitted("ORG:VIEW") &&
              this.state.tabKey === "servicelistener" ? (
                <ServiceListenerList
                  orgId={
                    this.props.match.params ? this.props.match.params.id : null
                  }
                />
              ) : null}
              {this.props.AuthStore.IsPermitted("USER:VIEW") &&
                this.state.tabKey === "users" && (
                  <OrgUsers
                    cameFromDashboard={false}
                    resource="users"
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    onAfterClick={this.onAfterClick}
                    changeRoute={this.changeRoute}
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                    cache={false}
                    icon={<Icons type="ai" name="AiOutlineUser" />}
                    title="Users"
                    loadOrgState={true}
                    {...this.props}
                  />
                )}
              {this.props.AuthStore.IsPermitted("ROLE:VIEW") &&
                this.state.tabKey === "roles" && (
                  <RolesList
                    orgId={
                      this.props.match.params
                        ? this.props.match.params.id
                        : null
                    }
                    onAfterClick={this.onAfterClick}
                    changeRoute={this.changeRoute}
                    params={{
                      org_id: this.props.match.params
                        ? this.props.match.params.id
                        : null,
                      own: true,
                    }}
                  />
                )}
              {this.props.AuthStore.IsPermitted("ACTIVITY:VIEW") &&
                this.state.tabKey === "activities" && (
                  <ActivityList {...this.props} />
                )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.orgModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

OrgDetails.propTypes = {
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
};

export default OrgDetails;
