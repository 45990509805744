import React, { Component } from "react";
import { Popover, Modal, Button, Badge } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  focusFirstInput,
  isValidURL,
  isValidFontURL,
  formatTimestampToIotiumLastAtTimeString,
  getFontFamilyFromURL,
  isEmpty,
} from "Core/Utils";
import { OrgController } from "../../controller/Controllers";
import BrandingLogoUploader from "components/Forms/BrandingLogoUploader";
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const MAX_BACKGROUND_IMAGE_URL_LIMIT = 4;

@inject("UiStore", "OrgViewModel")
@observer
class BrandForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      onLogoRemove: {
        dark_logo_id: false,
        light_logo_id: false,
        favicon_id: false,
        login_background_id_0: false,
        login_background_id_1: false,
        login_background_id_2: false,
        login_background_id_3: false,
      },
      formData: {
        light_logo_url: null,
        dark_logo_url: null,
        favicon_url: null,
        login_background_url: [],
        privacy_policy_url: null,
        sidebar_mode: "dark",
        primary_color: "#365c6f",
        text_color: "#365c6f",
        font_family_name: "",
        font_source_url: null,
        uploadingItems: [],
        uploadedInfo: {},
        loadingStatus: {},
        uploadingFailedItems: [],
      },
      uploadedFiles: [],
      currentUploadedFiles: [],
      orgId: null,
      showResendModal: false,
      background_urls: [
        {
          key: Math.random()
            .toString()
            .replace(".", ""),
          url: null,
          isUrl: true,
        },
      ],
      background_ids: [],
      isLoadingIsInProgress: false,
    };
    this.getUploadedFileList(true);
  }

  updateLoginBackground() {
    let Org = this.props.OrgViewModel.org;
    let uploadedInfo = this.state.uploadedInfo || {};
    let background_urls = this.state.background_urls;
    let login_background_id = [];
    let formData = Object.assign(this.state.formData, Org.branding);
    if (
      Org &&
      Org.branding &&
      Org.branding.login_background_id &&
      Org.branding.login_background_id.length > 0
    ) {
      Org.branding.login_background_id.forEach(logo_id => {
        let urlIndex = -1;
        if (this.state.background_urls) {
          urlIndex = background_urls.findIndex(url => url.id == logo_id);
        }
        Org.branding.login_background_url.forEach(url => {
          if (url.includes(logo_id) && urlIndex != -1) {
            background_urls[urlIndex] = {
              isUrl: false,
              key: Math.random()
                .toString()
                .replace(".", ""),
              id: logo_id,
              url: url,
            };
            login_background_id.push(logo_id);
            uploadedInfo["login_background_id_" + urlIndex] = {
              logo_id: logo_id,
              original_filename: null,
              s3_url: background_urls[urlIndex].url,
            };
            formData[
              "login_background_url_" + urlIndex
            ] = this.props.OrgViewModel.org.branding.login_background_url[
              urlIndex
            ];
          }
        });
      });

      setTimeout(() => {
        this.setState({
          uploadedInfo: uploadedInfo,
          background_urls: background_urls,
          formData: formData,
        });
      });
    }
    return login_background_id;
  }

  getUploadedFileList = (isInitial = false) => {
    OrgController.getUploadedLogos(this.props.orgId).then(result => {
      if (result) {
        let uploadedFiles = result || [];
        this.setState({ uploadedFiles }, () => {
          if (isInitial) {
            this.clearStaleLoginImages();
          }
        });
      }
    });
  };

  componentDidMount() {
    // Focus first input
    focusFirstInput();
    // Increse size of Tag select component
    let element = document.querySelector(
      ".branding-background-url .ant-select-selection",
    );
    if (element) {
      element.style.minHeight = "75px";
    }
    if (this.props.OrgViewModel.org) {
      if (this.props.OrgViewModel.org.branding) {
        let formData = Object.assign(
          this.state.formData,
          this.props.OrgViewModel.org.branding,
        );
        let background_urls = this.state.background_urls;
        if (
          formData.login_background_url &&
          formData.login_background_url.length > 0
        ) {
          background_urls = formData.login_background_url.map(item => {
            return {
              key: Math.random()
                .toString()
                .replace(".", ""),
              url: item,
              isUrl: true,
            };
          });
        }
        let background_ids = this.state.background_ids;
        this.setState({
          formData: formData,
          background_urls: background_urls,
          background_ids: background_ids,
        });
        let uploadedInfo = this.state.uploadedInfo || {};
        if (
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.branding &&
          this.props.OrgViewModel.org.branding.dark_logo_id
        ) {
          formData.dark_logo_id = this.props.OrgViewModel.org.branding.dark_logo_id;
          uploadedInfo["dark_logo_id"] = {
            logo_id: this.props.OrgViewModel.org.branding.dark_logo_id,
            original_filename: null,
            s3_url: this.props.OrgViewModel.org.branding.dark_logo_url,
          };
        }
        if (
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.branding &&
          this.props.OrgViewModel.org.branding.light_logo_id
        ) {
          formData.light_logo_id = this.props.OrgViewModel.org.branding.light_logo_id;
          uploadedInfo["light_logo_id"] = {
            logo_id: this.props.OrgViewModel.org.branding.light_logo_id,
            original_filename: null,
            s3_url: this.props.OrgViewModel.org.branding.light_logo_url,
          };
        }
        if (
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.branding &&
          this.props.OrgViewModel.org.branding.favicon_id
        ) {
          formData.favicon_id = this.props.OrgViewModel.org.branding.favicon_id;
          uploadedInfo["favicon_id"] = {
            logo_id: this.props.OrgViewModel.org.branding.favicon_id,
            original_filename: null,
            s3_url: this.props.OrgViewModel.org.branding.favicon_url,
          };
        }
        if (
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.branding &&
          this.props.OrgViewModel.org.branding.login_background_id &&
          this.props.OrgViewModel.org.branding.login_background_id.length > 0
        ) {
          let login_background_id = [];
          this.props.OrgViewModel.org.branding.login_background_id.forEach(
            logo_id => {
              let urlIndex = -1;
              if (
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.branding &&
                this.props.OrgViewModel.org.branding.login_background_url &&
                this.props.OrgViewModel.org.branding.login_background_url
                  .length > 0
              ) {
                urlIndex = this.props.OrgViewModel.org.branding.login_background_url.findIndex(
                  url => url.includes(logo_id),
                );
              }
              this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.branding &&
                this.props.OrgViewModel.org.branding.login_background_url &&
                this.props.OrgViewModel.org.branding.login_background_url.forEach(
                  url => {
                    if (url.includes(logo_id) && urlIndex != -1) {
                      background_urls[urlIndex] = {
                        isUrl: false,
                        key: Math.random()
                          .toString()
                          .replace(".", ""),
                        id: logo_id,
                        url: url,
                      };
                      login_background_id.push(logo_id);
                      uploadedInfo["login_background_id_" + urlIndex] = {
                        logo_id: logo_id,
                        original_filename: null,
                        s3_url: this.props.OrgViewModel.org.branding
                          .login_background_url[urlIndex],
                      };

                      formData["login_background_url_" + urlIndex] =
                        background_urls[urlIndex].url;
                    }
                  },
                );
            },
          );
          formData.login_background_id = login_background_id;
        }
        setTimeout(() => {
          this.setState({
            formData: formData,
            uploadedInfo: uploadedInfo,
            background_urls: background_urls,
          });
        }, 200);
      } else if (isEmpty(this.props.OrgViewModel.org.branding)) {
        let formData = this.state.formData;
        this.setState({ formData: formData });
      }
    }
  }

  /**
   * Clear Stale images in S3
   */
  clearStaleLoginImages = () => {
    let uploadedFiles = this.state.uploadedFiles || [];

    let loginImages =
      this.state.formData && this.state.formData.login_background_id
        ? this.state.formData.login_background_id
        : [];
    let uploadedLoginBackgrounds = uploadedFiles.filter(files => {
      return (
        files &&
        files.purpose &&
        files.purpose.toLowerCase().startsWith("loginbackgroundlogo")
      );
    });
    let deletableImages = [];
    if (uploadedLoginBackgrounds.length > 0) {
      if (loginImages.length <= 0) {
        uploadedLoginBackgrounds.forEach(val => {
          deletableImages.push(val.logo_id);
        });
      } else {
        uploadedLoginBackgrounds.forEach(bg => {
          if (!loginImages.includes(bg.logo_id)) {
            deletableImages.push(bg.logo_id);
          }
        });
      }
    }
    let uploadedOtherImages = uploadedFiles.filter(files => {
      return (
        files &&
        files.purpose &&
        !files.purpose.toLowerCase().startsWith("loginbackgroundlogo")
      );
    });
    if (uploadedOtherImages.length > 0) {
      let formData = this.state.formData;
      uploadedOtherImages.forEach(img => {
        if (
          img.purpose &&
          img.purpose === "darklogo" &&
          (!formData.dark_logo_id || formData.dark_logo_id === "")
        ) {
          deletableImages.push(img.logo_id);
        }
        if (
          img.purpose &&
          img.purpose === "lightlogo" &&
          (!formData.light_logo_id || formData.light_logo_id === "")
        ) {
          deletableImages.push(img.logo_id);
        }
        if (
          img.purpose &&
          img.purpose === "faviconlogo" &&
          (!formData.favicon_id || formData.favicon_id === "")
        ) {
          deletableImages.push(img.logo_id);
        }
      });
    }
    if (deletableImages.length > 0) {
      this.deleteImage(deletableImages, 0);
    }
  };

  // delete uploaded image
  deleteImage = (deletableImages, count = 0) => {
    if (deletableImages[count]) {
      OrgController.removeUploadedLogo(
        this.props.orgId,
        deletableImages[count],
        false,
      ).then(res => {
        this.getUploadedFileList();
        if (count <= deletableImages.length - 1) {
          this.deleteImage(deletableImages, count + 1);
        }
      });
    }
  };

  updateHelp = helpData => {
    this.UiStore.SetStoreData("helpData", {
      title: "",
      data: [{ subtitle: "", content: "" }],
    });
    if (helpData) {
      setTimeout(() => {
        let helpTitleElm = document.querySelector(
          ".branding-page .help-container .help-title",
        );
        if (helpTitleElm) {
          helpTitleElm.innerHTML = helpData.title;
        }
        let helpContentElm = document.querySelector(
          ".branding-page .help-container .help-content",
        );
        if (helpContentElm) {
          helpContentElm.innerHTML = helpData.content;
        }
      }, 50);
    }
  };

  validateURL = (rule, value, callback) => {
    setTimeout(() => {
      if (
        (value && value.file) ||
        !(rule && rule.field && rule.field.includes("_url")) ||
        (value && value.includes("fakepath"))
      ) {
        callback();
      } else {
        if (
          value &&
          !isValidURL(value) &&
          rule &&
          rule.field &&
          rule.field.includes("_url")
        ) {
          callback(getCurrentlocaleText("branding.url.error.message"));
        } else {
          callback();
        }
      }
    }, 200);
  };

  validateFontURL = (rule, value, callback) => {
    if (value) {
      if (!isValidFontURL(value)) {
        callback(getCurrentlocaleText("branding.font.url.error.message"));
      } else {
        let fonts = getFontFamilyFromURL(value);
        if (fonts && fonts.length > 1) {
          callback(getCurrentlocaleText("branding.font.url.text.error"));
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  handleSidebarOnClick = sidebarMode => {
    let formData = this.state.formData;
    formData.sidebar_mode = sidebarMode;
    this.setState({ formData: formData });
    this.updateHelp({
      title: getCurrentlocaleText("branding.sidebar.theme.text"),
      content: getCurrentlocaleText("branding.sidebar.help.text"),
    });
  };

  handleBackgroundUrlOnChange = (index, e) => {
    let background_urls = this.state.background_urls;
    background_urls[index].url =
      e.currentTarget.value && e.currentTarget.value.length
        ? e.currentTarget.value
        : null;
    this.setState({ background_urls: background_urls });
    if (!background_urls[index].url) {
      let obj = {},
        fieldName = "login_background_url_" + background_urls[index].key;
      obj[fieldName] = { value: null };
      obj[fieldName].errors = null;
      this.props.form.setFields(obj);
    }
  };

  addBackgroundUrl = () => {
    let bgUrl = {
      key: Math.random()
        .toString()
        .replace(".", ""),
      url: null,
      isUrl: true,
    };
    let background_urls = this.state.background_urls;
    background_urls.push(bgUrl);
    this.setState({ background_urls: background_urls });
  };

  resetFormValues = type => {
    let { formData, onLogoRemove, background_urls } = this.state;
    onLogoRemove[type] = true;
    this.setState({ onLogoRemove }, () => {
      switch (type) {
        case "dark_logo_id":
          formData.dark_logo_url = "";
          this.props.form.resetFields(["dark_logo_url"]);
          this.props.form.setFieldsValue({ dark_logo_url: "" });
          break;
        case "light_logo_id":
          this.props.form.resetFields(["light_logo_url"]);
          this.props.form.setFieldsValue({ light_logo_url: "" });
          formData.light_logo_url = "";
          break;
        case "favicon_id":
          this.props.form.resetFields(["favicon_url"]);
          this.props.form.setFieldsValue({ favicon_url: "" });
          formData.favicon_url = null;
          break;
        default:
          {
            let fieldKey = type.split("_");
            if (fieldKey && fieldKey[3]) {
              this.props.form.setFields({
                ["login_background_url_" + fieldKey[3]]: {
                  value: "",
                },
              });
              this.props.form.setFields({
                ["login_background_id_" + fieldKey[3]]: {
                  value: "",
                },
              });
              this.props.form.resetFields([
                "login_background_id_" + fieldKey[3],
              ]);
              this.props.form.resetFields([
                "login_background_url_" + fieldKey[3],
              ]);
              formData["login_background_url_" + fieldKey[3]] = null;
              if (background_urls[fieldKey[3]]) {
                background_urls[fieldKey[3]].url = null;
                background_urls[fieldKey[3]].id = null;
              }
            }
          }
          break;
      }
      onLogoRemove[type] = false;
      this.setState({ formData, onLogoRemove, background_urls });
    });
  };

  URLChange = (type, e) => {
    this.props.form.setFields({ [type]: { value: e.target.value } });
  };

  onLogoRemove = type => {
    return new Promise((resolve, reject) => {
      let uploadedInfo = this.state.uploadedInfo;
      let currentUploadInfo = uploadedInfo && uploadedInfo[type];
      let loadingStatus = this.state.loadingStatus || {};
      let isLoadingIsInProgress = this.state.isLoadingIsInProgress;
      isLoadingIsInProgress = true;
      loadingStatus[type] = true;
      this.setState({ loadingStatus, isLoadingIsInProgress });
      let logo_id =
        currentUploadInfo && currentUploadInfo.logo_id
          ? currentUploadInfo.logo_id.replaceAll(/\\/g, "").replaceAll('"', "")
          : null;
      if (logo_id) {
        OrgController.removeUploadedLogo(this.props.orgId, logo_id.trim())
          .then(response => {
            let uploadedFiles = this.state.uploadedFiles;
            let currentUploadedImage = [];
            if (uploadedFiles && uploadedFiles.length > 0) {
              currentUploadedImage = uploadedFiles.filter(info => {
                return info.logo_id === logo_id;
              });
            }
            // Check the uploaded image purpose to delete
            let currentUploadedFiles = this.state.currentUploadedFiles;
            if (currentUploadedImage && currentUploadedImage.length > 0) {
              let purpose =
                currentUploadedImage[0] && currentUploadedImage[0].purpose;
              let tmpIndex = currentUploadedFiles.indexOf(purpose);
              if (tmpIndex != -1) {
                currentUploadedFiles.splice(tmpIndex, 1);
              }
            }
            delete uploadedInfo[type];
            isLoadingIsInProgress = false;
            this.setState({
              uploadedFiles,
              uploadedInfo,
              isLoadingIsInProgress,
              currentUploadedFiles,
            });
            delete loadingStatus[type];
            this.setState({ loadingStatus });
            this.resetFormValues(type);
            let formData = null;
            if (this.props.OrgViewModel.org.branding) {
              formData = Object.assign(
                this.state.formData,
                this.props.OrgViewModel.org.branding,
              );
            }
            this.setState({ uploadedInfo, formData });
            setTimeout(() => {
              this.getUploadedFileList(true);
            }, 500);
            resolve();
          })
          .catch(error => {
            delete loadingStatus[type];
            isLoadingIsInProgress = false;
            this.setState({ loadingStatus, isLoadingIsInProgress });
            this.resetFormValues(type);
            reject(error);
          });
      } else {
        let uploadedInfo = this.state.uploadedInfo || {};
        delete uploadedInfo[type];
        isLoadingIsInProgress = false;
        this.setState({ uploadedInfo, isLoadingIsInProgress });
        delete loadingStatus[type];
        let uploadingFailedItems = this.state.uploadingFailedItems || [];
        let uploadIndx =
          uploadingFailedItems &&
          uploadingFailedItems.length > 0 &&
          uploadingFailedItems.indexOf(type);
        if (uploadIndx > -1) {
          uploadingFailedItems.splice(uploadIndx, 1);
        }
        this.setState({ loadingStatus, uploadingFailedItems });
        this.resetFormValues(type);
      }
    });
  };

  getCurrentBackGroundPurpose = () => {
    let background_urls = this.state.uploadedFiles;
    let currentUploadedFiles = this.state.currentUploadedFiles || [];
    let uploadedBGLogos = [];
    background_urls.forEach(lbg => {
      if (
        lbg.purpose &&
        lbg.purpose.toLowerCase().startsWith("loginbackgroundlogo")
      )
        uploadedBGLogos.push(lbg.purpose.toLowerCase());
    });
    if (currentUploadedFiles.length > 0)
      uploadedBGLogos = uploadedBGLogos.concat(currentUploadedFiles);

    let ALLOWED_VALUES = [
      "loginbackgroundlogo1",
      "loginbackgroundlogo2",
      "loginbackgroundlogo3",
      "loginbackgroundlogo4",
    ];
    return ALLOWED_VALUES.find(value => !uploadedBGLogos.includes(value));
  };

  PurposeFromType = type => {
    let purpose = "dark_logo";
    switch (type) {
      case "dark_logo_id":
        purpose = "darklogo";
        break;
      case "light_logo_id":
        purpose = "lightlogo";
        break;
      case "favicon_id":
        purpose = "faviconlogo";
        break;
      case "login_background_id_0":
      case "login_background_id_1":
      case "login_background_id_2":
      case "login_background_id_3":
        purpose = this.getCurrentBackGroundPurpose();
        break;
    }
    return purpose;
  };

  UploadImageFile = (
    type,
    {
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    },
  ) => {
    const formData = new FormData();
    let loadingStatus = this.state.loadingStatus || {};
    let isLoadingIsInProgress = this.state.isLoadingIsInProgress;
    isLoadingIsInProgress = true;
    let currentUploadedFiles = this.state.currentUploadedFiles || [];

    if (data) {
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });
    }
    let purpose = this.PurposeFromType(type);
    formData.append("filename", file);
    formData.append("purpose", purpose);
    currentUploadedFiles.push(purpose);
    loadingStatus[type] = true;
    this.props.OrgViewModel.isLogoUploadPending = true;
    this.setState({ loadingStatus, isLoadingIsInProgress });
    OrgController.uploadLogo(this.props.orgId, formData, {
      headers,
    })
      .then(response => {
        let uploadedInfo = this.state.uploadedInfo || {};
        uploadedInfo[type] = response && response.data ? response.data : {};
        let logo_id =
          response && response.data && response.data.logo_id
            ? response.data.logo_id
            : null;
        this.props.OrgViewModel.isLogoUploadPending = false;
        let uploadingFailedItems = this.state.uploadingFailedItems || [];
        let uploadIndx =
          uploadingFailedItems &&
          uploadingFailedItems.length > 0 &&
          uploadingFailedItems.indexOf(type);
        if (uploadIndx > -1) {
          uploadingFailedItems.splice(uploadIndx, 1);
        }
        delete loadingStatus[type];
        this.setState({
          loadingStatus,
          uploadingFailedItems,
          currentUploadedFiles,
        });
        onSuccess(response, file);
        isLoadingIsInProgress = false;
        this.setState({ uploadedInfo, isLoadingIsInProgress });

        this.getUploadedFileList();
        if (logo_id) {
          setTimeout(() => {
            this.props.form.setFields({ [type]: { value: logo_id } });
          }, 200);
        }
      })
      .catch(e => {
        delete loadingStatus[type];
        let tmpIndex = currentUploadedFiles.indexOf(purpose);
        this.props.OrgViewModel.isLogoUploadPending = false;
        if (tmpIndex != -1) {
          currentUploadedFiles.splice(tmpIndex, 1);
        }
        let uploadingFailedItems = this.state.uploadingFailedItems || [];
        if (uploadingFailedItems && uploadingFailedItems.indexOf(type) === -1) {
          uploadingFailedItems.push(type);
        }
        isLoadingIsInProgress = false;
        this.props.UiStore.errorMessage = e.response.data.message;
        onError({ event: this.props.UiStore.errorMessage });
        this.setState({
          loadingStatus,
          uploadingFailedItems,
          currentUploadedFiles,
          isLoadingIsInProgress,
        });
      });
  };

  removeBackgroundUrl = index => {
    let background_urls = this.state.background_urls;
    let uploadedInfo = this.state.uploadedInfo;
    background_urls.splice(index, 1);
    this.setState(
      {
        background_urls: background_urls,
      },
      () => {
        let formData = Object.assign(
          this.state.formData,
          this.props.OrgViewModel.org.branding,
        );
        let login_background_id = this.updateLoginBackground();
        formData.login_background_id = login_background_id;
        this.setState({ uploadedInfo, formData });
      },
    );

    this.getFileList();
  };

  /**
   * Method to send verify email link request to API
   */
  SendVerificationEmail = isname => {
    let params = {};
    if (
      this.state.formData.from_email_verify_triggered_at &&
      !this.state.formData.from_email_verified
    ) {
      params.force = true;
    } else {
      params = {};
    }
    OrgController.verifyFromMailBranding(this.state.orgId, params)
      .then(response => {
        this.props.UiStore.successMessage = getCurrentlocaleText(
          "branding.form.mail.success.text",
        );
        this.hidePopup();
        this.updateAfterCall();
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          if (
            this.state.formData.verify_email_count &&
            this.state.formData.verify_email_count > 0 &&
            error.response.data &&
            error.response.data.message
          ) {
            this.props.UiStore.errorMessage = error.response.data.message;
            this.props.hidePopup();
            this.props.updateAfterCall();
          }
        }
      });
  };

  enableResendLink = () => {
    if (this.state.formData.verify_email_count > 0) {
      this.setState({
        showResendModal: true,
      });
    } else this.setState({ showResendModal: true });
  };

  ignoreAlert = () => {
    this.hidePopup();
  };

  hidePopup = () => {
    this.setState({ showResendModal: false });
  };

  getFileList = type => {
    let uploadedInfo = this.state.uploadedInfo || {};
    let uploadingFailedItems = this.state.uploadingFailedItems || [];
    let uploadIndx =
      uploadingFailedItems &&
      uploadingFailedItems.length > 0 &&
      uploadingFailedItems.indexOf(type);
    let fileName = "";
    if (uploadedInfo && uploadedInfo[type]) {
      let uploadedFiles = this.state.uploadedFiles;
      if (uploadedFiles && uploadedFiles.length > 0) {
        let filtered_file = uploadedFiles.filter(file => {
          return file.logo_id === uploadedInfo[type].logo_id;
        });
        fileName =
          filtered_file &&
          filtered_file[0] &&
          filtered_file[0].original_filename;
      }
      return [
        {
          uid: uploadedInfo[type].logo_id,
          status: "done",
          url: uploadedInfo[type].s3_url,
          name: fileName,
          thumbUrl: uploadedInfo[type].s3_url,
        },
      ];
    } else if (uploadIndx > -1) {
      return [
        {
          uid: "-1" + type,
          status: "error",
        },
      ];
    } else {
      return [];
    }
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    let { formData, background_urls, uploadingItems } = this.state;
    let isCentralAuthOrg =
      this.props.org && this.props.org.is_central_auth ? true : false;
    let renderLoginBackgroundUrlFormItems = background_urls.map((item, i) => {
      return (
        <FormItem
          className={"background-url-item"}
          key={item.key}
          colon={false}
          label={
            i === 0 ? (
              <span>
                {this.state.uploadedInfo &&
                this.state.uploadedInfo["login_background_id_" + i]
                  ? getCurrentlocaleText("branding.login.background.logo.text")
                  : getCurrentlocaleText("branding.login.background.url.text")}
              </span>
            ) : (
              " "
            )
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator(
            this.state.uploadedInfo &&
              this.state.uploadedInfo["login_background_id_" + i]
              ? "login_background_id_" + i
              : "login_background_url_" + i,
            {
              rules: [
                { required: false },
                {
                  validator:
                    this.state.loadingStatus &&
                    this.state.loadingStatus["login_background_id_" + i]
                      ? {}
                      : this.validateURL,
                },
              ],
              initialValue: !item.isUrl ? item.id : item.url,
            },
          )(
            <div>
              {!(
                this.state.onLogoRemove &&
                this.state.onLogoRemove["login_background_id_" + i]
              ) && (
                <div style={{ display: "inline-block", width: "90%" }}>
                  <BrandingLogoUploader
                    loading={
                      this.state.loadingStatus &&
                      this.state.loadingStatus["login_background_id_" + i] ===
                        true
                        ? true
                        : false
                    }
                    formData={formData}
                    type="url"
                    uploadedFiles={this.state.uploadedFiles}
                    placeholder={getCurrentlocaleText(
                      "branding.login.background.url.placeholder.text",
                    )}
                    URLChange={value => {
                      this.URLChange("login_background_url_" + i, value);
                    }}
                    showUpload={
                      this.state.uploadingItems &&
                      this.state.uploadingItems.indexOf(
                        "login_background_id_" + i,
                      ) !== -1
                        ? true
                        : false
                    }
                    customRequest={eventObject => {
                      this.UploadImageFile(
                        "login_background_id_" + i,
                        eventObject,
                      );
                    }}
                    initialValue={item.url}
                    onRemove={() => {
                      this.onLogoRemove("login_background_id_" + i);
                    }}
                    isUploadFailed={
                      this.state.uploadingFailedItems &&
                      this.state.uploadingFailedItems.indexOf(
                        "login_background_id_" + i,
                      ) !== -1
                        ? true
                        : false
                    }
                    value={item.url}
                    onChange={() => {
                      this.handleBackgroundUrlOnChange.bind(this, i);
                      let uploadingItems = this.state.uploadingItems || [];
                      let uploadIndx =
                        uploadingItems &&
                        uploadingItems.indexOf("login_background_id_" + i);
                      if (uploadIndx !== -1) {
                        uploadingItems.push("login_background_id_" + i);
                      } else {
                        uploadingItems.splice(uploadIndx, 1);
                      }
                      this.setState({ uploadingItems });
                    }}
                    isDisabled={
                      this.state.isLoadingIsInProgress ||
                      false ||
                      this.props.viewOnly
                    }
                    onFocus={this.updateHelp.bind(this, {
                      title: getCurrentlocaleText(
                        "branding.login.background.url.text",
                      ),
                      content: getCurrentlocaleText(
                        "branding.login.background.help.text",
                      ),
                    })}
                    fileList={this.getFileList("login_background_id_" + i)}
                    uploadedInfo={
                      (this.state.uploadedInfo &&
                        this.state.uploadedInfo["login_background_id_" + i]) ||
                      {}
                    }
                  />
                </div>
              )}
              <div
                style={{
                  display: "inline-block",
                  width: "10%",
                  textAlign: "center",
                }}
              >
                {i === 0 ? (
                  <Button
                    className="no-border"
                    onClick={this.addBackgroundUrl}
                    disabled={
                      this.props.viewOnly ||
                      background_urls.length === MAX_BACKGROUND_IMAGE_URL_LIMIT
                    }
                    title={"Add"}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlinePlusSquare"
                      className="valign-sub middle"
                      style={{ fontSize: 24 }}
                    />
                  </Button>
                ) : (
                  item.isUrl && (
                    <Button
                      disabled={this.props.viewOnly}
                      className="no-border"
                      shape="circle"
                      onClick={this.removeBackgroundUrl.bind(this, i)}
                      title={"Remove"}
                    >
                      <Icons
                        type="ai"
                        name="AiOutlineMinusCircle"
                        className="valign-sub middle"
                        style={{ fontSize: 24 }}
                      />
                    </Button>
                  )
                )}
              </div>
            </div>,
          )}
        </FormItem>
      );
    });

    return (
      <Form
        noValidate
        onSubmit={this.props.onSubmit}
        className="branding-logo-form"
      >
        {/** Dark logo url */}
        <FormItem
          label={
            <span>
              {this.state.uploadedInfo &&
              this.state.uploadedInfo["dark_logo_id"]
                ? getCurrentlocaleText("branding.dark.logo.text")
                : getCurrentlocaleText("branding.dark.logo.url.text")}
            </span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator(
            this.state.uploadedInfo && this.state.uploadedInfo["dark_logo_id"]
              ? "dark_logo_id"
              : "dark_logo_url",
            {
              rules: [
                {
                  validator:
                    this.state.loadingStatus &&
                    this.state.loadingStatus["dark_logo_id"]
                      ? {}
                      : this.validateURL,
                },
              ],
              initialValue:
                this.state.uploadedInfo &&
                this.state.uploadedInfo["dark_logo_id"]
                  ? formData && formData.dark_logo_id
                    ? formData.dark_logo_id
                    : ""
                  : formData && formData.dark_logo_url
                  ? formData.dark_logo_url
                  : "",
            },
          )(
            <div>
              {!(
                this.state.onLogoRemove && this.state.onLogoRemove.dark_logo_id
              ) && (
                <BrandingLogoUploader
                  loading={
                    this.state.loadingStatus &&
                    this.state.loadingStatus["dark_logo_id"] === true
                      ? true
                      : false
                  }
                  type="url"
                  placeholder={getCurrentlocaleText(
                    "branding.dark.logo.url.placeholder.text",
                  )}
                  isUploadFailed={
                    this.state.uploadingFailedItems &&
                    this.state.uploadingFailedItems.indexOf("dark_logo_id") !==
                      -1
                      ? true
                      : false
                  }
                  isDisabled={
                    this.state.isLoadingIsInProgress ||
                    false ||
                    this.props.viewOnly
                  }
                  URLChange={value => {
                    this.URLChange("dark_logo_url", value);
                  }}
                  uploadedFiles={this.state.uploadedFiles}
                  uploadedInfo={
                    (this.state.uploadedInfo &&
                      this.state.uploadedInfo["dark_logo_id"]) ||
                    {}
                  }
                  formData={formData}
                  onRemove={e => {
                    this.onLogoRemove("dark_logo_id");
                  }}
                  showUpload={
                    uploadingItems &&
                    uploadingItems.indexOf("dark_logo_id") !== -1
                      ? true
                      : false
                  }
                  onChange={() => {
                    let uploadingItems = this.state.uploadingItems || [];
                    let uploadIndx =
                      uploadingItems && uploadingItems.indexOf("dark_logo_id");
                    if (uploadIndx !== -1) {
                      uploadingItems.push("dark_logo_id");
                    } else {
                      uploadingItems.splice(uploadIndx, 1);
                    }
                    this.setState({ uploadingItems });
                  }}
                  url_type="dark_logo_url"
                  customRequest={eventObject => {
                    setTimeout(() => {
                      this.UploadImageFile("dark_logo_id", eventObject);
                    }, 200);
                  }}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText("branding.dark.logo.url.text"),
                    content: getCurrentlocaleText(
                      "branding.dark.logo.help.text",
                    ),
                  })}
                  fileList={this.getFileList("dark_logo_id")}
                />
              )}
            </div>,
          )}
        </FormItem>
        {/** Light logo url */}
        <FormItem
          label={
            <span>
              {this.state.uploadedInfo &&
              this.state.uploadedInfo["light_logo_id"]
                ? getCurrentlocaleText("branding.light.logo.id.text")
                : getCurrentlocaleText("branding.light.logo.url.text")}
            </span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator(
            this.state.uploadedInfo && this.state.uploadedInfo["light_logo_id"]
              ? "light_logo_id"
              : "light_logo_url",
            {
              rules: [
                {
                  validator:
                    this.state.loadingStatus &&
                    this.state.loadingStatus["light_logo_id"]
                      ? {}
                      : this.validateURL,
                },
              ],
              initialValue:
                this.state.uploadedInfo &&
                this.state.uploadedInfo["light_logo_id"]
                  ? formData && formData.light_logo_id
                    ? formData.light_logo_id
                    : ""
                  : formData && formData.light_logo_url
                  ? formData.light_logo_url
                  : "",
            },
          )(
            <div>
              {!(
                this.state.onLogoRemove && this.state.onLogoRemove.light_logo_id
              ) && (
                <BrandingLogoUploader
                  loading={
                    this.state.loadingStatus &&
                    this.state.loadingStatus["light_logo_id"] === true
                      ? true
                      : false
                  }
                  type="url"
                  uploadedFiles={this.state.uploadedFiles}
                  placeholder={getCurrentlocaleText(
                    "branding.light.logo.url.placeholder.text",
                  )}
                  URLChange={value => {
                    this.URLChange("light_logo_url", value);
                  }}
                  customRequest={eventObject => {
                    this.UploadImageFile("light_logo_id", eventObject);
                  }}
                  isDisabled={
                    this.state.isLoadingIsInProgress ||
                    false ||
                    this.props.viewOnly
                  }
                  onRemove={() => {
                    this.onLogoRemove("light_logo_id");
                  }}
                  isUploadFailed={
                    this.state.uploadingFailedItems &&
                    this.state.uploadingFailedItems.indexOf("light_logo_id") !==
                      -1
                      ? true
                      : false
                  }
                  formData={formData}
                  url_type="light_logo_url"
                  onChange={() => {
                    let uploadingItems = this.state.uploadingItems || [];
                    let uploadIndx =
                      uploadingItems && uploadingItems.indexOf("light_logo_id");
                    if (uploadIndx !== -1) {
                      uploadingItems.push("light_logo_id");
                    } else {
                      uploadingItems.splice(uploadIndx, 1);
                    }
                    this.setState({ uploadingItems });
                  }}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText("branding.light.logo.url.text"),
                    content: getCurrentlocaleText(
                      "branding.light.logo.help.text",
                    ),
                  })}
                  fileList={this.getFileList("light_logo_id")}
                  uploadedInfo={
                    (this.state.uploadedInfo &&
                      this.state.uploadedInfo["light_logo_id"]) ||
                    {}
                  }
                  showUpload={
                    uploadingItems &&
                    uploadingItems.indexOf("light_logo_id") !== -1
                      ? true
                      : false
                  }
                />
              )}
            </div>,
          )}
        </FormItem>
        {/** Logo for favicon */}
        <FormItem
          label={
            <span>
              {this.state.uploadedInfo && this.state.uploadedInfo["favicon_id"]
                ? getCurrentlocaleText("branding.favicon.id.text")
                : getCurrentlocaleText("branding.favicon.url.text")}
            </span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator(
            this.state.uploadedInfo && this.state.uploadedInfo["favicon_id"]
              ? "favicon_id"
              : "favicon_url",
            {
              rules: [
                {
                  validator:
                    this.state.loadingStatus &&
                    this.state.loadingStatus["favicon_id"]
                      ? {}
                      : this.validateURL,
                },
              ],
              initialValue:
                this.state.uploadedInfo && this.state.uploadedInfo["favicon_id"]
                  ? formData && formData.favicon_id
                    ? formData.favicon_id
                    : ""
                  : formData && formData.favicon_url
                  ? formData.favicon_url
                  : "",
            },
          )(
            <div>
              {!(
                this.state.onLogoRemove && this.state.onLogoRemove.favicon_id
              ) && (
                <BrandingLogoUploader
                  loading={
                    this.state.loadingStatus &&
                    this.state.loadingStatus["favicon_id"] === true
                      ? true
                      : false
                  }
                  uploadedFiles={this.state.uploadedFiles}
                  URLChange={value => {
                    this.URLChange("favicon_url", value);
                  }}
                  isUploadFailed={
                    this.state.uploadingFailedItems &&
                    this.state.uploadingFailedItems.indexOf("favicon_id") !== -1
                      ? true
                      : false
                  }
                  type="url"
                  customRequest={eventObject => {
                    this.UploadImageFile("favicon_id", eventObject);
                  }}
                  key={"favicon_id"}
                  isDisabled={
                    this.state.isLoadingIsInProgress ||
                    false ||
                    this.props.viewOnly
                  }
                  showUpload={
                    uploadingItems &&
                    uploadingItems.indexOf("favicon_id") !== -1
                      ? true
                      : false
                  }
                  onRemove={() => {
                    this.onLogoRemove("favicon_id");
                  }}
                  formData={formData}
                  url_type="favicon_url"
                  onChange={() => {
                    let uploadingItems = this.state.uploadingItems || [];
                    let uploadIndx =
                      uploadingItems && uploadingItems.indexOf("favicon_id");
                    if (uploadIndx !== -1) {
                      uploadingItems.push("favicon_id");
                    } else {
                      uploadingItems.splice(uploadIndx, 1);
                    }
                    this.setState({ uploadingItems });
                  }}
                  placeholder={getCurrentlocaleText(
                    "branding.favicon.url.placeholder.text",
                  )}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText("branding.favicon.url.text"),
                    content: getCurrentlocaleText("branding.favicon.help.text"),
                  })}
                  fileList={this.getFileList("favicon_id")}
                  uploadedInfo={
                    (this.state.uploadedInfo &&
                      this.state.uploadedInfo["favicon_id"]) ||
                    {}
                  }
                />
              )}
            </div>,
          )}
        </FormItem>
        {/** Login background image urls */}
        {renderLoginBackgroundUrlFormItems}
        {/** Privacy policy Url */}
        <FormItem
          label={
            <span>
              {getCurrentlocaleText("branding.privacy_policy.url.text")}
            </span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("privacy_policy_url", {
            rules: [{ validator: this.validateURL }],
            initialValue:
              formData && formData.privacy_policy_url
                ? formData.privacy_policy_url
                : "",
          })(
            <Input
              type="url"
              autoComplete="off"
              disabled={this.props.viewOnly}
              placeholder={getCurrentlocaleText(
                "branding.privacy_policy.url.placeholder.text",
              )}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("branding.privacy_policy.url.text"),
                content: getCurrentlocaleText(
                  "branding.privacy_policy.help.text",
                ),
              })}
            />,
          )}
        </FormItem>
        {/** Sidebar Mode */}
        <FormItem
          label={
            <span>{getCurrentlocaleText("branding.sidebar.theme.text")}</span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("sidebar_mode", {
            initialValue:
              formData && formData.sidebar_mode ? formData.sidebar_mode : "",
          })(
            <div
              className="block-checbox"
              style={{
                pointerEvents: this.props.viewOnly === true ? "none" : "auto",
              }}
            >
              <div
                className="block-checkbox-item"
                onClick={this.handleSidebarOnClick.bind(this, "dark")}
              >
                {/** Sidebar dark */}
                <div style={{ width: 50, height: 45 }}>
                  <div
                    style={{
                      width: 10,
                      height: "inherit",
                      display: "inline-block",
                      background: "#001529",
                    }}
                  />
                  <div
                    style={{
                      width: 40,
                      height: "inherit",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{ width: 40, height: 10, background: "#fff" }}
                    />
                    <div
                      style={{ width: 40, height: 35, background: "#f3f3f3" }}
                    />
                  </div>
                </div>
                <div
                  title={getCurrentlocaleText(
                    "branding.sidebar.theme.dark.text",
                  )}
                  className="block-checbox-selectIcon"
                  style={{
                    display:
                      formData && formData.sidebar_mode === "dark"
                        ? "block"
                        : "none",
                  }}
                >
                  <Icons type="ai" name="AiOutlineCheckCircle" />
                </div>
              </div>
              <div
                disabled={this.props.viewOnly}
                className="block-checkbox-item"
                onClick={this.handleSidebarOnClick.bind(this, "light")}
              >
                {/** Sidebar Light */}
                <div style={{ width: 50, height: 45 }}>
                  <div
                    style={{
                      width: 10,
                      height: "inherit",
                      display: "inline-block",
                      background: "#fff",
                    }}
                  />
                  <div
                    style={{
                      width: 40,
                      height: "inherit",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{ width: 40, height: 10, background: "#fff" }}
                    />
                    <div
                      style={{ width: 40, height: 35, background: "#f3f3f3" }}
                    />
                  </div>
                </div>
                <div
                  title={getCurrentlocaleText(
                    "branding.sidebar.theme.light.text",
                  )}
                  className="block-checbox-selectIcon"
                  style={{
                    display:
                      formData && formData.sidebar_mode === "light"
                        ? "block"
                        : "none",
                  }}
                >
                  <Icons type="ai" name="AiOutlineCheckCircle" />
                </div>
              </div>
            </div>,
          )}
        </FormItem>
        {/** Primary Color */}
        <FormItem
          label={
            <span>{getCurrentlocaleText("branding.primary.color.text")}</span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("primary_color", {
            initialValue:
              formData && formData.primary_color ? formData.primary_color : "",
          })(
            <Input
              type="color"
              autoComplete="off"
              disabled={this.props.viewOnly}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("branding.primary.color.text"),
                content: getCurrentlocaleText(
                  "branding.primary.color.help.text",
                ),
              })}
              style={{ width: 150 }}
              title={
                formData && formData.primary_color ? formData.primary_color : ""
              }
            />,
          )}
        </FormItem>
        {/** Text Color */}
        <FormItem
          label={
            <span>{getCurrentlocaleText("branding.text.color.text")}</span>
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("text_color", {
            initialValue:
              formData && formData.text_color ? formData.text_color : "",
          })(
            <Input
              type="color"
              autoComplete="off"
              disabled={this.props.viewOnly}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("branding.text.color.text"),
                content: getCurrentlocaleText("branding.text.color.help.text"),
              })}
              style={{ width: 150 }}
              title={formData && formData.text_color ? formData.text_color : ""}
            />,
          )}
        </FormItem>
        {/** Font Url */}
        <FormItem
          label={<span>{getCurrentlocaleText("branding.font.url.text")}</span>}
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("font_source_url", {
            rules: [{ validator: this.validateFontURL }],
            initialValue:
              formData && formData.font_source_url
                ? formData.font_source_url
                : "",
          })(
            <Input
              type="url"
              autoComplete="off"
              disabled={this.props.viewOnly}
              placeholder={getCurrentlocaleText(
                "branding.font.url.placeholder.text",
              )}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("branding.font.url.text"),
                content: getCurrentlocaleText("branding.font.url.help.text"),
              })}
            />,
          )}
        </FormItem>
        {/** From email address */}
        {!isCentralAuthOrg && (
          <FormItem
            label={
              <span>{getCurrentlocaleText("branding.from.mail.text")}</span>
            }
            {...formItemLayout}
          >
            <div>
              <div style={{ display: "inline-block", width: "80%" }}>
                {" "}
                {getFieldDecorator("from_mail", {
                  rules: [
                    {
                      required: false,
                      message: getCurrentlocaleText(
                        "branding.from.mail.error.text",
                      ),
                      type: "email",
                    },
                  ],
                  initialValue:
                    formData && formData.from_mail ? formData.from_mail : "",
                })(
                  <Input
                    type="email"
                    autoComplete="off"
                    disabled={this.props.viewOnly}
                    prefix={<Icons type="ai" name="AiOutlineMail" />}
                    placeholder={getCurrentlocaleText(
                      "branding.form.mail.placeholder.text",
                    )}
                    onFocus={this.updateHelp.bind(this, {
                      title: getCurrentlocaleText("branding.from.mail.text"),
                      content: getCurrentlocaleText(
                        "branding.from.mail.help.text",
                      ),
                    })}
                  />,
                )}
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "10%",
                  textAlign: "center",
                  marginLeft: 10,
                }}
              >
                {formData &&
                  formData.from_mail &&
                  !formData.from_email_verified &&
                  formData.from_email_verify_triggered_at && (
                    <Popover
                      content={getCurrentlocaleText(
                        "user.support.email_triggered_at.message_text",
                        {
                          0: formatTimestampToIotiumLastAtTimeString(
                            formData.from_email_verify_triggered_at,
                          ),
                        },
                      )}
                    >
                      <Badge count={formData.from_email_verify_count}>
                        <Button
                          style={{ marginLeft: 10, fontSize: 10 }}
                          size={"small"}
                          onClick={this.enableResendLink}
                        >
                          <Icons
                            type="fa"
                            name="FaRegClock"
                            style={{ fontSize: 16 }}
                          />
                          {getCurrentlocaleText(
                            "user.support.resend_email.forcebutton.text",
                          )}
                        </Button>
                      </Badge>
                    </Popover>
                  )}
                {formData && formData.from_email_verified && (
                  <Popover
                    content={getCurrentlocaleText(
                      "branding.verify-email.success.message",
                    )}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineCheckCircle"
                      className="success_icon"
                    />
                  </Popover>
                )}
              </div>
            </div>
          </FormItem>
        )}

        <Modal
          title={
            formData &&
            !formData.from_email_verified &&
            formData.from_email_verify_triggered_at
              ? getCurrentlocaleText("branding.force_resend_email.title")
              : getCurrentlocaleText("branding.resend_email.title")
          }
          visible={this.state.showResendModal}
          onOk={this.SendVerificationEmail}
          onCancel={this.ignoreAlert}
          maskClosable={false}
          okText={
            formData &&
            !formData.from_email_verified &&
            formData.from_email_verify_triggered_at
              ? getCurrentlocaleText("branding.resend_email.forcebutton.text2")
              : getCurrentlocaleText("branding.resend_email.button.text2")
          }
          cancelText={getCurrentlocaleText(
            "branding.cancel_email.forcebutton.text2",
          )}
        >
          {formData &&
          !formData.from_email_verified &&
          formData.from_email_verify_triggered_at ? (
            <span>
              <h4 style={{ marginBottom: 15 }}>
                {getCurrentlocaleText(
                  "branding.resend_email.requestpending.message1",
                  {
                    0: formatTimestampToIotiumLastAtTimeString(
                      formData.from_email_verify_triggered_at,
                    ),
                  },
                )}
                <span>
                  <span className="email_address">
                    {getCurrentlocaleText(
                      "branding.resend_email.requestpending.message3",
                    )}
                  </span>
                  {"."}
                </span>
              </h4>{" "}
              <h4 style={{ marginBottom: 15 }}>
                {getCurrentlocaleText(
                  "branding.resend_email.requestpending.message2",
                  {
                    0: formatTimestampToIotiumLastAtTimeString(
                      formData.from_email_verify_triggered_at,
                    ),
                  },
                )}
              </h4>{" "}
            </span>
          ) : (
            <div>
              <h4 style={{ marginBottom: 20 }}>
                {getCurrentlocaleText("branding.resend_email.intial.message")}
              </h4>
              <span className="resend-modal-user-email">
                {formData && formData.from_mail}
              </span>
            </div>
          )}
        </Modal>
      </Form>
    );
  }
}

const BrandingForm = Form.create()(BrandForm);
export default BrandingForm;
