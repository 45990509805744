import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

import ActionBtn from "Core/API/ActionBtn";
import UsersForm from "components/Forms/UsersForm";

import UserActions from "components/UI-Components/UserActions";
import TableLayout from "components/layout/TableLayout";
import {
  highlightSearchText,
  copyObject,
  getCurrentlocaleText,
  formatTimestampToIotiumLastAtTimeString,
  getDaysLeftInfo,
  getAvailableTimezones,
  nanoToDateString,
  isoToDateString,
} from "Core/Utils";
import moment from "moment";

import ResendVerificationModal from "components/Containers/ResendVerificationModal";

import SearchInput from "components/UI-Components/SearchInput";
import Icons from "components/UI-Components/Icons";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import propTypes from "prop-types";
import OrgController from "controller/OrgController";
import UserController from "controller/UserController";

import { Row, Badge, Col, Checkbox, Icon, Button, Popover } from "antd";
import BulkConfigure from "components/BulkConfig/BulkConfigure";
import BulkConfigController from "../../controller/BulkConfigController";
import TableLayoutV1 from "../layout/TableLayoutV1";

const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
};

@inject(
  "AuthStore",
  "UiStore",
  "UserViewModel",
  "TimezoneViewModel",
  "OrgViewModel",
)
@observer
class OrgUsers extends Component {
  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.searchTimeout = null;
    this.state = {
      selectedUser: {},
      selectedRowKeys: [], // for table to identify selected rows
      deletableUsers: {
        users: [],
      },
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      currentPage: 1,
      expandedRowKeys: [],
      showResendModal: false,
      UserInfo: {},
      requestInProgress: false,
      autoOpen: false,
      autoOpenOrgID: null,
      modelAction: "create",
      currUserRecord: {},
      disabledPassword: false,
      showBulkConfig: false,
      isTableModalToggle: false,
      modalContent: "",
      modalTitle: "",
    };
  }
  componentWillUnmount() {
    this.props.UiStore.userParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };
  }

  componentDidMount() {
    if (this.props.cameFromDashboard) {
      this.props.UiStore.showChildUsers = true;
    }
    this.userList(true);
    let pathname = location.pathname;
    setTimeout(() => {
      if (pathname) {
        let path = pathname.split("/");
        if (path[1] === "orgs") {
          this.setState({
            showBulkConfig: true,
          });
        }
        if (
          path[1] === "orgs" &&
          path[2] &&
          path[3] === "users" &&
          path[4] === "create"
        ) {
          this.setState({
            autoOpen: true,
            modelAction: "create",
            disabledPassword: false,
          });
        } else if (
          path[1] === "orgs" &&
          path[2] &&
          path[3] === "users" &&
          path[4] &&
          path[5] === "edit"
        ) {
          this.setState({
            autoOpen: true,
            autoOpenOrgID: path[4],
            modelAction: "edit",
            disabledPassword: true,
          });
          this.getUserById(path[4]);
        }
      }
    }, 200);
  }

  getUserById = id => {
    if (id) {
      UserController.getUser(id, false, true).then(resp => {
        this.setState({ currUserRecord: resp });
      });
    }
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId !== props.orgId) {
      this.userList(
        true,
        0,
        this.state.searchText,
        this.state.sorterInfo.sortBy,
        this.state.sorterInfo.sortOrder,
        props.params,
      );
    }
  }

  userList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    params = this.props.params || {},
  ) => {
    params = copyObject(params);
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (this.props.UiStore.showChildUsers) {
      delete params.own;
    } else {
      params.own = true;
    }
    UserController.getUsers(params, loading);
  };

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.props.onAfterClick("users");
    this.userList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.loadOrgState();
    this.resetState();
  };

  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.userModel.usersData.length == 0) {
      page--;
    }
    this.props.onAfterClick("users");
    this.userList(
      true,
      page - 1,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.loadOrgState();
    this.resetState();
  };

  // selected table row status
  setSelectedStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      if (selectedRows[i]) {
        let id = selectedRows[i].id;

        this.setState(() => {
          let newObj = {
              deletableUsers: {
                users: [],
              },
            },
            selectedUser = this.state.selectedUser,
            item = selectedRows[i],
            tempArray = [];

          selectedUser[id] = {
            value: true,
            name: item.name,
            id: item.id,
          };

          //Temp array for deletable users list
          for (let i in selectedRows) {
            if (selectedRows[i] && selectedUser[selectedRows[i].id]) {
              tempArray.push(selectedUser[selectedRows[i].id]);
            }
          }
          // To set the value : false , when users is deselected and remove it from delete list

          !selected && selectedUser[record.id]
            ? ((selectedUser[record.id].value = false),
              (tempArray = _.filter(tempArray, val => {
                return val.id !== record.id;
              })))
            : null;

          newObj["selectedUser"] = selectedUser;
          newObj.deletableUsers["users"] = tempArray;
          return newObj;
        });
      }
    }
  };
  // select all status functions
  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };
  //ontable change events
  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };

  userListFilter = e => {
    clearTimeout(this.timeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.props.UiStore.userParams.searchText = searchText;
    this.timeout = setTimeout(() => {
      this.userList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ currentPage: 1, searchText: "" });
    this.props.UiStore.userParams.searchText = "";
    this.userList(
      true,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.props.UiStore.userParams.filterInfo = filter;
    this.props.UiStore.userParams.sorterInfo = { sorter: sorter, sort: sort };
    this.userList(true, page, this.state.searchText, sort.by, sort.order);
  };

  resetState = () => {
    this.setState({
      selectedUser: {},
      selectedRowKeys: [],
      modelAction: "create",
      currUserRecord: {},
      disabledPassword: false,
      deletableUsers: {
        users: [],
      },
    });
  };

  loadOrgState = () => {
    if (this.props.loadOrgState) {
      OrgController.getOrg(this.props.orgId, false, true).then(resp => {
        this.props.UiStore.setOrgName(resp.name);
      });
    }
  };

  expandRow = () => {
    let keys = this.userModel.usersData.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle:
        record && record.value && record.value.name ? record.value.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  getMoreInfo(record) {
    let timeZone = this.updateUserTimezone(record);
    return (
      <div>
        <Row style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={5}>
            <strong>
              {getCurrentlocaleText(
                "users.list.more_info.password_expiry.label.text",
              )}
            </strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={18}>
            {/*All Expiry state Time stamp should be shown in this format across IOTIUM UI*/}
            {getDaysLeftInfo(record.password_expire_at)}
            {/* {formatTimestampToIotiumLastAtTimeString(
                record.password_expire_at,
              )} */}
          </Col>
        </Row>
        <Row style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={5}>
            <strong>{getCurrentlocaleText("myprofile.timezone.text")}</strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={18}>{timeZone.tzDisplayValue}</Col>
        </Row>
      </div>
    );
  }

  updateUserTimezone = user => {
    // Replace browser timezone
    let Timezones = copyObject(getAvailableTimezones());
    // Check the user has timezone
    if (!user.value.timezone) {
      // Check current org has timezone or not
      if (this.props.TimezoneViewModel.orgTimezone) {
        let timezone = Timezones.find(tz => {
          return tz.value === this.props.TimezoneViewModel.orgTimezone;
        });
        user.timezone = "";
        user.tzDisplayValue = getCurrentlocaleText("org_default_timezone.text");
      } else {
        // Org default timezone
        user.timezone = "";
        user.tzDisplayValue = getCurrentlocaleText("org_default_timezone.text");
      }
    } else {
      let timezone = Timezones.find(tz => {
        return tz.value === user.value.timezone;
      });
      // check if user timezone is equal to org timezone
      if (timezone.value === this.props.TimezoneViewModel.orgTimezone) {
        user.tzDisplayValue = getCurrentlocaleText("org_default_timezone.text");
      } else {
        user.tzDisplayValue = timezone.label;
      }
    }
    return user;
  };

  changeListview = e => {
    this.props.UiStore.showChildUsers = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.userList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  // Check for API key security warning
  checkForWarningMessage(last_login_at) {
    let last_login_at_time = nanoToDateString(last_login_at);
    let currentDate = moment(new Date().getTime());
    let lastLoginTime = moment(new Date(last_login_at_time).getTime());
    if (last_login_at != null) {
      let LastLogin = currentDate.diff(lastLoginTime, "days");
      if (LastLogin > 90) {
        return true;
      }
    } else {
      return false;
    }
  }

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  enableResendLink = data => {
    if (data.email_count > 0) {
      this.setState({
        showResendModal: true,
        UserInfo: data,
        requestInProgress: true,
      });
    } else {
      this.setState({ showResendModal: true, UserInfo: data });
    }
  };
  hidePopup = () => {
    this.setState({ showResendModal: false, UserInfo: {} });
  };

  downloadUsers = (bulkConfig = false) => {
    let params = {};
    params.search = this.state.searchText;
    if (this.state.sorterInfo.sort.by && this.state.sorterInfo.sort.order) {
      params.sort = `${this.state.sorterInfo.sort.by}:${this.state.sorterInfo.sort.order}`;
    }
    if (this.props.UiStore.showChildUsers) {
      delete params.own;
    } else {
      params.own = true;
    }
    if (bulkConfig) {
      params.fields = "name,email,role";
    }
    if (this.props.orgId) {
      params.org_id = this.props.orgId;
    }

    BulkConfigController.downloadUsers(params, false);
  };

  onNewUserTemplate = () => {
    BulkConfigController.downloadUsersTemplate();
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};

    let usersData = this.userModel.usersData;

    //table columns
    this.columns = [
      {
        title: (
          <span title={getCurrentlocaleText("myprofile.fullname.text")}>
            {getCurrentlocaleText("myprofile.fullname.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: this.props.UiStore.showChildUsers ? "20%" : "15%",
        ellipsis: true,
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          return (
            <div className="col-wrap">
              <Link
                to={
                  "/orgs/" +
                  record.organization.id +
                  "/usersdetails/" +
                  record.id
                }
              >
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(text, this.state.searchText)
                  : text}
              </Link>
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("support.email_address.text")}>
            {getCurrentlocaleText("support.email_address.text")}
          </span>
        ),
        dataIndex: "emailaddress",
        key: "username",
        width: !this.props.UiStore.showChildUsers ? "30%" : "35%",
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "username" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span className="col-wrap" style={{ width: "70%" }}>
                {text}
              </span>
              {(this.props.AuthStore.IsPermitted("USER:CREATE") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <span>
                  {!record.is_verified && (
                    <span
                      onClick={e => {
                        this.enableResendLink(record);
                        e.stopPropagation();
                      }}
                      style={{ verticalAlign: "middle" }}
                    >
                      {record.last_triggerred_at ? (
                        <Popover
                          content={getCurrentlocaleText(
                            "user.support.email_triggered_at.message_text",
                            {
                              0: formatTimestampToIotiumLastAtTimeString(
                                record.last_triggerred_at,
                              ),
                            },
                          )}
                        >
                          <Badge count={record.email_count}>
                            <Button
                              style={{ marginLeft: 10, fontSize: 10 }}
                              size={"small"}
                            >
                              <Icons
                                type="fa"
                                name="FaRegClock"
                                style={{ fontSize: 16 }}
                              />
                              {getCurrentlocaleText(
                                "user.support.resend_email.forcebutton.text",
                              )}
                            </Button>
                          </Badge>
                        </Popover>
                      ) : (
                        <Popover
                          content={
                            record.is_me
                              ? getCurrentlocaleText(
                                  "user.profile.unverified_email_reintiate.info_message",
                                )
                              : getCurrentlocaleText(
                                  "user.unverified_email_reintiate_for_others.info_message",
                                )
                          }
                          overlayStyle={{ width: 350 }}
                        >
                          <Badge count={record.email_count}>
                            <Button
                              style={{ marginLeft: 10, fontSize: 10 }}
                              size={"small"}
                            >
                              {getCurrentlocaleText(
                                "user.support.resend_email.forcebutton.text",
                              )}
                            </Button>
                          </Badge>
                        </Popover>
                      )}
                    </span>
                  )}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("myprofile.organization.text")}>
            {getCurrentlocaleText("myprofile.organization.text")}
          </span>
        ),
        dataIndex: "org",
        key: "org",
        width: "15%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div className="col-wrap">
              <Link to={"/dashboard/" + record.value.organization.id}>
                {record.value &&
                  record.value.organization &&
                  record.value.organization.name}
              </Link>
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("general.component.role.text")}>
            {getCurrentlocaleText("general.component.role.text")}
          </span>
        ),
        dataIndex: "role",
        key: "roles",
        width: this.props.UiStore.showChildUsers ? "15%" : "20%",
        ellipsis: true,
        render: (text, record, index) => {
          return <div className="col-wrap">{text}</div>;
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("columns.2fa.title")}>
            {getCurrentlocaleText("columns.2fa.title")}
          </span>
        ),
        dataIndex: "two_factor",
        key: "two_factor",
        width: "10%",
        ellipsis: true,
        render: (text, record, index) => {
          let x = record.two_factor ? (
            <Icons
              type="ai"
              name="AiOutlineCheckCircle"
              className={
                this.props.OrgViewModel &&
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.policy &&
                this.props.OrgViewModel.org.policy.two_factor &&
                this.props.OrgViewModel.org.policy.two_factor.force == true
                  ? "middle iot-primary-color-style"
                  : "middle"
              }
              style={{
                iconStyle,
              }}
            />
          ) : (
            "-"
          );

          return <span style={{ marginLeft: 10 }}>{x}</span>;
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "users.list.more_info.last_login.label.text",
            )}
          >
            {getCurrentlocaleText("users.list.more_info.last_login.label.text")}
          </span>
        ),
        dataIndex: "last_login",
        key: "last_login",
        width: this.props.UiStore.showChildUsers ? "15%" : "20%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div className="col-wrap">
              {record.last_login_at
                ? isoToDateString(record.last_login_at)
                : getCurrentlocaleText(
                    "user.api_keys.key.validity.button.text2",
                  )}
              {this.checkForWarningMessage(record.last_login_at) && (
                <Popover
                  overlayStyle={{ maxWidth: "20%", float: "right" }}
                  placement="right"
                  content={getCurrentlocaleText(
                    "user.expired.lastused_exceeds_time.error",
                  )}
                >
                  <span>
                    <Icons
                      style={{ fontSize: 15, marginLeft: 5, color: "orange" }}
                      className=""
                      type="fa"
                      name="FaExclamationCircle"
                    />
                  </span>
                </Popover>
              )}
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: "10%",
        render: (text, record, index) => {
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <UserActions
                isUnlocked={record.locked ? true : false}
                formData={record}
                orgId={record.organization.id}
                userId={record.id ? record.id : this.state.autoOpenOrgID}
                disabled={record.disabled}
                initialVal={record.value}
                disabledEdit={record.disabledEdit}
                isMe={record.is_me}
                afterCall={this.deleteAfterCall.bind()}
                twoFactor={record.two_factor}
                changeRoute={this.props.changeRoute}
                onAfterClick={this.props.onAfterClick}
                isResetRequestPending={
                  record.reset_password_count ? true : false
                }
                user_info={this.state.UserInfo}
                reset_password_triggered_at={record.reset_password_triggered_at}
                password_count={record.reset_password_count}
                updateAfterCall={this.createAfterCall}
                userName={record.name}
              />
            </div>
          );
        },
      },
    ];

    if (!this.props.UiStore.showChildUsers) {
      this.columns.splice(2, 1);
    }

    if (
      !this.props.AuthStore.IsPermitted("SUPER:ADMIN") &&
      !this.props.AuthStore.IsPermitted("USER:ADMIN") &&
      !this.props.AuthStore.IsPermitted("ORG:SUPPORT")
    ) {
      /* show TFA for all orgs for admin and support only */
      let indx = null;
      this.columns.forEach((val, i) => {
        if (val.key.toLowerCase() === "two_factor") {
          indx = i;
        }
      });
      indx && this.columns.splice(indx, 1);
    }

    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "50%" }}>
          <span className="headertext">
            {getCurrentlocaleText("dashboard.counts.users.tooltip")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildUsers}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("user.list.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchInput
              placeholder="Filter users by full name"
              onChange={this.userListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.AuthStore.IsPermitted("USER:CREATE") && (
              <ActionBtn
                type="primary"
                title={
                  this.state.modelAction == "create"
                    ? getCurrentlocaleText("users.create.button_title.text")
                    : getCurrentlocaleText("users.edit.button_title.text")
                }
                icon="AiOutlinePlus"
                resource="users"
                iconButton
                disabledPassword={this.state.disabledPassword}
                map={{ id: this.state.currUserRecord.id }}
                initialVal={this.state.currUserRecord}
                action={
                  this.state.modelAction ? this.state.modelAction : "create"
                }
                width={600}
                changeRoute={this.props.changeRoute}
                orgId={this.props.orgId}
                autoOpenModel={this.state.autoOpen}
                actionForm={UsersForm}
                afterCall={this.createAfterCall}
                controller={UserController}
              >
                {/* <Icons type="ai" name="AiOutlinePlus" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("users.create.button_title.text")} */}

                {this.state.modelAction == "create" && (
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlinePlus"
                      style={{ margin: 0 }}
                    />{" "}
                    {getCurrentlocaleText("users.create.button_title.text")}
                  </span>
                )}

                {this.state.modelAction == "edit" && (
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" />{" "}
                    {getCurrentlocaleText("update.text")}{" "}
                  </span>
                )}
              </ActionBtn>
            )}
            {this.props.AuthStore.IsPermitted("USER:DELETE") && (
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {this.state.deletableUsers.users.length > 1
                      ? getCurrentlocaleText(
                          "multiple.user.delete.confirmation.title",
                        )
                      : getCurrentlocaleText(
                          "single.user.delete.confirmation.title",
                        )}
                  </span>
                }
                messageContent={
                  this.state.deletableUsers.users.length > 1
                    ? getCurrentlocaleText(
                        "multiple.user.delete.confirmation.message",
                      )
                    : getCurrentlocaleText(
                        "single.user.delete.confirmation.message",
                      )
                }
                orgId={this.props.orgId}
                icon="AiOutlineDelete"
                formData={this.state.deletableUsers.users}
                iconButton
                resource="users"
                action="delete"
                afterCall={this.deleteAfterCall}
                disabled={this.state.deletableUsers.users.length === 0}
                controller={UserController}
                cancelText={
                  this.state.deletableUsers.users.length > 1
                    ? getCurrentlocaleText(
                        "multiple.user.delete.confirmation.cancel.text",
                      )
                    : getCurrentlocaleText(
                        "single.user.delete.confirmation.cancel.text",
                      )
                }
                warningMessage={
                  this.state.deletableUsers.users.length > 1
                    ? getCurrentlocaleText(
                        "multiple.user.delete.warning.message",
                      )
                    : getCurrentlocaleText("single.user.delete.warning.message")
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {this.state.deletableUsers.users.length > 1
                  ? getCurrentlocaleText(
                      "multiple.user.delete.confirmation.ok.text",
                    )
                  : getCurrentlocaleText(
                      "single.user.delete.confirmation.ok.text",
                    )}
              </ActionBtn>
            )}
          </div>
        </div>
        {this.state.showBulkConfig && (
          <div>
            <BulkConfigure
              readOnly={!this.props.AuthStore.IsPermitted("USER:CREATE")}
              getList={this.userList}
              changeRoute={this.props.changeRoute}
              orgId={this.props.orgId}
              onAfterClick={this.props.onAfterClick}
              createTemplate={this.onNewUserTemplate}
              download={this.downloadUsers}
              resource={"User"}
            />
          </div>
        )}
        {this.userModel.users.length > 0 ? (
          <div>
            <TableLayoutV1
              selectedRows={this.handleTableChange.bind(null)}
              selectAll={this.onSelectAllChange.bind(null)}
              columns={this.columns}
              pagination={{
                pageSize: this.userModel && this.userModel.pageable.size,
                total: this.userModel && this.userModel.pageable.total_count,
                current: this.state.currentPage,
              }}
              dataSource={usersData}
              expandedRowKeys={this.state.expandedRowKeys}
              onExpand={this.onExpand}
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              onChange={this.onTableChange}
              handleModalOk={e => this.handleModalOk(e)}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              isModalLoading={false}
              disableFooter={true}
              className="userList"
            />

            <ResendVerificationModal
              visibility={this.state.showResendModal}
              requestInProgress={this.state.requestInProgress}
              user_info={this.state.UserInfo}
              hidePopup={this.hidePopup}
              updateAfterCall={this.createAfterCall}
            />
          </div>
        ) : (
          <div>{!this.userModel.loading && <h3>No users to display</h3>}</div>
        )}
      </div>
    );

    return (
      <LoadingComponent loading={this.userModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

OrgUsers.propTypes = {
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
  orgId: propTypes.string.isRequired,
};

export default OrgUsers;
