import React, { Component } from "react";
import { Modal, Button, Select, Checkbox, Spin, Input } from "antd";
import { Form } from "@ant-design/compatible";
import InputBox from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import html2canvas from "html2canvas";
import axios from "axios";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import { getCurrentlocaleText, getErrorMessage } from "Core/Utils";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const ModalLeftContent = {
  width: "49%",
  display: "inline-block",
  float: "left",
};

const ModalRightContent = {
  width: "50%",
  float: "right",
  display: "inline-block",
};

const ModalBodyStyle = {
  overflow: "hidden",
};

@inject("AuthStore", "ModelStore", "UiStore")
@observer
class SupportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: this.props.UiStore.ContactSupportMdl,
      screenshot: true,
      supportImage: null,
      file: null,
      loading: false,
      imgLoading: false,
      imageTimeout: 0,
      url: null,
      emailAddress: null,
    };
    this.handleUpload = this.handleUpload.bind(this);
  }

  UNSAFE_componentWillMount() {
    var _this = this;
    _this.setState({ imgLoading: true });
    //html to canvas conversion
    html2canvas(document.body, {
      onrendered: function(canvas) {
        var img = new Image();
        img.src = canvas.toDataURL("image/png");
        canvas.toBlob(blob => {
          _this.setState({ supportImage: blob, imgLoading: false });
        });
        let element = document.getElementById("support-ss-img");
        if (element) {
          element.src = img && img.src ? img.src : "";
          element.style.display = img && img.src ? "block" : "none";
        }
      },
      useCORS: true,
      foreignObjectRendering: true,
      imageTimeout: _this.state.imageTimeout,
    });
    this.setState({
      url: window.location,
      emailAddress: this.props.AuthStore.loggedInUser.username,
    });
    this.currentDocTitle = this.props.UiStore.currentDocTitle;
    this.props.UiStore.setDocumentTitle(
      getCurrentlocaleText("supportform.navigation_menu.text"),
    );
  }

  //modal close
  closeModal = () => {
    this.setState({ loading: false });
    this.props.UiStore.ContactSupportMdl = false;
    this.props.UiStore.setDocumentTitle(
      this.currentDocTitle.substring(0, this.currentDocTitle.lastIndexOf("-")),
    );
  };
  //On submit action
  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var currentUser = this.props.AuthStore.loggedInUser.username;
        var currentUserName = this.props.AuthStore.loggedInUser.name;
        var currentUserOrg = this.props.AuthStore.loggedInUser.organization
          ? this.props.AuthStore.loggedInUser.organization.name
          : null;
        this.setState({ loading: true });
        // preparing form data
        var formData = new FormData();
        // Attachment functionality
        if (this.state.file) {
          formData.append("attachment", this.state.file);
        }
        //includes screenshot to post data
        if (this.state.screenshot) {
          formData.append("screenshot", this.state.supportImage);
        }
        formData.append("description", values["description"]);
        formData.append("requestType", values["requestType"]);
        formData.append("url", values["url"]);
        formData.append("currentUser", currentUser);
        formData.append("emailAddress", values["emailAddress"]);
        formData.append("organization", currentUserOrg);
        formData.append("name", currentUserName);
        formData.append("userAgent", navigator.userAgent);
        let sendSupportReq = axios.post("/support/request", formData);
        sendSupportReq
          .then(response => {
            this.closeModal();
            this.props.UiStore.setSuccessMessage(
              "Request submitted successfully",
            );
          })
          .catch(error => {
            this.setState({ loading: false });
            let error_msg = getErrorMessage(
              error,
              getCurrentlocaleText("supportform.navigation_menu.text"),
            );
            this.props.UiStore.setErrorMessage(error_msg);
            this.props.UiStore.setFormErrorMessage(error_msg);
          });
      }
    });
  }
  //include screenshot or not
  changeSupportImage() {
    if (this.state.screenshot) {
      this.setState({ screenshot: false });
    } else {
      this.setState({ screenshot: true });
    }
  }
  //checks file size should be less than 10 mb
  handleUpload(event) {
    if (event.target.files && event.target.files[0]) {
      let fileSize = event.target.files[0].size;
      if (fileSize < 10485760 && fileSize > 0) {
        this.setState({ file: event.target.files[0] });
      } else {
        if (fileSize === 0) {
          this.props.UiStore.setErrorMessage(
            getCurrentlocaleText("support.zero_file_size_error_message.text"),
          );
        } else {
          this.props.UiStore.setErrorMessage(
            getCurrentlocaleText("support.file_size_error_message.text"),
          );
        }
        setTimeout(() => {
          this.clearAttachment();
        }, 10);
      }
    }
  }

  componentWillUnMount() {
    this.setState({
      imageTimeout: 10,
    });
  }

  clearAttachment = () => {
    let obj = {};
    obj["attachment"] = {};
    this.props.form.setFields(obj);
    this.setState({ file: null });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let imgLoaderStyle = {
      position: "relative",
      top: "65px",
      textAlign: "center",
    };

    return (
      <Modal
        wrapClassName="vertical-center-modal"
        title={getCurrentlocaleText("supportform.navigation_menu.text")}
        maskClosable={false}
        visible={this.props.UiStore.ContactSupportMdl}
        onCancel={this.closeModal}
        confirmLoading={this.state.loading}
        closable={!this.state.loading}
        footer={[
          <Button
            key="support-cancel"
            disabled={this.state.loading}
            onClick={this.closeModal}
          >
            {" "}
            {getCurrentlocaleText("support.cancel.text")}
          </Button>,
          <Button
            key="support-submit"
            type="primary"
            onClick={this.handleSubmit.bind(this)}
            loading={this.state.loading}
          >
            {" "}
            {getCurrentlocaleText("support.submit.text")}
          </Button>,
        ]}
        width={860}
      >
        <Form>
          <div style={ModalBodyStyle}>
            <div style={ModalLeftContent}>
              <FormItem
                label={getCurrentlocaleText("support.request_type.text")}
              >
                {getFieldDecorator("requestType", {
                  initialValue: getCurrentlocaleText(
                    "support.reported_an_issue.text",
                  ),
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "support.request_type_error_message.text",
                      ),
                    },
                  ],
                })(
                  <Select
                    placeholder={getCurrentlocaleText(
                      "support.request_type_placeholder.text",
                    )}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                  >
                    <Option
                      value={getCurrentlocaleText(
                        "support.reported_an_issue.text",
                      )}
                    >
                      {getCurrentlocaleText("support.reported_an_issue.text")}
                    </Option>
                    <Option
                      value={getCurrentlocaleText(
                        "support.submited_an_improvement.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "support.submited_an_improvement.text",
                      )}
                    </Option>
                  </Select>,
                )}
              </FormItem>
              <FormItem
                label={getCurrentlocaleText("support.description.text")}
              >
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "support.description_error_message1.text",
                      ),
                      whitespace: true,
                    },
                    {
                      max: 1000,
                      message: getCurrentlocaleText(
                        "support.description_error_message2.text",
                      ),
                    },
                  ],
                })(
                  <TextArea
                    style={{ resize: "none" }}
                    placeholder={getCurrentlocaleText(
                      "support.description_placeholder.text",
                    )}
                    rows={5}
                  />,
                )}
              </FormItem>
              <span>
                <p className="disclaimer-font">
                  {getCurrentlocaleText("support.description_max_size.text")}
                </p>
                <p>&nbsp;</p>
              </span>
              <FormItem label={getCurrentlocaleText("support.attachment.text")}>
                {getFieldDecorator(
                  "attachment",
                  {},
                )(
                  <InputBox
                    type="file"
                    id="supportFile"
                    multiple={false}
                    onClick={this.clearAttachment}
                    onChange={this.handleUpload}
                    style={{ height: "37px" }}
                    suffix={
                      <span
                        onClick={this.clearAttachment}
                        style={{
                          cursor: "pointer",
                          display: this.state.file ? "block" : "none",
                        }}
                      >
                        <Icons
                          type="ai"
                          name="AiOutlineCloseCircle"
                          style={{ fontSize: 16 }}
                        />
                      </span>
                    }
                  />,
                )}
              </FormItem>
              <span>
                <p className="disclaimer-font">
                  {getCurrentlocaleText("support.file_size_information.text")}
                </p>
                <p className="disclaimer-font">
                  {getCurrentlocaleText("support.disclaimer.text")}
                </p>
              </span>
            </div>
            <div />
            <div style={ModalRightContent}>
              <FormItem label={getCurrentlocaleText("support.url.text")}>
                {getFieldDecorator("url", {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                  initialValue: this.state.url ? this.state.url : null,
                })(
                  <InputBox
                    type="text"
                    placeholder={getCurrentlocaleText("support.url.text")}
                    disabled={true}
                  />,
                )}
              </FormItem>
              <FormItem
                label={getCurrentlocaleText("support.email_address.text")}
              >
                {getFieldDecorator("emailAddress", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "support.email_address_error_message",
                      ),
                    },

                    {
                      type: "email",
                      message: getCurrentlocaleText(
                        "support.email_address_validation.text",
                      ),
                    },
                  ],
                  initialValue: this.state.emailAddress
                    ? this.state.emailAddress
                    : null,
                })(
                  <InputBox
                    autoComplete="off"
                    type="email"
                    placeholder={getCurrentlocaleText(
                      "support.email_address_placeholder",
                    )}
                    disabled={true}
                  />,
                )}
              </FormItem>
              <div>
                <Checkbox
                  checked={this.state.screenshot}
                  onChange={this.changeSupportImage.bind(this)}
                >
                  {getCurrentlocaleText("support.include_this_screenshot.text")}
                </Checkbox>
                <div id="supportSS">
                  {this.state.imgLoading && (
                    <div style={imgLoaderStyle}>
                      <Spin
                        tip={getCurrentlocaleText("support.loading.text")}
                      />
                    </div>
                  )}
                  <img id="support-ss-img" style={{ display: "none" }} />
                </div>
              </div>
            </div>
          </div>
          <FormErrorMessage />
        </Form>
      </Modal>
    );
  }
}
const Support = Form.create()(SupportForm);

export default Support;
