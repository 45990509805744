import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Icon,
  Select,
  Pagination,
  Button,
  Alert,
  Cascader,
  Table,
  Tag,
  Popover,
  message,
  Checkbox,
  Tabs,
  Radio,
  Tooltip,
  Collapse,
} from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";

import {
  getCurrentlocaleText,
  makeEmpty,
  isCidr,
  getCIDRdata,
  randomAlphaNumericString,
  copyObject,
  markCollapseHeader,
  formConnectNetworkData,
} from "../../../../Core/Utils";
import RouteInfo from "../../../../components/INodes/RouteInfo";
import Icons from "../../../../components/UI-Components/Icons";

import InodeController from "../../../../controller/InodeController";
import ClusterController from "../../../../controller/ClusterController";

import CidrProvider from "components/UI-Components/CidrProvider";
import NetworkMultiConnectFunc from "../../compute/NetworkMultiConnectFunc";

import NetworkStatus from "../../../../components/Forms/Components/Network/NetworkStatus";
import DiodeIcon from "components/Forms/Components/Network/DiodeIcon";
import RemoteNetworkRemoveConfirmDialog from "components/Forms/Components/Network/RemoteNetworkRemoveConfirmDialog";
import "../../../../assets/styles/networkMulticonnect.css";
import RemoteNetworkTag from "components/Forms/Components/Network/RemoteNetworkTag";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const formItemLayout2 = {
  labelCol: { span: 0 },
  wrapperCol: { span: 18 },
};
const formItemLayout3 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 6 },
};
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
const TabPane = Tabs.TabPane;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;

@inject(
  "UiStore",
  "InodeViewModel",
  "NetworkViewModel",
  "ProfileViewModel",
  "AuthStore",
)
@observer
class Connect extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.profileModel = this.props.ProfileViewModel;
    this.networkStatus = {};
    this.state = {
      disableConnect: true,
      newNetwork: true,
      selectedNetworkId: null,
      nodeId: null,
      data: [],
      mounting: false,
      networkOptions: [],
      currentNetworkValue: {},
      NetworkInfo: [],
      showAddButton: false,
      addedNetworks: [],
      currentAddednetworks: [],
      SelectedNetworks: [],
      remoteNetworks: [],
      isSelectDisabled: false,
      editable: [],
      value: "",
      isloading: true,
      availableNetworks: this.props.availableNetwork || [],
      showWarning: this.props.UiStore.showRepNetwork,
      currentRemoteCidr: null,
      currentRowKey: null,
      currentLocalRepCidr: null,
      conflictedNetworks: this.props.UiStore.formErrorResponse.data
        ? this.props.UiStore.formErrorResponse.data.errorList
        : {},
      expandedRow: [],
      defaultActiveKey: "2",
      firewallLabel_options: this.props.firewallLables || [],
      firewallSelectors: {},
      showCustom: false,
      showRouteRemoveConfirmation: false,
      isVirtualNode: this.props.isVirtualNode,
      VirtualNodeDetails: {},
      isCidrExist: this.props.isCidrExist,
      resetRemoteRepresentationNetwork: [],
      showInfo: false,
      isUCGNode: false,
    };
  }
  /**
   * Selector contructor
   */
  selectorConstructor = values => {
    let fw_selector_values = {};
    if (values) {
      values.forEach(value => {
        let data = value.split(":");
        fw_selector_values[data[0].trim()] = data[1].trim();
      });
    }
    return fw_selector_values;
  };

  /**
   * Method to handel change
   */
  handleChange = values => {
    let fw_selector_values = {};
    let remoteNetworks = this.state.currentAddednetworks,
      updatedNetworkList = [];
    values.forEach(value => {
      let data = value.split(":");
      fw_selector_values[data[0]] = data[1];
    });

    remoteNetworks.filter(network => {
      if (network.network_id === this.state.currentRowKey) {
        let selectors =
          (network.firewall_selector &&
            network.firewall_selector.match_label) ||
          {};
        let currentSelector = Object.assign(selectors, fw_selector_values);
        network.firewall_selector = { match_label: currentSelector };
      }
      updatedNetworkList.push(network);
    });
    this.pushConnectedNetworksdata(updatedNetworkList);
  };
  /**
   *
   * Handle Diode Change
   */
  handleDiodeChange = e => {
    const value = e.target.value;
    let fw_selector_values = {};
    let remoteNetworks = this.state.currentAddednetworks,
      updatedNetworkList = [];
    if (value === 3) {
      this.setState({ showCustom: true });
    } else {
      remoteNetworks.filter(network => {
        if (network.network_id === this.state.currentRowKey) {
          if (Array.isArray(network.firewall_selector)) {
            let d = {};
            if (value != null) {
              d["_iotium.firewall.diode"] =
                value === 1 ? "ingress-only" : "egress-only";
              network.firewall_selector = { match_label: d };
            } else {
              delete d["_iotium.firewall.diode"];
              network.firewall_selector = { match_label: d };
            }
          } else {
            if (
              network.firewall_selector &&
              network.firewall_selector.match_label
            ) {
              let d = {};
              if (value != null) {
                d["_iotium.firewall.diode"] =
                  value === 1 ? "ingress-only" : "egress-only";
                network.firewall_selector = { match_label: d };
              } else {
                delete d["_iotium.firewall.diode"];
                network.firewall_selector = { match_label: d };
              }
            } else {
              if (
                network.firewall_selector &&
                network.firewall_selector.match_label
              ) {
                if (value != null) {
                  network.firewall_selector.match_label[
                    "_iotium.firewall.diode"
                  ] = value === 1 ? "ingress-only" : "egress-only";
                } else {
                  delete network.firewall_selector.match_label[
                    "_iotium.firewall.diode"
                  ];
                  network.firewall_selector = {
                    match_label: network.firewall_selector.match_label,
                  };
                }
              } else {
                if (value != null) {
                  let label = {
                    "_iotium.firewall.diode":
                      value === 1 ? "ingress-only" : "egress-only",
                  };
                  let selector = { match_label: label };
                  network["firewall_selector"] = selector;
                } else {
                  let selector = { match_label: {} };
                  network["firewall_selector"] = selector;
                }
              }
            }
          }
        }
        updatedNetworkList.push(network);
      });
      this.pushConnectedNetworksdata(updatedNetworkList);
    }
  };
  /**
   * handleTunnelChange
   */
  handleTunnelChange = value => {
    let remoteNetworks = this.state.currentAddednetworks,
      updatedNetworkList = [];
    remoteNetworks.filter(network => {
      if (network.network_id === this.state.currentRowKey) {
        if (Array.isArray(network.firewall_selector)) {
          let d = {};
          delete d["_iotium.firewall.diode"];
          network.firewall_selector = { match_label: d };
        } else {
          let d = this.selectorConstructor(value);
          if (
            network.firewall_selector &&
            network.firewall_selector.match_label
          ) {
            delete network.firewall_selector.match_label[
              "_iotium.firewall.diode"
            ];
            network.firewall_selector = {
              match_label: d,
            };
          } else {
            let selector = { match_label: d };
            network["firewall_selector"] = selector;
          }
        }
      }
      updatedNetworkList.push(network);
    });
    this.pushConnectedNetworksdata(updatedNetworkList);
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.formData &&
      this.props.formData &&
      JSON.stringify(props.formData.connected_networks) !==
        JSON.stringify(this.props.formData.connected_networks)
    ) {
      this.updateiNodeDetails();
    }
    if (props.NetworkViewModel.networks) {
      let in_dx = props.NetworkViewModel.networks.findIndex(nw => {
        return nw.id === props.netWorkId && !nw.is_wan_network;
      });
      if (in_dx !== -1) {
        if (
          props.NetworkViewModel.networks[in_dx] &&
          props.NetworkViewModel.networks[in_dx].status
        ) {
          if (
            JSON.stringify(this.networkStatus) !==
            JSON.stringify(props.NetworkViewModel.networks[in_dx].status)
          ) {
            this.networkStatus = copyObject(
              props.NetworkViewModel.networks[in_dx].status,
            );
            this.checkForNeworksStatus();
          }
        }
      }
    }
    if (
      this.props.availableNetwork !== props.availableNetwork &&
      this.props.isFromAllNetworks
    ) {
      this.setState({ availableNetworks: props.availableNetwork });
      this.setState({ isVirtualNode: props.isVirtualNode });
      this.setState({ isCidrExist: props.isCidrExist });
      this.updateiNodeDetails();

      this.checkForNeworksStatus();
    }
    if (this.props.isCidrExist != props.isCidrExist) {
      this.setState({ isCidrExist: props.isCidrExist });
    }
  }

  getTagKeyValues = (labels, status) => {
    let tags = [];
    if (labels && labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if ((key && key.charAt(0) != "_") || key === "_iotium.firewall.name")
        tags.push(key + ":" + labels[key]);
    }
    return tags;
  };

  componentDidMount() {
    if (
      this.props.currentNetwork &&
      this.props.currentNetwork.hasOwnProperty("connected_networks")
    ) {
      this.setState({ newNetwork: false });
    }
    this.updateiNodeDetails();
    if (
      this.props.isVirtualNode &&
      this.inodeModel.inode &&
      this.inodeModel.inode.node_state === "NEW" &&
      !this.props.cluster_id
    ) {
      this.setState({ isSelectDisabled: true });
    }

    this.setState({
      isUCGNode:
        this.inodeModel.inode &&
        this.inodeModel.inode.profile &&
        this.inodeModel.inode.profile.name &&
        this.inodeModel.inode.profile.name === "Unified Cloud Gateway"
          ? true
          : false,
    });
  }
  updateiNodeDetails = (isPeriodic = false) => {
    let params = {
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
      profile_name: !this.props.isVirtualNode
        ? ["Virtual"]
        : ["Virtual Edge", "Edge"],
      state: ["UNREACHABLE", "ALIVE", "NEW"],
    };
    (params.page = 0), (params.size = 1);
    this.props.loadingState(true);
    InodeController.getInodesForDropdown(params, false).then(response => {
      if (response) {
        this.updateOrgsiNodeData(response.total_count / 100, [], isPeriodic);
        this.setState({ mounting: true });
        if (this.props.isCollapsible) {
          this.markHeader();
        }
      }
    });
  };
  updateClusterDetails = () => {
    let params = {
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
    };
    (params.page = 0), (params.size = 1);
    let all_params = [];
    all_params.push(params);
    ClusterController.getAllClustersForDropdown(all_params, true)
      .then(response => {
        if (response && response[0]) {
          this.updateOrgsClusterData(response[0].total_count / 100, []);
          this.setState({ mounting: true });
          if (this.props.isCollapsible) {
            this.markHeader();
          }
        }
      })
      .catch(err => {
        this.checkForconnectedNeworks(true);
      });
  };
  /**
   *  UPDATE all inodes Data
   */
  updateOrgsiNodeData(totalCount, availableNodes, isPeriodic) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
          profile_name: !this.props.isVirtualNode
            ? ["Virtual"]
            : ["Virtual Edge", "Edge"],
          state: ["UNREACHABLE", "ALIVE", "NEW"],
        });
      }
      this.props.loadingState(true);
      InodeController.getAllInodesForDropdown(allParams, false, false)
        .then(resp => {
          if (resp) {
            availableNodes = availableNodes.concat(resp);
            if (this.props.setAvailableInodes) {
              this.props.setAvailableInodes(availableNodes);
            }
            this.setState({ remoteNetworks: availableNodes }, () => {
              if (this.props.isVirtualNode) {
                this.updateClusterDetails();
              }
              this.setState({ mounting: true });
              this.setState({ isloading: false });
              if (!isPeriodic) {
                this.checkForconnectedNeworks();
              }
            });
          } else {
            this.setState({ isloading: false });
            this.setState({ mounting: true });
            if (this.props.isVirtualNode) {
              this.updateClusterDetails();
            } else {
              this.checkForconnectedNeworks();
            }
          }
        })
        .catch(err => {
          this.checkForconnectedNeworks();
        });
    } else {
      this.checkForconnectedNeworks();
      this.props.loadingState(false);
    }
  }
  /**
   *  UPDATE all cluster Data
   */
  updateOrgsClusterData(totalCount, availableClusters) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
        });
      }
      ClusterController.getAllClustersForDropdown(allParams, false).then(
        resp => {
          if (resp) {
            let inodeAndClusterNetworks = this.state.remoteNetworks;
            availableClusters = this.checkForNetworkInfo(
              resp,
              this.state.availableNetworks,
            );
            availableClusters = availableClusters.concat(availableClusters);
            inodeAndClusterNetworks = inodeAndClusterNetworks.concat(
              availableClusters,
            );
            let filteredNetworks = NetworkMultiConnectFunc.removeDuplicates(
              inodeAndClusterNetworks,
              "id",
            );
            this.setState({ remoteNetworks: filteredNetworks }, () => {
              this.checkForconnectedNeworks(true);
              this.setState({ mounting: true });
              this.setState({ isloading: false });
            });
          } else {
            this.setState({ isloading: false });
            this.setState({ mounting: true });
            this.checkForconnectedNeworks(true);
          }
        },
      );
    } else {
      this.props.loadingState(false);
    }
  }
  checkForNetworkInfo = (cluster_list, availableNetworks) => {
    if (cluster_list.length > 0) {
      cluster_list.forEach(data => {
        let clusterInfo = data;
        let networks = [];
        availableNetworks.forEach(net => {
          if (net.cluster && net.cluster.id === data.id) {
            networks.push({ id: net.id, name: net.name });
          }
        });
        clusterInfo.networks = networks;
      });
    }
    return cluster_list;
  };
  /**
   * Check for connected networks options
   */
  checkForconnectedNeworks(isCluster = false) {
    if (isCluster) this.props.loadingState(true);
    let computedData = NetworkMultiConnectFunc.getConnectedNodesInfo(
      this.state.remoteNetworks,
      this.state.availableNetworks,
      {
        networks: NetworkMultiConnectFunc.getNetworkDetails(
          this.networkModel.networks.length > 0
            ? this.networkModel.networks
            : this.props.availableNetwork,
          this.props.netWorkId,
        ),
      },
      this.state.isUCGNode,
    );
    this.networkStatus = computedData.networkStatus;
    this.setState({
      currentAddednetworks: computedData.currentConnectednetwork,
      SelectedNetworks: computedData.SelectedNetworks,
      currentNetworkValue: {},
      showAddButton: false,
      NetworkInfo: [],
    });
    this.setState(
      {
        networkOptions: this.props.isVirtualNode
          ? NetworkMultiConnectFunc.computeCascaderOptionsWithClusters(
              this.state.remoteNetworks,
              [],
              this.state.availableNetworks,
              this.props.inodeVersion,
              this.props.cluster_id,
            )
          : NetworkMultiConnectFunc.computeCascaderOptions(
              this.state.remoteNetworks,
              [],
              this.state.availableNetworks,
              this.props.inodeVersion,
              this.props.cluster_id,
            ),
      },
      () => {
        this.props.loadingState(false);
      },
    );

    this.pushConnectedNetworksdata(computedData.currentConnectednetwork, true);
  }

  checkForNeworksStatus() {
    // preparing total networkslist for computing cascader menu
    let networkParams = {
        networks: NetworkMultiConnectFunc.getNetworkDetails(
          this.networkModel.networks.length > 0
            ? this.networkModel.networks
            : this.props.availableNetwork,
          this.props.netWorkId,
        ),
      },
      remoteNetworks = this.state.remoteNetworks,
      availableNetworks = this.state.availableNetworks;
    let computedData = NetworkMultiConnectFunc.getConnectedNodesInfo(
      remoteNetworks,
      availableNetworks,
      networkParams,
    );
    let currentAddednetworks = this.state.currentAddednetworks;
    let updatedNetworks = [];
    currentAddednetworks.forEach(Networks => {
      computedData.currentConnectednetwork.forEach(UpdatedNetwork => {
        //updating new status to the existing network
        if (Networks.network_id === UpdatedNetwork.network_id) {
          Networks.networkStatus = UpdatedNetwork.networkStatus;
        }
      });
      updatedNetworks.push(Networks);
    });
    this.setState({
      currentAddednetworks: updatedNetworks,
    });
  }
  // check iNode state
  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  setData = value => {
    this.props.setVnode(value);
    this.setState({ nodeId: value });
  };

  /*-----------------------------Representation Network CIDR validation ----------------------------------*/
  checkRepCidr = (rule, value, callback) => {
    //Rep cidr will allow 32 subnet
    let isCidrValid = value && isCidr(value, false);
    this.setState({ isvalidrepCidr: false });
    if (value) {
      if (!isCidrValid) {
        callback(
          getCurrentlocaleText("network.form.rep_network.errormessage2.text"),
        );
      } else {
        let isrepCidrAllowed = NetworkMultiConnectFunc.isrepCidrAllowed(
          value,
          this.state.currentRemoteCidr,
          this.props.tanCIDR,
          this.state.currentLocalRepCidr,
        );
        if (isrepCidrAllowed === 3) {
          callback(
            getCurrentlocaleText("network.form.rep_network.errormessage3.text"),
          );
        } else if (isrepCidrAllowed === 2) {
          callback(
            getCurrentlocaleText("network.form.rep_network.errormessage4.text"),
          );
        } else if (isrepCidrAllowed === 1) {
          callback(
            getCurrentlocaleText("network.form.rep_network.errormessage5.text"),
          );
        } else {
          let lr = this.props.form.getFieldValue(
              "local_representation_network",
            ),
            rr = this.props.form.getFieldValue("remote_representation_network"),
            currentNetworkId;
          if (rule && rule.field) {
            let indx = rule.field.indexOf("n-");
            if (indx !== -1) {
              currentNetworkId = rule.field.substring(indx);
            }
          }
          if (
            NetworkMultiConnectFunc.checkForvalidrepCIDR(
              value,
              this.state.currentAddednetworks,
              currentNetworkId,
              false,
            ) &&
            (lr &&
            lr.length > 0 &&
            rr &&
            rr.length > 0 &&
            getCIDRdata(lr) &&
            getCIDRdata(rr)
              ? getCIDRdata(lr).networkAddress !==
                getCIDRdata(rr).networkAddress
              : true)
          ) {
            this.setState({ isvalidrepCidr: true });
            callback();
          } else {
            this.setState({ isvalidrepCidr: true });
            callback(
              getCurrentlocaleText(
                "network.form.rep_network.errormessage6.text",
              ),
            );
          }
        }
      }
    } else {
      callback();
    }
  };

  checkLocalRepCidr = (rule, value, callback) => {
    //Local rep cidr will allow 32 subnet
    let isCidrValid = value && isCidr(value, false);
    this.setState({ isvalidrepCidr: false });
    if (value) {
      if (!isCidrValid) {
        callback(
          getCurrentlocaleText("network.form.rep_network.errormessage2.text"),
        );
      } else {
        let isrepCidrAllowed = NetworkMultiConnectFunc.isrepCidrAllowed(
          value,
          this.state.currentRemoteCidr,
          this.props.tanCIDR,
          this.state.currentLocalRepCidr,
        );
        if (isrepCidrAllowed === 3) {
          callback(
            getCurrentlocaleText("network.form.rep_network.errormessage3.text"),
          );
        } else if (isrepCidrAllowed === 2) {
          callback(
            getCurrentlocaleText("network.form.rep_network.errormessage4.text"),
          );
        } else if (isrepCidrAllowed === 1) {
          callback(
            getCurrentlocaleText("network.form.rep_network.errormessage5.text"),
          );
        } else {
          let lr = this.props.form.getFieldValue(
              "local_representation_network",
            ),
            rr = this.props.form.getFieldValue("remote_representation_network"),
            currentNetworkId;
          if (rule && rule.field) {
            let indx = rule.field.indexOf("n-");
            if (indx !== -1) {
              currentNetworkId = rule.field.substring(indx);
            }
          }
          if (
            NetworkMultiConnectFunc.checkForvalidrepCIDR(
              value,
              this.state.currentAddednetworks,
              currentNetworkId,
              true,
            ) &&
            (lr &&
            lr.length > 0 &&
            rr &&
            rr.length > 0 &&
            getCIDRdata(lr) &&
            getCIDRdata(rr)
              ? getCIDRdata(lr).networkAddress !==
                getCIDRdata(rr).networkAddress
              : true)
          ) {
            this.setState({ isvalidrepCidr: true });
            callback();
          } else {
            this.setState({ isvalidrepCidr: true });
            callback(
              getCurrentlocaleText(
                "network.form.rep_network.errormessage6.text",
              ),
            );
          }
        }
      }
    } else {
      callback();
    }
  };
  /**
   * Network edit  on multi connect
   */
  editSelection = (record, index, colIndex) => {
    this.setState({ currentLocalRepCidr: false });
    let editableRows = this.state.editable;
    editableRows.push(record.network_id + colIndex);
    this.setState({
      editable: editableRows,
      currentRemoteCidr: record.remote_cidr,
    });

    this.props.UiStore.showRepNetwork = false;
    let indexId =
      colIndex === "tan_rep_network"
        ? "local_representation_network_update" + record.network_id
        : "remote_representation_network_update" + record.network_id;
    setTimeout(() => {
      this.props.form.setFields({
        [indexId]: {
          value:
            colIndex === "tan_rep_network"
              ? record.local_representation_network
              : record.peer_representation_network,
        },
      });
    }, 100);
  };

  /**
   * Method to check whether the connected network selected as a default route.
   */
  isDefaultRouteRemovable = networkId => {
    // For Default route
    let routes = this.props.form.getFieldValue("routes");
    if (routes) {
      let route = routes.find(r => {
        return networkId && r.network_id === networkId;
      });
      if (route) {
        return false;
      }
    }
    return true;
  };

  /**
   * Network remove  for multi connect
   */
  removeSelection = (key, networkId) => {
    if (this.isDefaultRouteRemovable(networkId)) {
      this.disconnectNetwork(key, networkId);
    } else {
      let currentAddednetworks = this.state.currentAddednetworks,
        networkName = "",
        network = currentAddednetworks.find(nw => {
          return nw.network_id === networkId;
        });
      if (network) {
        networkName = network.network_name;
      }
      this.setState({
        rm_key: key,
        rm_networkId: networkId,
        rm_networkName: networkName,
        showRouteRemoveConfirmation: true,
      });
    }
  };

  /**
   * Method to disconnect connected network
   */
  disconnectNetwork = (key, networkId) => {
    let currentAddednetworks = this.state.currentAddednetworks,
      SelectedNetworksdata = this.state.SelectedNetworks,
      index = SelectedNetworksdata.indexOf(networkId);
    if (networkId != null) {
      if (index > -1) {
        SelectedNetworksdata.splice(index, 1);
      }
    }
    let data = currentAddednetworks.filter(networkarray => {
      if (key !== networkarray.key) return networkarray;
    });
    this.setState({
      currentAddednetworks: data,
      SelectedNetworks: SelectedNetworksdata,
      currentNetworkValue: {},
      showAddButton: false,
      NetworkInfo: [],
    });
    this.props.UiStore.showRepNetwork = false;
    // push data to parent component
    this.pushConnectedNetworksdata(data);
  };

  /**
   * Method to remove default route and connected network
   */
  removeRouteAndConnectedNetwork = () => {
    this.disconnectNetwork(this.state.rm_key, this.state.rm_networkId);
    this.handleRouteConfirmationCancel();
  };

  handleRouteConfirmationCancel = () => {
    this.setState({
      rm_key: null,
      rm_networkId: null,
      rm_networkName: "",
      showRouteRemoveConfirmation: false,
    });
  };

  /**
   * Network update  func...
   */
  handleUpdate = (record, index, status) => {
    this.props.form.validateFields((err, values) => {
      if (!err && !this.props.isVirtualNode) {
        let remoteNetworks = this.state.currentAddednetworks,
          repNetwork =
            status === "remote"
              ? this.props.form.getFieldValue(
                  "remote_representation_network_update" + record.network_id,
                )
              : this.props.form.getFieldValue(
                  "local_representation_network_update" + record.network_id,
                ),
          networkId = record.network_id,
          updatedNetworkList = [];
        if (status === "local") {
          let data = this.props.form.getFieldValue(
            "local_representation_network_update" + record.network_id,
          );
          if (data === "" || data === undefined) {
            return;
          }
        }
        remoteNetworks.filter(network => {
          if (network.network_id === networkId) {
            if (status === "remote")
              network.peer_representation_network = repNetwork;
            else if (status === "local")
              network.local_representation_network = repNetwork;
          }
          updatedNetworkList.push(network);
        });
        if (status === "remote") {
          this.props.form.resetFields([
            "remote_representation_network_update" + networkId,
          ]);
        } else {
          this.props.form.resetFields([
            "local_representation_network_update" + networkId,
          ]);
        }

        this.setState({
          currentAddednetworks: updatedNetworkList,
          currentNetworkValue: {},
          showAddButton: false,
          NetworkInfo: [],
        });
        this.pushConnectedNetworksdata(updatedNetworkList);
        this.cancelEdit(
          record,
          index,
          status === "remote" ? "remote_rep_network" : "tan_rep_network",
        );
      } else if (this.props.isVirtualNode) {
        this.props.form.validateFields(
          ["remote_representation_network_update" + record.network_id],
          (err, values) => {
            if (!err) {
              let remoteNetworks = this.state.currentAddednetworks,
                repNetwork =
                  status === "remote"
                    ? this.props.form.getFieldValue(
                        "remote_representation_network_update" +
                          record.network_id,
                      )
                    : this.props.form.getFieldValue(
                        "local_representation_network_update" +
                          record.network_id,
                      ),
                networkId = record.network_id,
                updatedNetworkList = [];
              remoteNetworks.filter(network => {
                if (network.network_id === networkId) {
                  if (status === "remote")
                    network.peer_representation_network = repNetwork;
                  else if (status === "local")
                    network.local_representation_network = repNetwork;
                }
                updatedNetworkList.push(network);
              });
              if (status === "remote") {
                this.props.form.resetFields([
                  "remote_representation_network_update" + networkId,
                ]);
              } else {
                this.props.form.resetFields([
                  "local_representation_network_update" + networkId,
                ]);
              }

              this.setState({
                currentAddednetworks: updatedNetworkList,
                currentNetworkValue: {},
                showAddButton: false,
                NetworkInfo: [],
              });
              this.pushConnectedNetworksdata(updatedNetworkList);
              this.cancelEdit(
                record,
                index,
                status === "remote" ? "remote_rep_network" : "tan_rep_network",
              );
            }
          },
        );
      }
      if (status === "local") {
        const showerrorMessage = this.props.UiStore.formErrorResponse.data
          ? this.props.UiStore.formErrorResponse.data.errorList
          : {};
        delete showerrorMessage[record.network_id];
        this.props.UiStore.formErrorResponse.data.errorList = showerrorMessage;
      } else {
      }
    });
  };

  /**
   * Cancel Update operation
   */
  cancelEdit = (record, index, status) => {
    if (status === "remote_rep_network") {
      this.props.form.resetFields([
        "remote_representation_network_update" + record.network_id,
      ]);
    } else {
      this.props.form.resetFields([
        "local_representation_network_update" + record.network_id,
      ]);
    }
    let editableRows = this.state.editable;
    const arrayIndex = editableRows.indexOf(record.network_id + status);
    if (arrayIndex > -1) {
      editableRows.splice(arrayIndex, 1);
    }
    this.setState({
      currentNetworkValue: {},
      showAddButton: false,
      editable: editableRows,
    });
  };
  /**
   * Remote Network on select func...
   */
  selectedOptions = (values, selectedOptions) => {
    if (selectedOptions.length > 0) {
      let selectednetwork = {};
      let data = selectedOptions.filter(Options => {
        if (Options.value === values[0])
          selectednetwork["org"] = {
            org_id: Options.value,
            org_name: Options.label,
          };
        else if (Options.value === values[1])
          selectednetwork["inode"] = {
            node_id: Options.value,
            node_name: Options.label,
            isCluster: Options.isCluster ? Options.isCluster : false,
          };
        else if (Options.value === values[2])
          selectednetwork["network"] = {
            network_id: Options.value,
            network_name: Options.label,
          };
      });
      let NetworkInfo = this.state.NetworkInfo;
      NetworkInfo.push(selectednetwork);
      let currentaddedNetworks = this.state.currentNetworkValue;
      this.setState({
        NetworkInfo: NetworkInfo,
        showAddButton: true,
      });

      let remoteNetwork = this.state.NetworkInfo[0].network,
        RemoteCidr = null;

      if (
        this.state.SelectedNetworks.indexOf(remoteNetwork.network_id) === -1
      ) {
        this.state.availableNetworks.filter(availabelNetwork => {
          if (availabelNetwork.id === remoteNetwork.network_id) {
            RemoteCidr = availabelNetwork.config.network.cidr;
          }
        });
        let networkData = {
          peer_representation_network: this.props.form.getFieldValue(
            "remote_representation_network",
          ),
          local_representation_network: this.props.form.getFieldValue(
            "local_representation_network",
          ),
          key: remoteNetwork.network_id,
          network_id: remoteNetwork.network_id,
          node_id: this.state.NetworkInfo[0].inode.node_id,
          isCluster:
            this.state.NetworkInfo[0].inode &&
            this.state.NetworkInfo[0].inode.isCluster
              ? this.state.NetworkInfo[0].inode.isCluster
              : false,
          network_name: remoteNetwork.network_name,
          org_name: this.state.NetworkInfo[0].org.org_name,
          org_id: this.state.NetworkInfo[0].org.org_id,
          node_name: this.state.NetworkInfo[0].inode.node_name,
          remote_cidr: RemoteCidr,
          is_connected: false,
          record_value: values,
          is_cluster: false,
          disable_representation_network_automation: false,
        };
        let currentAddednetwork = this.state.currentAddednetworks,
          currentRow = this.state.currentRowKey,
          i = -1;

        let CurrentIndex = -1;
        for (let i = 0; i < this.state.currentAddednetworks.length; i++) {
          if (this.state.currentAddednetworks[i].key === currentRow) {
            CurrentIndex = i;
            networkData.networkStatus = this.state.currentAddednetworks[
              i
            ].networkStatus;
            networkData.disable_representation_network_automation = this.state.currentAddednetworks[
              i
            ].disable_representation_network_automation;
          }
        }
        this.props.UiStore.showRepNetwork = false;
        currentAddednetwork[CurrentIndex] = networkData;
        currentaddedNetworks[remoteNetwork.network_id] = values;
        let SelectedNetworks = this.state.SelectedNetworks;
        SelectedNetworks.push(remoteNetwork.network_id);
        this.setState({
          currentAddednetworks: currentAddednetwork,
          showAddButton: false,
          NetworkInfo: [],
          SelectedNetworks: SelectedNetworks,
          currentNetworkValue: currentaddedNetworks,
        });
        this.pushConnectedNetworksdata(currentAddednetwork);
      } else {
        message.warning({
          content: getCurrentlocaleText(
            "network.form.remote_network.duplicate.warning.text",
          ),
          duration: 8,
          icon: <span></span>,
        });
        let currentaddedNetworks = this.state.currentAddednetworks,
          currentRow = this.state.currentRowKey,
          removedData = [],
          SelectedNetworksdata = this.state.SelectedNetworks,
          index = SelectedNetworksdata.indexOf(currentRow);
        if (currentRow != null) {
          if (index > -1) {
            SelectedNetworksdata.splice(index, 1);
          }
        }
        currentaddedNetworks.forEach(network => {
          if (network.key === currentRow) {
            network.record_value = [];
            removedData.push(network);
          } else {
            removedData.push(network);
          }
        });
        this.setState({
          currentAddednetworks: removedData,
          showAddButton: false,
          NetworkInfo: [],
          SelectedNetworks: SelectedNetworksdata,
        });
      }
    } else {
      let currentaddedNetworks = this.state.currentAddednetworks,
        currentRow = this.state.currentRowKey,
        removedData = [],
        SelectedNetworksdata = this.state.SelectedNetworks,
        index = SelectedNetworksdata.indexOf(currentRow);
      if (currentRow != null) {
        if (index > -1) {
          SelectedNetworksdata.splice(index, 1);
        }
      }
      currentaddedNetworks.forEach(network => {
        if (network.key === currentRow) {
          network.record_value = [];
          removedData.push(network);
        } else {
          removedData.push(network);
        }
      });
      this.setState({
        currentAddednetworks: removedData,
        showAddButton: false,
        NetworkInfo: [],
        SelectedNetworks: SelectedNetworksdata,
      });
    }
  };

  // Represent Remote Network Locally as
  handleRemoteRepCidrChange = (value, key) => {
    let field = "remote_representation_network" + key;
    value = makeEmpty(value);
    this.props.form.setFields({ [field]: { value: value } });
    this.props.form.validateFields(
      ["remote_representation_network" + key],
      (err, value) => {
        if (!err) {
          this.updateRepCIDR(key);
        }
      },
    );
  };

  //Update static routes rep cidr
  handleRepCidrChange = (value, key) => {
    let field = "remote_representation_network_update" + key;
    value = makeEmpty(value);
    this.props.form.setFields({ [field]: { value: value } });
    this.props.form.validateFields(
      ["remote_representation_network_update" + key],
      (err, value) => {
        if (!err) {
        }
      },
    );
  };

  handleLocalRepCidrChange = (value, key) => {
    let field = "local_representation_network_update" + key;
    value = makeEmpty(value);
    this.props.form.setFields({ [field]: { value: value } });
    this.props.form.validateFields(
      ["local_representation_network_update" + key],
      (err, value) => {
        if (!err) {
        }
      },
    );
  };

  /**
   * updateRepCIDR
   */
  updateRepCIDR = currentKey => {
    this.setState({ currentLocalRepCidr: false });
    let currentaddedNetworks = this.state
        ? this.state.currentAddednetworks
        : null,
      currentRow = currentKey,
      repNetwork = this.props.form.getFieldValue(
        "remote_representation_network" + currentKey,
      ),
      updatedData = [];
    currentaddedNetworks.forEach(network => {
      if (network.key === currentRow) {
        network.peer_representation_network = repNetwork;
        updatedData.push(network);
      } else {
        updatedData.push(network);
      }
    });
    this.setState({
      currentAddednetworks: updatedData,
      showAddButton: false,
      NetworkInfo: [],
    });
    this.pushConnectedNetworksdata(updatedData);
  };

  onExpand = (expanded, record) => {
    if (record.network_id != null) {
      let keys = this.state.expandedRow;
      let index = keys.findIndex(val => {
        return val === record.key;
      });
      if (expanded) {
        if (index === -1) {
          keys.push(record.key);
        }
        this.setExpandedRowKeys(keys);
      } else {
        if (index !== -1) {
          keys.splice(index, 1);
        }
        this.setExpandedRowKeys(keys);
      }
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRow: keys, defaultActiveKey: "1" });
  };
  updateLocalRepCIDR = currentKey => {
    let repNetwork = this.props.form.getFieldValue(
      "local_representation_network_update" + currentKey,
    );
    this.setState({ currentLocalRepCidr: true });
  };

  /**
   * Push connected networks to the parent component
   */
  pushConnectedNetworksdata = (data, isInitial = null) => {
    let connetedNetworks = formConnectNetworkData(data);
    this.setData(
      isInitial && connetedNetworks.length === 0 ? null : connetedNetworks,
    );
  };

  /**
   * Update Virtual data ID
   */
  updateRemoteCIDR = (remotenetworkId, repCidr, record) => {
    this.updateHelp({
      title: getCurrentlocaleText("network.routeinfo.help.title"),
      data: [
        {
          subtitle: "",
          content: (
            <RouteInfo
              tanCIDR={repCidr ? repCidr : this.props.tanCIDR}
              ViNodeId={remotenetworkId}
              tanCidr={this.props.tanCIDR}
              currentNode={this.props.currentNode}
              remoteInterfaceIP={record.remote_interface_ip}
              localInterfaceIP={record.local_interface_ip}
            />
          ),
        },
      ],
    });
  };

  /**
   *  Get more Info...
   */
  getMoreInfo = record => {
    const children = [];
    const { getFieldDecorator } = this.props.form;
    let firewallLabel_options = this.props.firewallLables;
    for (
      let i = 0;
      i < firewallLabel_options && firewallLabel_options.length;
      i++
    ) {
      children.push(
        <Option key={i} value={firewallLabel_options[i]}>
          {firewallLabel_options[i]}
        </Option>,
      );
    }
    return (
      <span
        onClick={() => {
          this.setState({ currentRowKey: record.key });
        }}
      >
        <Tabs
          tabPosition={"top"}
          defaultActiveKey={this.state.defaultActiveKey}
        >
          <TabPane
            tab={
              <span>
                {getCurrentlocaleText("networks.diode.tab.header.text")}
              </span>
            }
            key="1"
          >
            <Row type="flex" gutter={2}>
              <Col style={{ marginBottom: 75, marginTop: 20 }} span={24}>
                <FormItem
                  colon={false}
                  label={<span className={"iot-label-not-required"} />}
                  {...formItemLayout2}
                >
                  {getFieldDecorator("tunnel_diode" + record.key, {
                    initialValue: NetworkMultiConnectFunc.getDiodeValue(
                      record,
                      this.props.isVirtualNode,
                    ),
                  })(
                    <RadioGroup
                      onChange={this.handleDiodeChange}
                      disabled={
                        this.props.viewOnly
                          ? true
                          : !this.props.allowFirewall
                          ? true
                          : false
                      }
                      onFocus={() => {
                        this.updateHelp({
                          title: getCurrentlocaleText(
                            "network.security.diode.help.title",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "network.security.diode.help.message",
                              ),
                            },
                          ],
                        });
                        this.setState({ currentRowKey: record.key });
                      }}
                    >
                      <Radio
                        style={radioStyle}
                        value={null}
                        onFocus={() => {
                          this.updateHelp({
                            title: getCurrentlocaleText(
                              "network.security.diode.help.title",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "network.security.diode.help.message",
                                ),
                              },
                            ],
                          });
                          this.setState({ currentRowKey: record.key });
                        }}
                      >
                        {getCurrentlocaleText(
                          "networks.security.diode.direction3.label.text",
                        )}
                        <DiodeIcon
                          isVirtualNode={this.props.isVirtualNode}
                          direction={"comparearrows"}
                        />
                      </Radio>
                      <Radio
                        style={radioStyle}
                        value={2}
                        onFocus={() => {
                          this.updateHelp({
                            title: getCurrentlocaleText(
                              "network.security.diode.help.title",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "network.security.diode.help.message",
                                ),
                              },
                            ],
                          });
                          this.setState({ currentRowKey: record.key });
                        }}
                      >
                        {getCurrentlocaleText(
                          "networks.security.diode.direction2.label.text",
                        )}
                        <DiodeIcon
                          isVirtualNode={this.props.isVirtualNode}
                          direction={"keyboardbackspace"}
                        />
                      </Radio>
                      <Radio
                        style={radioStyle}
                        value={1}
                        onFocus={() => {
                          this.updateHelp({
                            title: getCurrentlocaleText(
                              "network.security.diode.help.title",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "network.security.diode.help.message",
                                ),
                              },
                            ],
                          });
                          this.setState({ currentRowKey: record.key });
                        }}
                      >
                        {getCurrentlocaleText(
                          "networks.security.diode.direction1.label.text",
                        )}
                        <DiodeIcon
                          isVirtualNode={this.props.isVirtualNode}
                          direction={"arrowforward"}
                        />
                      </Radio>
                    </RadioGroup>,
                  )}
                </FormItem>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                {getCurrentlocaleText("networks.otherinfo.label.text")}
              </span>
            }
            key="2"
          >
            <Row type="flex" gutter={2}>
              <Col span={4}>
                <span>
                  <strong>
                    {getCurrentlocaleText("network.form.organization.text")}
                  </strong>
                </span>
              </Col>
              <Col span={4}>
                <span>{record.org_name}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span>
                  <strong>
                    {getCurrentlocaleText("network.form.inode.text")}
                  </strong>
                </span>
              </Col>
              <Col span={4}>
                <span>{record.node_name}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span>
                  <strong>
                    {getCurrentlocaleText("network.form.remote.cidr.text")}
                  </strong>
                </span>
              </Col>
              <Col span={4}>
                <span>{record.remote_cidr}</span>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </span>
    );
  };
  /**
   *  Add New Network component
   */
  addNewnetwork = () => {
    let data = NetworkMultiConnectFunc.computeNewconnectData(),
      customData = this.state.currentAddednetworks;
    customData.push(data);
    this.setState({ currentAddednetwork: customData });
  };
  /**
   * getcurrentValue for cascader
   */
  getcurrentValue = value => {
    this.state.currentNetworkValue.filter(data => {
      return data[value];
    });
  };

  remoteNetworkDetails = data => {
    return (
      <div>
        <Row type="flex">
          <Col span={24}>
            <strong>
              {getCurrentlocaleText("network.form.remote.cidr.text")}
            </strong>
            {data.remote_cidr}
          </Col>
        </Row>
      </div>
    );
  };

  markHeader = () => {
    let hasConnectedNetwork =
      this.props.formData &&
      this.props.formData.connected_networks &&
      this.props.formData.connected_networks.length > 0
        ? true
        : false;
    markCollapseHeader("multiconnect-collapse", hasConnectedNetwork);
  };

  updateVirtualNodeInfo = nodeId => {
    let VirtualNodeDetails = this.state.VirtualNodeDetails;
    let currentVirtualIndex = VirtualNodeDetails[nodeId]
      ? VirtualNodeDetails[nodeId]
      : null;
    if (!currentVirtualIndex) {
      if (nodeId && nodeId) {
        InodeController.getInodeById(nodeId).then(response => {
          VirtualNodeDetails[nodeId] = response;
          this.setState({ VirtualNodeDetails: VirtualNodeDetails });
          return response;
        });
      }
    } else {
      return VirtualNodeDetails[nodeId];
    }
  };

  getRouteInfoPage = (record, connType) => {
    return (
      <RouteInfo
        tanCIDR={
          this.props.isVirtualNode
            ? record.peer_representation_network
              ? record.peer_representation_network
              : record.remote_cidr
            : record.local_representation_network
            ? record.local_representation_network
            : this.props.tanCIDR
        }
        ViNodeId={this.props.isVirtualNode ? this.props.nodeId : record.node_id}
        viNodeDetails={
          this.state.VirtualNodeDetails[
            this.props.isVirtualNode ? this.props.nodeId : record.node_id
          ]
        }
        key={record.node_id + randomAlphaNumericString(5)}
        connType={connType}
        isVirtual={this.props.isVirtualNode}
        isRepnet={record.peer_representation_network}
        localRepnet={record.local_representation_network}
        peerRepnet={record.peer_representation_network}
        remoteCidr={record.remote_cidr}
        tanCidr={this.props.tanCIDR}
        currentNode={this.props.currentNode}
        remoteInterfaceIP={record.remote_interface_ip}
        localInterfaceIP={record.local_interface_ip}
      />
    );
  };

  disableRepNetAutomationUpdate = (index, e) => {
    let currentAddednetworks = this.state.currentAddednetworks || [];
    let editable = this.state.editable || [];
    currentAddednetworks[index].disable_representation_network_automation =
      e.target.checked;
    let resetRemoteRepresentationNetwork =
      this.state.resetRemoteRepresentationNetwork || [];
    resetRemoteRepresentationNetwork.push(
      currentAddednetworks[index].network_id,
    );
    this.setState({ resetRemoteRepresentationNetwork }, () => {
      currentAddednetworks[index].peer_representation_network = undefined;
      this.props.form.resetFields([
        "remote_representation_network" +
          currentAddednetworks[index].network_id,
      ]);
      this.props.form.resetFields([
        "remote_representation_network_update" +
          currentAddednetworks[index].network_id,
      ]);
      let editableIndx = editable.indexOf(
        currentAddednetworks[index].network_id + "remote_rep_network",
      );
      if (editableIndx !== -1) {
        editable.splice(editableIndx, 1);
      }
      let resetRemoteNwIndx = resetRemoteRepresentationNetwork.indexOf(
        currentAddednetworks[index].network_id,
      );
      if (resetRemoteNwIndx != -1) {
        resetRemoteRepresentationNetwork.splice(resetRemoteNwIndx, 1);
      }
      this.setState({
        currentAddednetworks,
        editable,
        resetRemoteRepresentationNetwork,
      });
      this.pushConnectedNetworksdata(currentAddednetworks);
    });
  };

  computeLinkUrl = record => {
    let url;
    if (record.isCluster) {
      url = this.props.AuthStore.IsPermitted("NETWORK:CREATE")
        ? `/orgs/${record.org_id}/clusters/${record.node_id}/modify/${record.network_id}`
        : `/orgs/${record.org_id}/clusters/${record.node_id}/view/${record.network_id}`;
    } else {
      url = this.props.AuthStore.IsPermitted("NETWORK:CREATE")
        ? `/orgs/${record.org_id}/inodes/${record.node_id}/modify/${record.network_id}`
        : `/orgs/${record.org_id}/inodes/${record.node_id}/view/${record.network_id}`;
    }
    return url;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const showerrorMessage = this.props.UiStore.formErrorResponse.data
      ? this.props.UiStore.formErrorResponse.data.errorList
      : {};
    const { editable, value } = this.state;
    const showWarning = this.props.UiStore.showRepNetwork;
    const displayRender = (labels, selectedOptions) => {
      if (selectedOptions.length > 0) {
        return (
          <Tag key={selectedOptions}>
            {labels.map((label, i) => {
              const option = selectedOptions[i];
              if (i === labels.length - 1) {
                return (
                  <span key={option.value}>
                    &nbsp;{option.tag}&nbsp;{label}&nbsp;
                  </span>
                );
              }
              return (
                <span key={option.value}>
                  &nbsp;{option.tag}&nbsp;{label}&nbsp;/
                </span>
              );
            })}
          </Tag>
        );
      }
    };
    this.columns = [
      {
        title: getCurrentlocaleText("network.remote_network.text"),
        dataIndex: "network_name",
        key: "network_name",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          let tabInfo = [];
          let virtualTab = (
            <Tabs.TabPane
              tab={
                <div style={{ fontSize: 12 }}>
                  <Icons
                    type="md"
                    name="MdCloudQueue"
                    className={"baseline iot-diode-cloud-icon-style"}
                    style={{
                      marginRight: 2,
                      marginLeft: 0,
                    }}
                  />
                  <Icons
                    type="ai"
                    name="AiOutlineArrowRight"
                    style={{ fontSize: 13, marginRight: 2 }}
                  />
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    className={"baseline iot-diode-cloud-icon-style"}
                    style={{
                      marginRight: 2,
                      marginLeft: 0,
                    }}
                  />
                  {getCurrentlocaleText("network.routeinfo.help.title")}
                </div>
              }
              key={1}
            >
              {this.getRouteInfoPage(record, "remoteToLocal")}
            </Tabs.TabPane>
          );
          let edgeTab = (
            <Tabs.TabPane
              tab={
                <div style={{ fontSize: 12 }}>
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    className={"baseline iot-diode-cloud-icon-style"}
                    style={{
                      marginRight: 2,
                      marginLeft: 0,
                    }}
                  />
                  <Icons
                    type="ai"
                    name="AiOutlineArrowRight"
                    style={{ fontSize: 13, marginRight: 2 }}
                  />
                  <Icons
                    type="md"
                    name="MdCloudQueue"
                    className={"baseline iot-diode-cloud-icon-style"}
                    style={{
                      marginRight: 2,
                      marginLeft: 0,
                    }}
                  />
                  {getCurrentlocaleText("network.routeinfo_reverse.help.title")}
                </div>
              }
              key={2}
            >
              {this.getRouteInfoPage(record, "localToRemote")}
            </Tabs.TabPane>
          );

          if (this.props.isVirtualNode) {
            tabInfo.push(virtualTab);
            tabInfo.push(edgeTab);
          } else {
            tabInfo.push(edgeTab);
            tabInfo.push(virtualTab);
          }

          return (
            <Col
              className={
                showWarning &&
                showerrorMessage &&
                showerrorMessage.hasOwnProperty(record.network_id)
                  ? "error-on-remote-network"
                  : null
              }
            >
              <span>
                {record.is_connected ? (
                  <div>
                    {record && (
                      <Link target="_blank" to={this.computeLinkUrl(record)}>
                        <RemoteNetworkTag data={record} maxWidth={"80%"} />
                      </Link>
                    )}
                    {this.props.isForForm ? (
                      <span
                        key={record.node_id + "span"}
                        onMouseOver={() => {
                          this.updateRemoteCIDR(
                            this.props.isVirtualNode
                              ? this.props.nodeId
                              : record.node_id,
                            this.props.isVirtualNode
                              ? record.peer_representation_network
                                ? record.peer_representation_network
                                : record.remote_cidr
                              : record.local_representation_network
                              ? record.local_representation_network
                              : this.props.tanCIDR,
                            record,
                          );
                        }}
                      >
                        <Icons
                          type="fa"
                          name="FaInfoCircle"
                          className={"baseline"}
                          style={{ fontSize: 16, marginLeft: 5 }}
                        />
                      </span>
                    ) : (
                      <span
                        onMouseOver={() => {
                          if (this.state.showInfo == false) {
                            this.updateVirtualNodeInfo(
                              this.props.isVirtualNode
                                ? this.props.nodeId
                                : record.node_id,
                            );
                            this.setState({ showInfo: true });
                          }
                        }}
                      >
                        <Popover
                          key={record.network_id}
                          content={
                            <Tabs style={{ width: 700 }}>{tabInfo}</Tabs>
                          }
                        >
                          <Button
                            style={{ fontSize: 16, marginLeft: 15 }}
                            className="popover-help-button p-0"
                            shape="circle"
                          >
                            <Icons type="fa" name="FaInfoCircle" />
                          </Button>
                        </Popover>
                      </span>
                    )}
                  </div>
                ) : (
                  <div style={{ marginTop: 30 }}>
                    <FormItem>
                      <Cascader
                        allowClear={false}
                        size="large"
                        placeholder={getCurrentlocaleText(
                          "network.form.remote_select.title.text",
                        )}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                        key={record.key}
                        showSearch
                        value={record.record_value}
                        expandTrigger="hover"
                        onChange={this.selectedOptions}
                        options={this.state.networkOptions}
                        className="remote-repnetwork-field"
                        title={
                          record.record_value.length <= 0
                            ? this.state.isSelectDisabled
                              ? getCurrentlocaleText(
                                  "network.form.newnode_warning.title.text",
                                )
                              : this.props.tanCIDR === null
                              ? getCurrentlocaleText(
                                  "network.form.fillnetwork.title.text",
                                )
                              : getCurrentlocaleText(
                                  "network.form.remote_select.title.text",
                                )
                            : record.org_name +
                              " / " +
                              record.node_name +
                              " / " +
                              record.network_name
                        }
                        disabled={
                          this.state.isSelectDisabled ||
                          this.props.tanCIDR === null
                        }
                        onClick={() => {
                          this.setState({ currentRowKey: record.key });
                        }}
                        displayRender={displayRender}
                        onFocus={() => {
                          this.updateHelp({
                            title: getCurrentlocaleText(
                              "network.form.remotenetwork.selectbox.helptext.title",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "network.form.remotenetwork.selectbox.helptext.text",
                                ),
                              },
                            ],
                          });
                          this.setState({ currentRowKey: record.key });
                        }}
                      />
                    </FormItem>
                  </div>
                )}
              </span>
            </Col>
          );
        },
      },
      {
        title: getCurrentlocaleText("network.form.remote.network.header.text"),
        dataIndex: "peer_representation_network",
        key: "rep_network2",
        width: "30%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div>
              {record.is_connected ? (
                editable.indexOf(record.network_id + "remote_rep_network") >
                -1 ? (
                  <div className="editable-cell-input-wrapper">
                    <Col className="d-flex all-center">
                      <FormItem hasFeedback className="editable-table-input">
                        {getFieldDecorator(
                          "remote_representation_network_update" +
                            record.network_id,
                          {
                            rules: [
                              {
                                required: this.props.UiStore.showRepNetwork,
                                message: getCurrentlocaleText(
                                  "network.form.rep_network.errormessage1.text",
                                ),
                              },
                              { validator: this.checkRepCidr },
                            ],
                          },
                        )(
                          <CidrProvider
                            maskLength={"34%"}
                            ipLength={"57%"}
                            tancidr={
                              this.props.form.getFieldValue(
                                "remote_representation_network_update" +
                                  record.network_id,
                              ) !== undefined
                                ? this.props.form.getFieldValue(
                                    "remote_representation_network_update" +
                                      record.network_id,
                                  )
                                : record.peer_representation_network
                            }
                            onFocus={() => {
                              this.updateHelp({
                                title: getCurrentlocaleText(
                                  "network.form.remote_rep_network.text",
                                ),
                                data: [
                                  {
                                    subtitle: "",
                                    content: this.props.UiStore.showRepNetwork
                                      ? getCurrentlocaleText(
                                          "network.form.rep_network.help2.content",
                                        )
                                      : getCurrentlocaleText(
                                          "network.form.rep_network.help1.content",
                                        ),
                                  },
                                ],
                              });
                            }}
                            handleChange={val => {
                              this.handleRepCidrChange(val, record.key);
                              this.props.UiStore.showRepNetwork = false;
                            }}
                          />,
                        )}
                      </FormItem>
                      <div className="d-flex">
                        <Button
                          onClick={() => {
                            this.handleUpdate(record, index, "remote");
                          }}
                          shape="circle"
                          icon={<Icons type="ai" name="AiOutlineCheck" />}
                          size={"small"}
                          title={getCurrentlocaleText("update.text")}
                          className="all-center"
                          style={{
                            marginRight: 4,
                          }}
                        />
                        <Button
                          onClick={() => {
                            this.cancelEdit(
                              record,
                              index,
                              "remote_rep_network",
                            );
                          }}
                          shape="circle"
                          icon={<Icons type="ai" name="AiOutlineClose" />}
                          title={getCurrentlocaleText(
                            "general.form.button.cancel.text",
                          )}
                          size={"small"}
                          className="all-center"
                        />
                      </div>
                    </Col>
                  </div>
                ) : (
                  <div className="editable-cell-text-wrapper">
                    {record.peer_representation_network}
                    {!this.props.viewOnly && (
                      <span
                        title="Edit"
                        className={
                          !record.disable_representation_network_automation &&
                          this.props.isVirtualNode
                            ? "remote-network-action-btn-disabled"
                            : "remote-network-action-btn"
                        }
                        style={{ float: "right" }}
                        onClick={() => {
                          if (
                            record.disable_representation_network_automation ||
                            !this.props.isVirtualNode
                          ) {
                            this.editSelection(
                              record,
                              index,
                              "remote_rep_network",
                            );
                          }
                        }}
                      >
                        <Icons type="ai" name="AiOutlineEdit" />
                      </span>
                    )}
                  </div>
                )
              ) : (
                <div
                  style={{ marginTop: 30 }}
                  className="editable-cell-input-wrapper"
                >
                  <Col>
                    <FormItem hasFeedback>
                      {getFieldDecorator(
                        "remote_representation_network" + record.key,
                        {
                          rules: [
                            {
                              required:
                                showWarning &&
                                showerrorMessage &&
                                showerrorMessage.hasOwnProperty(
                                  record.network_id,
                                ) &&
                                showerrorMessage &&
                                !showerrorMessage.hasOwnProperty("self"),
                              message: getCurrentlocaleText(
                                "network.form.rep_network.errormessage1.text",
                              ),
                            },
                            { validator: this.checkRepCidr },
                          ],
                        },
                      )(
                        <CidrProvider
                          maskLength={"34%"}
                          ipLength={"57%"}
                          reset={
                            this.state.resetRemoteRepresentationNetwork.indexOf(
                              record.key,
                            ) !== -1
                              ? true
                              : false
                          }
                          onFocus={() => {
                            this.updateHelp({
                              title: getCurrentlocaleText(
                                "network.form.remote_rep_network.text",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: this.props.UiStore.showRepNetwork
                                    ? getCurrentlocaleText(
                                        "network.form.rep_network.help2.content",
                                      )
                                    : getCurrentlocaleText(
                                        "network.form.rep_network.help1.content",
                                      ),
                                },
                              ],
                            });
                            this.setState({
                              currentRemoteCidr: record.remote_cidr,
                            });
                          }}
                          handleChange={val => {
                            this.handleRemoteRepCidrChange(val, record.key);
                            this.props.UiStore.showRepNetwork = false;
                          }}
                          title={
                            this.state.isSelectDisabled
                              ? getCurrentlocaleText(
                                  "network.form.newnode_warning.title.text",
                                )
                              : this.props.tanCIDR === null
                              ? getCurrentlocaleText(
                                  "network.form.fillnetwork.title.text",
                                )
                              : getCurrentlocaleText(
                                  "network.form.rep_network.text",
                                )
                          }
                          isDisabled={
                            (this.inodeModel.inode.cloud_route &&
                              !record.disable_representation_network_automation) ||
                            this.state.isSelectDisabled ||
                            this.props.tanCIDR === null ||
                            record.record_value.length <= 0
                          }
                        />,
                      )}
                    </FormItem>
                  </Col>
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText(
          "disable.representation.network.automation.text",
        ),
        dataIndex: "disable_representation_network_automation",
        key: "disable_representation_network_automation",
        width: "10%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Checkbox
                onChange={e => {
                  this.disableRepNetAutomationUpdate(index, e);
                }}
                disabled={this.props.viewOnly ? true : false}
                checked={record.disable_representation_network_automation}
              />
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("network.connection_status.text"),
        dataIndex: "networkState",
        key: "network_state",
        width: "15%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div>
              <NetworkStatus status={record.networkStatus || {}} />
            </div>
          );
        },
      },
      {
        title: (
          <span style={{ wordWrap: "break-word" }}>
            {getCurrentlocaleText("networks.diode.tab.header.text")}
          </span>
        ),
        dataIndex: "security_policy",
        key: "security_policy",
        width: "15%",
        ellipsis: true,
        onCell: (record, index) => {
          return {
            onClick: () => {
              if (record.network_id != null) {
                let data = this.state.expandedRow;
                if (!data.includes(record.key))
                  this.setState({
                    expandedRow: [record.key],
                    defaultActiveKey: "1",
                  });
                else this.setState({ expandedRow: [], defaultActiveKey: "2" });
              }
            },
          };
        },
        render: (text, record, index) => {
          return (
            <div
              onClick={() => {
                this.setState({ currentRowKey: record.key });
              }}
            >
              <span
                title={
                  NetworkMultiConnectFunc.getDiodeValue(
                    record,
                    this.props.isVirtualNode,
                  ) != null
                    ? NetworkMultiConnectFunc.getDiodeValue(
                        record,
                        this.props.isVirtualNode,
                      ) === 1
                      ? getCurrentlocaleText(
                          "networks.security.diode.direction1.label.text",
                        )
                      : getCurrentlocaleText(
                          "networks.security.diode.direction2.label.text",
                        )
                    : getCurrentlocaleText(
                        "networks.security.diode.direction3.label.text",
                      )
                }
              >
                <DiodeIcon
                  isVirtualNode={this.props.isVirtualNode}
                  direction={
                    NetworkMultiConnectFunc.getDiodeValue(
                      record,
                      this.props.isVirtualNode,
                    ) != null
                      ? NetworkMultiConnectFunc.getDiodeValue(
                          record,
                          this.props.isVirtualNode,
                        ) === 1
                        ? "arrowforward"
                        : "keyboardbackspace"
                      : "comparearrows"
                  }
                />
              </span>
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "remove-network",
        width: "5%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div>
              <Col>
                {
                  <span
                    title={getCurrentlocaleText(
                      "network.form.remove.title.text",
                    )}
                    className="remote-network-action-btn"
                  >
                    <span
                      onClick={() => {
                        this.removeSelection(record.key, record.network_id);
                      }}
                    >
                      <Icons type="ai" name="AiOutlineMinusCircle" />
                    </span>
                    {showWarning &&
                      showerrorMessage &&
                      showerrorMessage.hasOwnProperty(record.network_id) && (
                        <Popover
                          overlayStyle={{ maxWidth: "30%", float: "right" }}
                          placement="left"
                          content={
                            <div>
                              <span style={{ color: "#f04134" }}>
                                {record.remote_cidr &&
                                showerrorMessage &&
                                showerrorMessage.hasOwnProperty("self") &&
                                record.local_representation_network ===
                                  undefined
                                  ? NetworkMultiConnectFunc.getErrorMessages(
                                      "SelfCIDRConflict",
                                    )
                                  : record.remote_cidr &&
                                    showerrorMessage &&
                                    showerrorMessage.hasOwnProperty("self")
                                  ? NetworkMultiConnectFunc.getErrorMessages(
                                      "RemoteNetworkCidrConflicts",
                                    )
                                  : this.props.UiStore.isCidrChanged &&
                                    showerrorMessage &&
                                    showerrorMessage[record.network_id] &&
                                    showerrorMessage[record.network_id]
                                      .errorCode === "CidrLengthMismatch"
                                  ? NetworkMultiConnectFunc.getErrorMessages(
                                      "SelfCIDRmissMatch",
                                    )
                                  : showerrorMessage &&
                                    showerrorMessage[record.network_id]
                                  ? NetworkMultiConnectFunc.getErrorMessages(
                                      showerrorMessage[record.network_id]
                                        .errorCode,
                                    )
                                  : NetworkMultiConnectFunc.getErrorMessages(
                                      "default",
                                    )}
                              </span>
                              {((this.props.UiStore.isCidrChanged &&
                                showerrorMessage &&
                                showerrorMessage[record.network_id] &&
                                showerrorMessage[record.network_id]
                                  .errorCode === "CidrLengthMismatch") ||
                                (record.remote_cidr &&
                                  showerrorMessage &&
                                  showerrorMessage.hasOwnProperty("self") &&
                                  record.local_representation_network ===
                                    undefined)) && (
                                <span>
                                  <p>
                                    {getCurrentlocaleText(
                                      "network.form.remote.network.header2.text",
                                    )}
                                  </p>
                                  <Col>
                                    <FormItem hasFeedback>
                                      {getFieldDecorator(
                                        "local_representation_network_update" +
                                          record.key,
                                        {
                                          rules: [
                                            {
                                              message: getCurrentlocaleText(
                                                "network.form.rep_network.errormessage1.text",
                                              ),
                                            },
                                            {
                                              validator: this.checkLocalRepCidr,
                                            },
                                          ],
                                        },
                                      )(
                                        <CidrProvider
                                          maskLength={"34%"}
                                          ipLength={"57%"}
                                          onFocus={() => {
                                            this.updateHelp({
                                              title: getCurrentlocaleText(
                                                "network.form.remote_rep_network.text",
                                              ),
                                              data: [
                                                {
                                                  subtitle: "",
                                                  content: this.props.UiStore
                                                    .showRepNetwork
                                                    ? getCurrentlocaleText(
                                                        "network.form.rep_network.help2.content",
                                                      )
                                                    : getCurrentlocaleText(
                                                        "network.form.rep_network.help1.content",
                                                      ),
                                                },
                                              ],
                                            });
                                            this.setState({
                                              currentRemoteCidr:
                                                record.remote_cidr,
                                              currentLocalRepCidr: true,
                                            });
                                          }}
                                          title={
                                            this.state.isSelectDisabled
                                              ? getCurrentlocaleText(
                                                  "network.form.newnode_warning.title.text",
                                                )
                                              : this.props.tanCIDR === null
                                              ? getCurrentlocaleText(
                                                  "network.form.fillnetwork.title.text",
                                                )
                                              : getCurrentlocaleText(
                                                  "network.form.rep_network.text",
                                                )
                                          }
                                          tancidr={
                                            this.props.form.getFieldValue(
                                              "local_representation_network_update" +
                                                record.key,
                                            ) !== undefined
                                              ? this.props.form.getFieldValue(
                                                  "local_representation_network_update" +
                                                    record.key,
                                                )
                                              : this.state.currentRemoteCidr
                                              ? this.state.currentRemoteCidr
                                              : null
                                          }
                                          handleChange={val => {
                                            this.handleLocalRepCidrChange(
                                              val,
                                              record.key,
                                            );
                                          }}
                                        />,
                                      )}
                                    </FormItem>
                                    <FormItem hasFeedback>
                                      <Button
                                        onClick={() => {
                                          this.handleUpdate(
                                            record,
                                            index,
                                            "local",
                                          );
                                        }}
                                        size="small"
                                        style={{ float: "right" }}
                                      >
                                        {getCurrentlocaleText(
                                          "network.form.error_resolve.button.text",
                                        )}
                                      </Button>
                                    </FormItem>
                                  </Col>
                                </span>
                              )}
                            </div>
                          }
                        >
                          <Button
                            style={{ border: "none" }}
                            shape="circle"
                            size={"small"}
                          >
                            <Icons
                              type="fa"
                              name="FaTimesCircle"
                              className="remote-network-action-btn error-on-remote-network-icon"
                            />
                          </Button>
                        </Popover>
                      )}
                  </span>
                }
              </Col>
            </div>
          );
        },
      },
    ];
    // virtual node
    if (
      !this.props.isVirtualNode ||
      !this.inodeModel.inode.cloud_route ||
      !this.inodeModel.inode.cloud_route.cidr
    ) {
      this.columns.splice(2, 1);
    }
    // Only view only
    if (this.props.viewOnly) {
      this.columns.splice(5);
      if (
        !this.props.isVirtualNode ||
        !this.inodeModel.inode.cloud_route ||
        !this.inodeModel.inode.cloud_route.cidr
      ) {
        this.columns.splice(3);
      } else {
        this.columns.splice(4);
      }
    }
    // View only and read only
    if (this.props.viewOnly) {
      this.columns.splice(5);
    }

    let content = (
      <Row gutter={2}>
        <Col>
          {!this.props.viewOnly && (
            <div style={{ textAlign: "right" }}>
              <Button
                icon={<Icons type="ai" name="AiOutlinePlus" />}
                style={{ margin: "0 20px 10px 0" }}
                type="primary"
                size="small"
                onClick={this.addNewnetwork}
                title={getCurrentlocaleText(
                  "general.button.add.label.title.text",
                )}
                disabled={!this.state.isCidrExist ? true : false}
              >
                {getCurrentlocaleText("general.button.add.label.text")}
              </Button>
            </div>
          )}
          <Table
            size={"small"}
            locale={{
              emptyText: getCurrentlocaleText(
                "network.form.network.notfound.text",
              ),
            }}
            bordered={false}
            columns={this.columns}
            dataSource={NetworkMultiConnectFunc.removeDuplicates(
              this.state.currentAddednetworks,
            )}
            loading={this.state.isloading}
            scroll={{ x: "20%" }}
            pagination={false}
            onExpand={this.onExpand}
            expandedRowKeys={this.state.expandedRow}
            indentSize={5}
            expandedRowRender={
              !this.props.viewOnly || !this.state.isUCGNode
                ? record => {
                    if (record.network_id != null)
                      return this.getMoreInfo(record);
                    else return false;
                  }
                : null
            }
          />
          <RemoteNetworkRemoveConfirmDialog
            onOk={this.removeRouteAndConnectedNetwork}
            isVisible={this.state.showRouteRemoveConfirmation}
            onCancel={this.handleRouteConfirmationCancel}
            networkName={this.state.rm_networkName}
          />
        </Col>
      </Row>
    );

    return (
      <div>
        {this.props.isCollapsible ? (
          <Collapse className="multiconnect-collapse">
            <Panel
              key={"1"}
              header={
                <div className="panel-header-text">
                  {getCurrentlocaleText("remote_network_config.text")}
                </div>
              }
              disabled={!this.state.isCidrExist ? true : false}
            >
              {content}
            </Panel>
          </Collapse>
        ) : (
          content
        )}
      </div>
    );
  }
}

const NetworkMulticonnect = Form.create()(Connect);
export default NetworkMulticonnect;
