import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

import ActionBtn from "Core/API/ActionBtn";
import RolesForms from "components/Forms/RolesForm";
import { Row, Col, Tag, Icon, Button, Checkbox, Menu } from "antd";
import {
  highlightSearchText,
  copyObject,
  getCurrentlocaleText,
  isEqual,
} from "Core/Utils";
import SearchInput from "components/UI-Components/SearchInput";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import RoleController from "controller/RoleController";
import propTypes from "prop-types";
import Icons from "../UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject("ModelStore", "AuthStore", "UiStore", "RoleViewModel", "OrgViewModel")
@observer
class RolesList extends Component {
  constructor(props) {
    super(props);
    this.AuthStore = this.props.AuthStore;
    this.roleModel = this.props.RoleViewModel;
    this.searchTimeout = null;
    this.oldRolesData = [];
    this.state = {
      selectedRole: {},
      selectedRowKeys: [], // for table to identify selected rows
      deletableRoles: {
        roles: [],
      },
      autoOpen: false,
      autoOpenOrgID: null,
      modelAction: "create",
      currUserRecord: {},
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      currentPage: 1,
      expandedRowKeys: [],
      isTableModalToggle: false,
      modalContent: "",
      modalTitle: "",
    };
  }

  roleList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    params = this.props.params || {},
  ) => {
    params = copyObject(params);
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (this.props.UiStore.showChildRoles) {
      delete params.own;
    } else {
      params.own = true;
    }
    return new Promise((resolve, reject) => {
      RoleController.getRoles(params, loading)
        .then(result => {
          const currFirewall = result.filter(firewall => {
            return (
              firewall &&
              firewall.id &&
              firewall.id === this.state.autoOpenOrgID
            );
          });

          this.setState({
            currUserRecord: currFirewall[0],
          });
          setTimeout(() => {
            if (
              this.state.modelAction == "edit" ||
              this.state.modelAction == "view"
            ) {
              this.setState({
                autoOpen: true,
              });
            }
          }, 5);
          resolve(result.data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  };

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.roleList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.resetState();
    this.props.onAfterClick("roles");
  };

  deleteAfterCall = () => {
    this.resetState();
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.roleModel.rolesData.length == 0) {
      page--;
    }
    this.roleList(
      true,
      page - 1,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };

  // convert Default values into API request header format
  prepareData = values => {
    this.oldRolesData = copyObject(this.roleModel.rolesData);
    let permissions = values.permissions.map((val, i) => {
      return val.key;
    });
    let FormData = {
      name: values.name,
      description: values.description,
      permissions: permissions,
    };
    return FormData;
  };

  setSelectedStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      if (selectedRows[i]) {
        let id = selectedRows[i].id;

        this.setState(() => {
          let newObj = {
              deletableRoles: {
                roles: [],
              },
            },
            selectedRole = this.state.selectedRole,
            item = selectedRows[i],
            tempArray = [];

          selectedRole[id] = {
            value: true,
            name: item.name,
            id: item.id,
          };

          //Temp array for deletable roles list
          for (let i in selectedRows) {
            if (
              selectedRows[i] &&
              selectedRows[i].id &&
              selectedRole[selectedRows[i].id]
            ) {
              tempArray.push(selectedRole[selectedRows[i].id]);
            }
          }

          // To set the value : false , when roles is deselected and remove it from delete list

          !selected && selectedRole[record.id]
            ? ((selectedRole[record.id].value = false),
              (tempArray = _.filter(tempArray, val => {
                return val.id !== record.id;
              })))
            : null;

          newObj["selectedRole"] = selectedRole;
          newObj.deletableRoles["roles"] = tempArray;
          return newObj;
        });
      }
    }
  };

  roleListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.roleList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ currentPage: 1, searchText: "" });
    this.roleList(
      true,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
    this.resetState();
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.roleList(true, page, this.state.searchText, sort.by, sort.order);
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId !== props.orgId) {
      this.roleList(
        true,
        0,
        this.state.searchText,
        this.state.sorterInfo.sortBy,
        this.state.sorterInfo.sortOrder,
        props.params,
      );
    }
  }

  componentDidMount() {
    if (this.props.isFromDashboard) {
      this.props.UiStore.showChildRoles = true;
    }

    let pathname = location.pathname;
    let path = pathname.split("/");
    if (path[5] && (path[5] == "view" || "edit")) {
      this.props.UiStore.showChildSecurityGroups = true;
      this.roleList(true);
    } else {
      this.roleList(true);
    }
    if (pathname) {
      if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "roles" &&
        path[4] === "create"
      ) {
        this.setState({
          autoOpen: true,
          modelAction: "create",
        });
      } else if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "roles" &&
        path[4] &&
        path[5] === "edit"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "edit",
        });
      } else if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "roles" &&
        path[4] &&
        path[5] === "view"
      ) {
        this.setState({
          autoOpen: true,
          autoOpenOrgID: path[4],
          modelAction: "view",
        });
      }
    }
  }

  resetState = () => {
    this.setState({
      selectedRole: {},
      selectedRowKeys: [],
      deletableRoles: {
        roles: [],
      },
      modelAction: "create",
      currUserRecord: {},
    });
  };

  changeListview = e => {
    this.props.UiStore.showChildRoles = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.roleList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
    );
  };

  lazyLoad = () => {
    if (this.roleModel.pageable.next) {
      let page = this.roleModel.pageable.page + 1;
      this.roleList(
        true,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  expandRow = () => {
    let keys = this.roleModel.rolesData.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  getPermissionsTag(permissions = []) {
    if (permissions.length > 0) {
      return permissions.map(list => {
        return (
          <Tag style={{ marginTop: 8 }} key={list.permission}>
            <span title={list.description}>{list.permission}</span>
          </Tag>
        );
      });
    } else {
      return "No Permissions available";
    }
  }

  getModalContentJSX = record => {
    this.setState({
      modalTitle:
        record && record.value && record.value.name ? record.value.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  getMoreInfo(record) {
    return (
      <div>
        <Row>
          <Col span={4} style={{ marginTop: 9 }}>
            <strong>
              {getCurrentlocaleText(
                "users.roles.extended_column.permission.label.text",
              )}
            </strong>
            <strong className="px-5">{":"}</strong>
          </Col>
          <Col span={20}>{this.getPermissionsTag(record.permissions)}</Col>
        </Row>
        <Row style={{ marginTop: 5, marginBottom: 5 }} />
      </div>
    );
  }

  logout = resp => {
    this.props.onAfterClick("roles");
    if (resp && this.props.UiStore.currentUser) {
      // Check the current user role with updated role
      let currentUserRoles = this.props.UiStore.currentUser.roles;
      if (currentUserRoles && currentUserRoles.length > 0) {
        let isRoleMatched = false;
        currentUserRoles.forEach(role => {
          if (role.id === resp.id && !isRoleMatched) {
            isRoleMatched = true;
          }
        });
        // if current user role matches the updated role
        if (isRoleMatched) {
          // Check permissions changes
          // Current permissions and old permissions
          let permissions = [],
            oldPermissions = [];
          if (resp.permissions && resp.permissions.length > 0) {
            permissions = resp.permissions.map(permission => {
              return permission.id;
            });
          }
          if (this.oldRolesData) {
            let role = this.oldRolesData.find(r => {
              return r.id === resp.id;
            });
            if (role && role.value && role.value.permissions) {
              oldPermissions = role.value.permissions.map(permission => {
                return permission.id;
              });
              if (!isEqual(permissions, oldPermissions)) {
                setTimeout(() => {
                  this.props.ModelStore.Logout();
                  this.props.UiStore.SetLoginStatus(false);
                  this.props.AuthStore.resetStore();
                }, 1000);
              }
            }
          }
        }
      }
    }
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};

    let rolesData = this.roleModel.rolesData;
    //table columns
    this.columns = [
      {
        title: (
          <span title={getCurrentlocaleText("general.roles.label.text")}>
            {getCurrentlocaleText("general.roles.label.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: !this.props.UiStore.showChildRoles ? "40%" : "30%",
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {this.props.AuthStore.IsPermitted("ROLE:VIEW") && (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineIdcard" /> View Role
                    </span>
                  }
                  actionForm={RolesForms}
                  action={"view"}
                  resource="roles"
                  beforeCall={this.prepareData}
                  className={"action-link"}
                  orgId={this.props.orgId}
                  closeModal={this.closeModal}
                  initialVal={record.value}
                  style={{
                    padding: "unset",
                  }}
                  map={{ id: record.id }}
                  footer={[
                    <Button
                      type="primary"
                      key="back"
                      size="large"
                      onClick={() => {
                        this.props.UiStore.closeFormModal("roles");
                        this.setState({ type: "" });
                      }}
                    >
                      Close
                    </Button>,
                  ]}
                  help={false}
                  controller={RoleController}
                >
                  {" "}
                  <a>
                    {this.state.searchText.trim().length > 0
                      ? highlightSearchText(text, this.state.searchText)
                      : text}
                  </a>
                </ActionBtn>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("role.list.user_count.text")}>
            {getCurrentlocaleText("role.list.user_count.text")}
          </span>
        ),
        dataIndex: "users",
        key: "users",
        width: !this.props.UiStore.showChildRoles ? "40%" : "30%",
        render: (text, record, index) => {
          return <div style={{ marginLeft: 20 }}>{record.users}</div>;
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("myprofile.organization.text")}>
            {getCurrentlocaleText("myprofile.organization.text")}
          </span>
        ),
        dataIndex: "org",
        key: "org",
        width: !this.props.UiStore.showChildRoles ? "35%" : "30%",
        render: (text, record, index) => {
          return (
            <Link to={"/dashboard/" + record.value.organization.id}>
              {record.value &&
                record.value.organization &&
                record.value.organization.name}
            </Link>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: "10%",
        render: (text, record, index) => {
          const view = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineIdcard" /> View Role
                  </span>
                }
                actionForm={RolesForms}
                action={"view"}
                resource="roles"
                beforeCall={this.prepareData}
                iconButton
                icon="AiOutlineEye"
                className={record.id}
                changeRoute={this.props.changeRoute}
                afterCall={this.props.onAfterClick}
                orgId={this.props.orgId}
                initialVal={record.value}
                map={{ id: record.id }}
                help={false}
                controller={RoleController}
                disabled={!this.props.AuthStore.IsPermitted("ROLE:VIEW")}
                iconBtnStyle={{ fontSize: 14 }}
              >
                Close
              </ActionBtn>
            </span>
          );
          const edit = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" /> Edit Role
                  </span>
                }
                type=""
                actionForm={RolesForms}
                action={"edit"}
                resource="roles"
                beforeCall={this.prepareData}
                iconButton
                icon="AiOutlineEdit"
                orgId={record.organization.id}
                changeRoute={this.props.changeRoute}
                userCount={record.user_count}
                initialVal={record.value}
                map={{ id: record.id }}
                okText={
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" /> Update{" "}
                  </span>
                }
                disabled={
                  !(
                    this.props.AuthStore.IsPermitted("ROLE:CREATE") &&
                    record.is_editable
                  )
                }
                afterCall={this.logout}
                controller={RoleController}
              />
            </span>
          );
          const del = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      {
                        0: "Role",
                      },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      {
                        0: "Role",
                      },
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                iconButton
                icon="AiOutlineDelete"
                formData={record}
                map={{ id: record.id }}
                resource="roles"
                action="delete"
                actionForm={RolesForms}
                disabled={
                  !(
                    this.props.AuthStore.IsPermitted("ROLE:CREATE") &&
                    record.is_editable &&
                    record.user_count <= 0
                  )
                }
                afterCall={this.deleteAfterCall.bind()}
                controller={RoleController}
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        "single.role.delete.confirmation.message",
                      )}
                    </p>
                  </span>
                }
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  {
                    0: "Role",
                  },
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0: "Role",
                    })}
                  </span>
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          );
          const menu = (
            <Menu>
              <Menu.Item key={0}>{view}</Menu.Item>
              <Menu.Item key={1}>{edit}</Menu.Item>
              <Menu.Item key={2}>{del}</Menu.Item>
            </Menu>
          );
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    if (!this.props.UiStore.showChildRoles) {
      this.columns.splice(2, 1);
    }

    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "50%" }}>
          <span className="headertext">
            {getCurrentlocaleText("general.roles.label.text")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildRoles}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("role.list.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchInput
              placeholder="Filter roles by name"
              onChange={this.roleListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.AuthStore.IsPermitted("ROLE:CREATE") && (
              <ActionBtn
                type="primary"
                title={
                  <span>
                    {this.state.modelAction == "create" && (
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineIdcard"
                          style={{ fontSize: 16 }}
                        />{" "}
                        Add Role
                      </span>
                    )}

                    {this.state.modelAction == "edit" && (
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineEdit"
                          style={{ fontSize: 16 }}
                        />{" "}
                        Edit Role
                      </span>
                    )}
                    {this.state.modelAction == "view" && (
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineEye"
                          style={{ fontSize: 14, marginRight: 2 }}
                        />
                        View Role
                      </span>
                    )}
                  </span>
                }
                resource="roles"
                icon="AiOutlinePlus"
                iconButton
                action={this.state.modelAction}
                autoOpenModel={this.state.autoOpen}
                changeRoute={this.props.changeRoute}
                width={600}
                orgId={this.props.orgId}
                beforeCall={this.prepareData}
                initialVal={
                  this.state.modelAction !== "create"
                    ? this.state.currUserRecord
                    : null
                }
                map={{ id: this.state.autoOpenOrgID }}
                actionForm={RolesForms}
                afterCall={this.createAfterCall}
                controller={RoleController}
              >
                {this.state.modelAction == "create" && (
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlinePlus"
                      style={{ margin: 0 }}
                    />{" "}
                    Add Role
                  </span>
                )}

                {this.state.modelAction == "edit" && (
                  <span>
                    <Icons type="ai" name="AiOutlineEdit" />{" "}
                    {getCurrentlocaleText("update.text")}{" "}
                  </span>
                )}
                {this.state.modelAction == "view" && (
                  <span>
                    <Icons type="ai" />{" "}
                    {getCurrentlocaleText("close.form.button.text")}{" "}
                  </span>
                )}
              </ActionBtn>
            )}
            {this.props.AuthStore.IsPermitted("ORG:DELETE") && (
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      {
                        0:
                          this.state.deletableRoles.roles.length > 1
                            ? "Roles"
                            : "Role",
                      },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      {
                        0:
                          this.state.deletableRoles.roles.length > 1
                            ? "Roles"
                            : "Role",
                      },
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                icon="AiOutlineDelete"
                formData={this.state.deletableRoles.roles}
                iconButton
                resource="roles"
                action="delete"
                disabled={this.state.deletableRoles.roles.length === 0}
                afterCall={this.deleteAfterCall}
                controller={RoleController}
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        this.state.deletableRoles.roles.length > 1
                          ? "multiple.role.delete.confirmation.message"
                          : "single.role.delete.confirmation.message",
                      )}
                    </p>
                  </span>
                }
                warningMessage={
                  this.state.deletableRoles.roles.length > 1
                    ? getCurrentlocaleText(
                        "multiple.role.delete.warning.message",
                      )
                    : getCurrentlocaleText("single.role.delete.warning.message")
                }
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  {
                    0:
                      this.state.deletableRoles.roles.length > 1
                        ? "Roles"
                        : "Role",
                  },
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0:
                        this.state.deletableRoles.roles.length > 1
                          ? "Roles"
                          : "Role",
                    })}
                  </span>
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            )}
          </div>
        </div>
        {this.AuthStore.loggedInUser &&
        this.AuthStore.loggedInUser.roles &&
        this.roleModel.roles.length > 0 ? (
          <div>
            <TableLayoutV1
              columns={this.columns}
              dataSource={rolesData}
              pagination={{
                pageSize: this.roleModel && this.roleModel.pageable.size,
                total: this.roleModel && this.roleModel.pageable.total_count,
                current: this.state.currentPage,
              }}
              selectedRows={this.handleTableChange}
              selectAll={this.onSelectAllChange}
              onChange={this.onTableChange}
              expandedRowKeys={this.state.expandedRowKeys}
              onExpand={this.onExpand}
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              handleModalOk={e => this.handleModalOk(e)}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              isModalLoading={false}
              disableFooter={true}
              className="roleList"
              selectionType={true}
            />
          </div>
        ) : (
          <div>
            {!this.roleModel.loading && <h3>No user role to display</h3>}
          </div>
        )}
      </div>
    );

    return (
      <LoadingComponent loading={this.roleModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

RolesList.propTypes = {
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
  orgId: propTypes.string.isRequired,
};

export default RolesList;
