import React, { Component } from "react";
import {
  Row,
  Col,
  Icon,
  Switch,
  Collapse,
  Radio,
  DatePicker,
  Modal,
  Select,
  Alert,
  Button,
  Slider,
  message,
  Spin,
} from "antd";
import { Form } from "@ant-design/compatible";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import Input from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import {
  focusFirstInput,
  getCurrentlocaleText,
  removeDefaultLabels,
  computeMinutesToDuration,
  checkforVirtualProfiles,
  checkforFeatureEnabled,
  isDevelopmentMode,
  markCollapseHeader,
  DEFAULT_STAT_MODE,
  DataSaverAndPodDnsBaseVersion,
  getNodeVersion,
  getDefaultTimezone,
  softwareUpgradeBaseVersion,
  ONE_ARM_MODE,
  DEFAULT_MODE,
  getTagKeyValues,
  getTagFromReservedLabels,
  copyObject,
  tanDadBaseVersion,
  resourceLimitBaseVersion,
  isCidr,
  isTanCidrRange,
  InterTanRouteBaseVersion,
  getSamplingFreqMarks,
  multiNICVersion,
} from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import LabelProvider from "components/UI-Components/LabelProvider";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import GenerateLabelHelp from "components/UI-Components/GenerateLabelHelp";
import GenerateSSHKeyLinkHelp from "components/UI-Components/GenerateSSHKeyLinkHelp";
import DurationSelector from "components/UI-Components/DurationSelector";
import InterfaceTrafficSelector from "components/UI-Components/InterfaceTrafficSelector";
import OrgController from "controller/OrgController";
import CertificateSelect from "components/UI-Components/CertificateSelect";
import Icons from "components/UI-Components/Icons";
import InodeController from "controller/InodeController";
import TimezoneComponent from "../Logs/Components/TimezoneComponent";
import NetworkController from "controller/NetworkController";
import LocationInput from "components/UI-Components/LocationInput";

const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

@inject(
  "ModelStore",
  "UiStore",
  "ProfileViewModel",
  "OrgViewModel",
  "InodeViewModel",
  "TimezoneViewModel",
  "AuthStore",
  "NetworkViewModel",
)
@observer
class EditNodeForm extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.state = {
      name: null,
      profileName: null,
      hardwareSerialNumber: null,
      tags: [],
      metadata: {},
      reservedLabels: [],
      headLessMode: false,
      oneArmMode: false,
      unifiedCloudGateway: false,
      unifiedCloudGatewayEnabled: false,
      isVirtual: false,
      isVirtualEdge: false,
      cGroupMem: 0,
      cGroupMemLoading: false,
      cGroupMemConfigured: true,
      durationValue: {
        duration: isDevelopmentMode() ? 5 : 60,
        units: "MINUTES",
      },
      tanDadMode: false,
      tanDadDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
      orgInitialVal: {},
      intefaceTraffic: "SLOW",
      statsMode: false,
      upgradeChannelSelected: "",
      samplingFrequencySelected: 50000,
      softwareUpgrade: false,
      threatDetection: false,
      deviceDiscovery: false,
      HwMonitoring: false,
      HWMDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
      upgradeAfter: moment.tz(
        new Date().getTime(),
        this.timezoneModel.userTimezone ||
          this.timezoneModel.orgTimezone ||
          getDefaultTimezone().value,
      ),
      upgradeFrom: null,
      upgradeTo: null,
      getUpgradeAfter: null,
      getUpgradeBefore: null,
      showOneArmModeWarning: false,
      container_timezone: null,
      container_timezone_change: false,
      mem_resource_change: false,
      isResourceLimitVisible: false,
      isGen3: false,
      isGCP: false,
      isRepresentationNetworkAutomation: false,
      representationNetworkAutomation: false,
      cidr: "",
      suggestedCidr: "",
      clearCidrValue: false,
      networkSelectList: [],
      networkSelected: [],
      isMultiNICMode: false,
      isMultiNicChanged: false,
      isEdge: false,
    };
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.name =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.name
        ? this.GLOBAL_SETTINGS.name
        : "View";
  }
  /**
   *  Generate Help
   */
  GenerateHelp = () => {
    return (
      <span>
        <Row>
          <Col span={12}>
            <b>owner:&nbsp;</b>
            <span>dev/ann</span>
          </Col>
          <Col span={12}>
            <b>owner:&nbsp;</b>
            <span>qa/bob</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>version:&nbsp;</b>
            <span>1.2.3</span>
          </Col>
          <Col span={12}>
            <b>version:&nbsp;</b>
            <span>18.05.2</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>release:&nbsp;</b>
            <span>stable</span>
          </Col>
          <Col span={12}>
            <b>release:&nbsp;</b>
            <span>canary</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>deployment:&nbsp;</b>
            <span>staging</span>
          </Col>
          <Col span={12}>
            <b>deployment:&nbsp;</b>
            <span>pre-production</span>
          </Col>
        </Row>
      </span>
    );
  };

  checkAllSelected = networkSelected => {
    let networkSelectList = this.state.networkSelectList;
    if (networkSelected.length === networkSelectList.length) {
      let result = networkSelectList.every(val =>
        networkSelected.includes(val.id),
      );
      if (result) {
        this.setState({
          networkSelected: ["all"],
        });
      }
    }
  };

  getDeviceDiscovery = node_id => {
    InodeController.getDeviceDiscovery(node_id)
      .then(res => {
        if (res && res.enable) {
          this.setState({
            deviceDiscovery: res.enable,
          });
          this.inodeModel.setDeviceDiscovery(res.enable);
        } else {
          this.setState({
            deviceDiscovery: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          deviceDiscovery: false,
        });
      });
  };

  getNodeUcg = node_id => {
    InodeController.getNodeUCG(node_id)
      .then(res => {
        if (res && res.enable) {
          this.setState({
            unifiedCloudGatewayEnabled: res.enable,
          });
          this.inodeModel.setinodeUcgData(res.enable);
        } else {
          this.setState({
            unifiedCloudGatewayEnabled: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          unifiedCloudGatewayEnabled: false,
        });
      });
  };

  componentDidMount() {
    let formData = this.props.formData;
    if (
      (this.props.action === "edit" || this.props.action === "view") &&
      formData
    ) {
      if (
        formData.name &&
        formData.profile &&
        formData.profile.name &&
        formData.hardware_serial_number
      ) {
        this.setState({
          name: formData.name,
          profileName: formData.profile.name,
          hardwareSerialNumber: formData.hardware_serial_number,
        });
      }
      if (formData.metadata && formData.metadata.labels) {
        this.setState({
          metadata: removeDefaultLabels(copyObject(formData.metadata.labels)),
          tags: getTagKeyValues(formData.metadata.labels),
          reservedLabels: getTagFromReservedLabels(formData.metadata.labels),
        });
      }
      // updates value for headless
      if (formData.max_headless_time && formData.max_headless_time != 0) {
        this.setState({
          headLessMode: true,
          durationValue: computeMinutesToDuration(formData.max_headless_time),
        });
        markCollapseHeader("node-advanced-settings-collapse", true);
      }
      // updates value for one-arm
      if (
        formData.node_network_mode &&
        formData.node_network_mode === ONE_ARM_MODE
      ) {
        this.setState({
          oneArmMode: true,
        });
        markCollapseHeader("node-advanced-settings-collapse", true);
      }

      // Update upgrade config
      if (formData.upgrade_config && formData.upgrade_config.channel) {
        this.setState({
          upgradeChannelSelected: formData.upgrade_config.channel,
          softwareUpgrade:
            formData.upgrade_config.channel != "OFF" ? true : false,
        });
        if (formData.upgrade_config.channel != "OFF") {
          markCollapseHeader("node-advanced-settings-collapse", true);
        }
      }
      // Update upgrade config
      if (formData.upgrade_config && formData.upgrade_config.after) {
        if (this.state.upgradeAfter != formData.upgrade_config.after) {
          this.setState({
            upgradeAfter: moment.tz(
              formData.upgrade_config.after,
              this.timezoneModel.userTimezone ||
                this.timezoneModel.orgTimezone ||
                getDefaultTimezone().value,
            ),
          });
        } else {
          this.setState({
            upgradeAfter: moment.tz(
              new Date().getTime(),
              this.timezoneModel.userTimezone ||
                this.timezoneModel.orgTimezone ||
                getDefaultTimezone().value,
            ),
          });
        }
      }
      // Update stat config
      if (formData.stat_config && formData.stat_config.stat_mode) {
        this.setState({
          statsMode:
            formData.stat_config.stat_mode !== DEFAULT_STAT_MODE ? true : false,
          intefaceTraffic: formData.stat_config.stat_mode,
        });
        if (formData.stat_config.stat_mode !== DEFAULT_STAT_MODE) {
          markCollapseHeader("node-advanced-settings-collapse", true);
        }
      }

      // update timezone
      if (formData.container_timezone) {
        this.setState({
          container_timezone: formData.container_timezone,
        });
      }

      // update cloudroute config
      if (
        formData.cloud_route &&
        formData.cloud_route.representation_network_automation
      ) {
        this.setState({
          representationNetworkAutomation:
            formData.cloud_route.representation_network_automation,
        });
      }
      if (formData && formData.location_info) {
        let location_info = formData.location_info;
        this.props.form.setFields({
          street: {
            value: location_info.street,
          },
          city: {
            value: location_info.city,
          },
          state: {
            value: location_info.state,
          },
          country: {
            value: location_info.country,
          },
          zipcode: {
            value: location_info.zipcode,
          },
        });
      }

      // Multnic Mode
      if (formData.multinic_mode && formData.multinic_mode.enable) {
        this.setState({
          isMultiNICMode: true,
        });
        markCollapseHeader("node-advanced-settings-collapse", true);
      } else {
        this.setState({
          isMultiNICMode: false,
        });
      }
    }
    // checks the given iNode is virtual or edge to disable headless;

    if (
      this.props.ProfileViewModel.profiles &&
      formData.profile &&
      formData.profile.id
    ) {
      this.setState(
        {
          isVirtual: checkforVirtualProfiles(
            formData.profile.id,
            this.props.ProfileViewModel.profiles,
          ),
        },
        () => {
          /* Focus First Input */
          focusFirstInput();
          this.getorgInfo();
          // checks if given iNode is virtual edge or edge to disable ssh key;
          let isVirtualEdge = formData.profile.name === "Virtual Edge";
          let isVirtual = formData.profile.name === "Virtual";
          let unifiedCloudGateway =
            formData.profile.name === "Unified Cloud Gateway";
          let isEdge = formData.profile.name === "Edge";
          this.setState({
            isVirtualEdge: isVirtualEdge,
            isVirtual: isVirtual,
            unifiedCloudGateway: unifiedCloudGateway,
            isEdge: isEdge,
          });
          let node_data = this.inodeModel.inode ? this.inodeModel.inode : null;
          let isResourceLimitVisible =
            node_data &&
            node_data.node_state &&
            (node_data.node_state === "NEW" ||
              parseFloat(getNodeVersion(node_data)) >=
                resourceLimitBaseVersion);
          this.setState({ isResourceLimitVisible: isResourceLimitVisible });
          let isGcp =
            node_data &&
            node_data.cloud_init_config &&
            node_data.cloud_init_config.cloud_provider &&
            node_data.cloud_init_config.cloud_provider === "gcp"
              ? true
              : false;
          this.setState({ isGCP: isGcp });
          let isGen3 =
            node_data &&
            node_data.status &&
            node_data.status.node &&
            node_data.status.node.info &&
            node_data.status.node.info.distro &&
            node_data.status.node.info.distro.id &&
            node_data.status.node.info.distro.id == "GEN3"
              ? true
              : false;
          this.setState({ isGen3: isGen3 });
          let iNodeId = this.props.formData.id ? this.props.formData.id : null;
          if (iNodeId) {
            if (
              isResourceLimitVisible &&
              ((!this.state.isVirtual && !isVirtualEdge) ||
                (isVirtualEdge && isGcp))
            ) {
              this.getCGroupMemory(iNodeId);
            }
            if (!this.state.isVirtual) {
              this.inodeModel.getDadInfoLoading = true;
              this.inodeModel.getTrafficInsightLoading = true;
              this.inodeModel.getHWMInfoLoading = true;
              this.getDadInfo(iNodeId);
              this.getHWMInfo(iNodeId);
              this.getTrafficInsight(iNodeId);
              this.getDeviceDiscovery(iNodeId);
            }
            if (!this.state.isVirtual && !this.state.isVirtualEdge) {
              this.getNodeUcg(iNodeId);
            }
          }

          if (
            node_data &&
            node_data.cloud_route &&
            node_data.cloud_route.cidr
          ) {
            this.setState({
              cidr: node_data.cloud_route.cidr,
              isRepresentationNetworkAutomation: true,
            });
          }
        },
      );
    }
  }

  /**
   *  GET TAN DAD VALUE TO PRE-FILL
   */
  getDadInfo = nodeId => {
    this.inodeModel.getDadInfoLoading = true;
    InodeController.getTanDad(nodeId)
      .then(resp => {
        if (resp) {
          this.setState({
            tanDadMode: resp.enable,
            tanDadDurationValue: computeMinutesToDuration(resp.scan_interval),
          });
          this.inodeModel.setTandadOldVal({
            Mode: resp.enable,
            Duration: resp.scan_interval,
          });
          if (resp.enable) {
            markCollapseHeader("node-advanced-settings-collapse", true);
          }
        }
        this.inodeModel.getDadInfoLoading = false;
      })
      .catch(err => {
        this.inodeModel.getDadInfoLoading = false;
      });
  };

  /**
   *  CHECK FOR Threat Intelligence
   */
  getTrafficInsight = nodeId => {
    let ctdMethod = false;
    this.inodeModel.getTrafficInsightLoading = true;
    InodeController.getTrafficInsight(nodeId)
      .then(response => {
        if (response) {
          if (
            !response.threat_detection_enable &&
            !response.network_visibility_enable
          ) {
            this.setState({
              threatDetection: response.threat_detection_enable,
            });
          } else {
            this.setState({
              samplingFrequencySelected: response.sampling_frequency_in_pkts,
              threatDetection: response.threat_detection_enable,
            });
          }
          this.inodeModel.setTrafficInsightData({
            response,
          });
          this.inodeModel.getTrafficInsightLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getTrafficInsightLoading = false;
      });
  };

  /**
   *  GET HW Monitoring VALUE TO PRE-FILL
   */
  getHWMInfo = nodeId => {
    this.inodeModel.getHWMInfoLoading = true;
    InodeController.getHWM(nodeId)
      .then(resp => {
        if (resp) {
          this.setState({
            HwMonitoring: resp.enable,
            HWMDurationValue: computeMinutesToDuration(resp.scan_interval),
          });
          this.inodeModel.setHwMonitoringOldVal({
            Mode: resp.enable,
            Duration: resp.scan_interval,
          });
          if (resp.enable) {
            markCollapseHeader("node-advanced-settings-collapse", true);
          }
          this.inodeModel.getHWMInfoLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getHWMInfoLoading = false;
      });
  };

  /**
   *  CHECK FOR SUPPORTED VERSIONS
   */
  checkForFeatureSupported = feature => {
    let node_data = this.inodeModel.inode ? this.inodeModel.inode : null;
    if (node_data) {
      if (node_data.node_state && node_data.node_state != "NEW") {
        switch (feature) {
          case "STANDALONE":
            return true;
          case "DATASAVER":
            if (
              parseFloat(getNodeVersion(node_data)) >=
              DataSaverAndPodDnsBaseVersion
            )
              return true;
          case "SOFTWAREUPGRADE":
            if (
              parseFloat(getNodeVersion(node_data)) > softwareUpgradeBaseVersion
            )
              return true;
            else return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  /**
   * GET CURRENT ORG POLICY INFO
   */
  getorgInfo = () => {
    OrgController.getOrgById(this.props.orgId, true).then(response => {
      if (response) {
        this.setState({ orgInitialVal: response });
      }
    });
  };

  /**
   *  Get C Group Memory
   */
  getCGroupMemory = nodeId => {
    this.setState({
      cGroupMemLoading: true,
      cGroupMemConfigured: true,
    });
    this.inodeModel.getCGroupMemoryLoading = true;
    InodeController.getCGroupMemory(nodeId)
      .then(response => {
        if (
          response &&
          response.memory &&
          response.memory.user_service_limit_in_percentage
        ) {
          this.setState({
            cGroupMem: response.memory.user_service_limit_in_percentage,
          });
          this.inodeModel.setMemResourceLimitOldVal(
            response.memory.user_service_limit_in_percentage,
          );
        } else {
          this.setState({
            cGroupMemConfigured: false,
          });
        }
        this.setState({
          cGroupMemLoading: false,
        });
        this.inodeModel.getCGroupMemoryLoading = false;
      })
      .catch(err => {
        this.inodeModel.getCGroupMemoryLoading = false;
      });
  };

  handleLabelChange = values => {
    this.props.form.setFields({
      metadata: {
        value: { labels: values },
      },
    });
  };
  /**
   * HEADLESS MODE ON CHANGE
   */
  onHeadlessModeChange = value => {
    this.setState({
      headLessMode: value,
      durationValue: {
        duration: isDevelopmentMode() ? 5 : 60,
        units: "MINUTES",
      },
    });
  };

  onHeadlessDurationChange = value => {
    this.props.form.setFields({
      max_headless_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ durationValue: value });
  };

  onTanDadModeChange = value => {
    this.setState({
      tanDadMode: value,
      tanDadDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
    });
  };

  onTanDadModeDurationChange = value => {
    this.props.form.setFields({
      max_tandad_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ tanDadDurationValue: value });
  };

  onHWMModeChange = value => {
    this.setState({
      HwMonitoring: value,
      HWMDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
    });
  };

  onHWMDurationChange = value => {
    this.props.form.setFields({
      max_hwm_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ HWMDurationValue: value });
  };

  onOneArmModeChange = value => {
    this.setState({
      oneArmMode: value,
    });
    if (
      this.props.formData.node_network_mode &&
      this.props.formData.node_network_mode === ONE_ARM_MODE &&
      value === false
    ) {
      this.setState({
        showOneArmModeWarning: true,
      });
    } else if (
      ((this.props.formData.node_network_mode &&
        this.props.formData.node_network_mode === DEFAULT_MODE) ||
        !this.props.formData.node_network_mode) &&
      value === true
    ) {
      this.setState({
        showOneArmModeWarning: true,
      });
    }
  };

  onUnifiedCloudGateway = value => {
    this.setState({ unifiedCloudGatewayEnabled: value });
  };

  onStatsModeChange = value => {
    this.setState({
      statsMode: value,
      intefaceTraffic: value ? "SLOW" : DEFAULT_STAT_MODE,
    });
  };

  upgradeChannelOnSelect = e => {
    let value = e.target.value;
    this.setState({
      upgradeChannelSelected: value,
    });
  };

  samplingFrequencyOnSelect = value => {
    this.setState({
      samplingFrequencySelected: value,
    });
  };

  changeCtd = () => {
    this.setState({
      threatDetection: !this.state.threatDetection,
      samplingFrequencySelected: 50000,
    });
  };

  changeDeviceDiscovery = () => {
    this.setState({
      deviceDiscovery: !this.state.deviceDiscovery,
    });
  };

  handleInterfaceTraffic = value => {
    this.props.form.setFields({
      stat_mode: {
        value: value,
      },
    });
    this.setState({ intefaceTraffic: value });
  };

  setSSHKeySelectVal = values => {
    let formVals = {};
    formVals[values.type] = {
      value: values.value,
    };
    this.props.form.setFields(formVals);
  };

  changeSoftwareUpdate = () => {
    this.setState({
      softwareUpgrade: !this.state.softwareUpgrade,
      upgradeChannelSelected: "STABLE",
      upgradeAfter: moment.tz(
        new Date().getTime(),
        this.timezoneModel.userTimezone ||
          this.timezoneModel.orgTimezone ||
          getDefaultTimezone().value,
      ),
    });
  };

  /**
   * Updgrade After change event
   */
  onStartAfterChange = (date, dateString) => {
    this.setState({
      upgradeAfter: date,
    });
    this.props.form.setFields({
      upgrade_after: {
        value: date,
      },
    });
  };

  disabledDate = current => {
    // Can not select days before today and today and after 90 days
    let timezone =
      this.props.TimezoneViewModel.userTimezone ||
      this.props.TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value;
    let endDate = moment.tz(timezone).add(90, "days");
    return (
      (current && current.valueOf() < moment.tz(timezone).valueOf()) ||
      (current && current.isAfter(endDate))
    );
  };

  hideWarningModal = () => {
    this.setState({
      showOneArmModeWarning: false,
    });
  };

  resetOneArmMode = () => {
    this.props.form.setFields({
      one_arm_mode: {
        value:
          this.props.formData.node_network_mode === ONE_ARM_MODE ? true : false,
      },
    });
    this.setState({
      oneArmMode:
        this.props.formData.node_network_mode === ONE_ARM_MODE ? true : false,
      showOneArmModeWarning: false,
    });
  };

  downloadDefaultSSHKey = () => {
    let file = this.props.formData.hardware_serial_number + ".pem";
    InodeController.downloadDefaultSSHKey(this.props.formData.id, file);
  };

  selectedTimezoneValue = value => {
    this.props.form.setFields({
      container_timezone: {
        value: value,
      },
    });
    this.setState({
      container_timezone_change: true,
    });
  };

  onChangecGroupMem = value => {
    this.setState({
      cGroupMem: value,
      mem_resource_change: true,
    });
  };

  /**  Get cloud route */
  getCloudroute = value => {
    let currentOrgInfo = this.orgModel.org;
    if (
      currentOrgInfo.policy &&
      currentOrgInfo.policy.network &&
      currentOrgInfo.policy.network.representation_network_automation &&
      currentOrgInfo.policy.network.representation_network_automation.enable
    ) {
      if (value) {
        InodeController.getCloudroute(this.props.formData.id).then(response => {
          if (response) {
            if (response.suggested_default_cidr == "" && response.cidr == "") {
              message.warning({
                content: getCurrentlocaleText(
                  "inode.cloud.route.cidr.exceeds.info",
                ),
              });
            }
            this.setState({
              cidr: response.suggested_default_cidr
                ? response.suggested_default_cidr
                : response.cidr,
              isRepresentationNetworkAutomation: value,
              suggestedCidr: response.suggested_default_cidr
                ? response.suggested_default_cidr
                : null,
            });
            this.inodeModel.setCloudroute(response);
          }
        });
      } else {
        this.setState({
          isRepresentationNetworkAutomation: value,
        });
      }
    } else {
      message.error({
        content: getCurrentlocaleText(
          "inode.repNet.automation.policy.disable.error",
        ),
      });
    }
  };

  checkCidr = (rule, value, callback, key) => {
    let message = null;
    if (value == null) {
    } else {
      let isCidrValid = value && isCidr(value, false);
      if (!isCidrValid) {
        message = getCurrentlocaleText("network.form.cidr.errormessage2.text");
      } else {
        let isCidrIPInRange = isTanCidrRange(value, value);
        if (isCidrIPInRange) {
          message = getCurrentlocaleText(
            "network.form.cidr.errormessage4.text",
          );
        }
      }
    }
    if (message) {
      callback(message);
    } else {
      callback();
    }
  };

  onChange = e => {
    if (e.target.value === "") {
      this.setState({
        clearCidrValue: e.target.value,
      });
    } else {
      this.setState({
        cidr: e.target.value,
      });
    }
  };

  handleSelectAll = value => {
    if (value && value.length && value.includes("all")) {
      if (value.length === this.state.networkSelectList.length + 1) {
        return [];
      }
      return [this.state.networkSelectList.map(val => val.id)];
    }
    return value;
  };

  listContainers = () => {
    let x = this.state.networkSelectList.map(val => {
      if (!val.is_wan_network) {
        return <Option key={val.id}>{val.name}</Option>;
      }
    });
    return x;
  };

  defaultValues = () => {
    let x = this.state.networkSelected.map(val => {
      return val.id;
    });
    return x;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let isHeadlessModeVisible =
      !this.state.isVirtual &&
      ((this.state.headLessMode &&
        !checkforFeatureEnabled(this.state.orgInitialVal, "headless_mode")) ||
        checkforFeatureEnabled(this.state.orgInitialVal, "headless_mode"));

    let isOneArmModeVisible =
      !this.state.isVirtual &&
      ((this.props.formData.node_network_mode &&
        this.props.formData.node_network_mode === ONE_ARM_MODE) ||
        checkforFeatureEnabled(this.state.orgInitialVal, "one_arm_mode"));
    let node_data = this.inodeModel.inode ? this.inodeModel.inode : null;
    let cGroupMemoryExisting =
      ((this.state.isGen3 ||
        (node_data &&
          node_data.node_state &&
          node_data.node_state === "NEW")) &&
        !this.state.isVirtual &&
        !this.state.isVirtualEdge) ||
      (this.state.isVirtualEdge && this.state.isGCP)
        ? true
        : false;
    let isUnifiedCloudGatewayVisible = this.state.isEdge ? true : false;
    const loaderIcon = (
      <LoadingOutlined
        style={{
          fontSize: 12,
        }}
        spin
      />
    );
    if (this.state.unifiedCloudGateway) {
      isHeadlessModeVisible = false;
    }
    if (
      isOneArmModeVisible &&
      node_data.status &&
      node_data.status.node &&
      node_data.status.node.info &&
      node_data.status.node.info.distro &&
      node_data.status.node.info.distro.id
    ) {
      isOneArmModeVisible =
        node_data.status.node.info.distro.id === "GEN3" ? true : false;
    }
    if (isOneArmModeVisible && this.state.isGCP) {
      isOneArmModeVisible = false;
    }
    if (this.state.isVirtualEdge || this.state.unifiedCloudGateway) {
      isOneArmModeVisible = false;
    }
    let isSSHKeysVisible =
      !this.state.isVirtual && !this.state.unifiedCloudGateway;
    let isDefaultSSHKeyVisible =
      !this.state.isVirtual &&
      !this.state.isVirtualEdge &&
      node_data &&
      node_data.node_state &&
      node_data.node_state === "NEW";

    let isCollapsePanelVisible =
      (isHeadlessModeVisible && this.checkForFeatureSupported("STANDALONE")) ||
      this.checkForFeatureSupported("DATASAVER") ||
      this.checkForFeatureSupported("SOFTWAREUPGRADE")
        ? true
        : false;

    let isEditBlocked = this.props.AuthStore.IsPermitted("NODE:CREATE")
      ? false
      : true;
    let isClusterNode = node_data && node_data.cluster ? true : false;
    let isTanDadModeVisible =
      !this.state.isVirtual && !this.state.unifiedCloudGateway;
    node_data &&
      node_data.node_state &&
      (node_data.node_state === "NEW" ||
        parseFloat(getNodeVersion(node_data)) >= tanDadBaseVersion);
    let isAutoRepnetVisible = checkforFeatureEnabled(
      this.state.orgInitialVal,
      "autorepnet",
    );
    let isHWMVisible =
      checkforFeatureEnabled(this.state.orgInitialVal, "hwm") &&
      !this.state.isVirtual &&
      (isDevelopmentMode()
        ? true
        : !this.state.isVirtualEdge &&
          node_data &&
          node_data.node_state &&
          (node_data.node_state === "NEW" ||
            (node_data.status &&
              node_data.status.node &&
              node_data.status.node.info &&
              node_data.status.node.info.device_vendor_details &&
              node_data.status.node.info.device_vendor_details
                .toUpperCase()
                .includes("R240"))));
    // show Threat Intelligence when node is NEW or node version is greater than 2776 and non virtual node
    let isThreatDetectionSupported =
      parseFloat(getNodeVersion(node_data)) <= InterTanRouteBaseVersion
        ? false
        : true;
    let showThreatDetection =
      node_data &&
      node_data.node_state &&
      (node_data.node_state === "NEW" || isThreatDetectionSupported) &&
      !this.state.isVirtual &&
      !this.state.unifiedCloudGateway;
    // To enable/disable multinic mode
    let isMultinicVisible =
      !this.state.isVirtual &&
      !this.state.isVirtualEdge &&
      !this.state.unifiedCloudGateway;
    !this.state.oneArmMode &&
      !isClusterNode &&
      parseFloat(getNodeVersion(node_data)) >= multiNICVersion;
    return (
      <Form noValidate onSubmit={this.props.onSubmit}>
        <FormItem
          label={getCurrentlocaleText("inode.form.inode_name.label.text")}
          hasFeedback
        >
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "inode.form.inode_name.required.help.text",
                ),
                whitespace: true,
              },
              {
                max: 255,
                message: getCurrentlocaleText(
                  "inode.name.length.validation.message",
                ),
              },
              {
                pattern: /^[A-Za-z0-9 .&,_-]*$/,
                message: getCurrentlocaleText(
                  "inode.name.regex.validation.message",
                ),
              },
            ],
            initialValue: this.state.name,
          })(
            <Input
              className="firstInput"
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineFork" />}
              placeholder={getCurrentlocaleText(
                "inode.form.inode_name.label.text",
              )}
              disabled={
                this.props.action === "view" || isEditBlocked ? true : false
              }
            />,
          )}
        </FormItem>
        <FormItem
          colon={false}
          label={
            <span className={"iot-label-not-required"}>
              {getCurrentlocaleText("general.form.label.text")}
              <PopOverHelp
                isHelpOnForm={true}
                height={285}
                width={475}
                placement={"rightTop"}
                helpData={{
                  title: getCurrentlocaleText("general.form.label.text"),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "general.form.label.field.help.text",
                      ),
                    },
                    {
                      subtitle: getCurrentlocaleText(
                        "general.form.label.field.help2.text",
                      ),
                      content: <GenerateLabelHelp />,
                    },
                  ],
                }}
              />
            </span>
          }
        >
          {getFieldDecorator("metadata", {
            initialValue: { labels: this.state.metadata },
          })(
            <div style={{ marginLeft: 10 }}>
              <LabelProvider
                tags={this.state.tags}
                metadata={this.state.metadata}
                handleChange={this.handleLabelChange}
                isModal={true}
                viewOnly={
                  this.props.action === "view" || isEditBlocked ? true : false
                }
                reservedLabels={this.state.reservedLabels}
              />
            </div>,
          )}
        </FormItem>
        <FormItem label="Profile" hasFeedback>
          {getFieldDecorator("profile_name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "inode.form.profile.required.help.text",
                ),
              },
            ],
            initialValue: this.state.profileName,
          })(
            <Input
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineFork" />}
              placeholder={getCurrentlocaleText(
                "inode.add.profile.placeholder.text",
              )}
              disabled={true}
            />,
          )}
        </FormItem>
        <FormItem
          label={getCurrentlocaleText("inode.systeminfo.serialnumber.text")}
          hasFeedback
        >
          {getFieldDecorator("hardware_serial_number_id", {
            rules: [{ required: true, message: "Please select your serial!" }],
            initialValue: this.state.hardwareSerialNumber,
          })(
            <Input
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineQrcode" />}
              placeholder={getCurrentlocaleText(
                "inode.add.hardware.serial.placeholder.text",
              )}
              disabled={true}
            />,
          )}
        </FormItem>
        {isSSHKeysVisible ? (
          <FormItem
            colon={false}
            label={
              <span
                className={"ssh-key"}
                title={getCurrentlocaleText("certificate.ssh_key.text")}
              >
                {getCurrentlocaleText("certificate.add.form.label.text")}
                <PopOverHelp
                  isHelpOnForm={true}
                  height={185}
                  width={475}
                  placement={"rightTop"}
                  helpData={{
                    title: getCurrentlocaleText(
                      "certificate.add.form.label.text",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: <GenerateSSHKeyLinkHelp />,
                      },
                    ],
                  }}
                />
              </span>
            }
            hasFeedback
          >
            {getFieldDecorator("ssh_key", {
              initialValue:
                this.props.formData &&
                this.props.formData.ssh_keys &&
                this.props.formData.ssh_keys[0]
                  ? this.props.formData.ssh_keys[0].id
                  : undefined,
              rules: [
                {
                  required: this.props.formData && this.props.formData.ssh_keys,
                  message: getCurrentlocaleText(
                    "certificate.inode.add.required.text",
                  ),
                },
              ],
            })(
              <CertificateSelect
                initialValue={
                  this.props.formData &&
                  this.props.formData.ssh_keys &&
                  this.props.formData.ssh_keys[0]
                    ? this.props.formData.ssh_keys[0].name
                    : undefined
                }
                setSelectVal={this.setSSHKeySelectVal}
                params={{
                  org_id: this.props.orgId,
                  own: true,
                  assigned: false,
                }}
                resource="inodes"
                cache={false}
                disabled={
                  this.props.action === "view" || isEditBlocked ? true : false
                }
              />,
            )}
          </FormItem>
        ) : null}
        <LocationInput {...this.props} />
        {isDefaultSSHKeyVisible ? (
          <Alert
            description={getCurrentlocaleText("default.sshkey.alert.text")}
            type="warning"
            style={{ marginBottom: 10 }}
          />
        ) : null}
        {isDefaultSSHKeyVisible ? (
          <Button
            type="primary"
            style={{ marginBottom: 30 }}
            onClick={this.downloadDefaultSSHKey.bind(this)}
            disabled={isEditBlocked ? true : false}
          >
            <span>
              <Icons type="ai" name="AiOutlineDownload" /> Download Default SSH
              Key{" "}
            </span>
          </Button>
        ) : null}
        {isCollapsePanelVisible && (
          <Collapse
            className="node-advanced-settings-collapse"
            style={{ marginBottom: 10 }}
          >
            <Panel
              header={
                <div className="panel-header-text" style={{ fontSize: 14 }}>
                  {getCurrentlocaleText("advanced.settings.text")}
                </div>
              }
              key="1"
            >
              <div>
                <Row>
                  {!this.state.unifiedCloudGateway &&
                    !this.state.isVirtual &&
                    !isClusterNode && (
                      <Col span={9}>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label={getCurrentlocaleText(
                                "inode.container.timezone.label",
                              )}
                              hasFeedback
                            >
                              {getFieldDecorator("container_timezone", {
                                initialValue: this.state.container_timezone,
                              })(
                                <TimezoneComponent
                                  placeholder={getCurrentlocaleText(
                                    "inode.container.timezone.label",
                                  )}
                                  initialValue={
                                    this.state.container_timezone
                                      ? this.state.container_timezone
                                      : ""
                                  }
                                  selectedValue={this.selectedTimezoneValue}
                                  defaultTimezone={"browser"}
                                  disabled={
                                    this.props.action === "view" ? true : false
                                  }
                                />,
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                        {this.state.container_timezone_change && (
                          <Col span={24}>
                            <Alert
                              style={{ marginBottom: 15 }}
                              showIcon
                              icon={
                                <Icon
                                  className="dad-warning"
                                  type="exclamation-circle"
                                />
                              }
                              message={getCurrentlocaleText(
                                "inode.container.timezone.help.text1",
                              )}
                              type="warning"
                            />
                          </Col>
                        )}
                      </Col>
                    )}
                  {isOneArmModeVisible && (
                    <Col span={15}>
                      <Row>
                        <Col span={24}>
                          <FormItem
                            colon={false}
                            label={
                              <span>
                                {getCurrentlocaleText(
                                  "inode.one_arm_mode.title.text",
                                ) + " :"}{" "}
                                <PopOverHelp
                                  isHelpOnForm={true}
                                  height={260}
                                  width={300}
                                  placement="right"
                                  helpData={{
                                    title: getCurrentlocaleText(
                                      "inode.one_arm_mode.title.text",
                                    ),
                                    data: [
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "onearmmode.field.help.text1",
                                        ),
                                      },
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "inode.one_arm_mode.help.text",
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </span>
                            }
                          >
                            {getFieldDecorator("one_arm_mode", {
                              initialValue: this.state.oneArmMode,
                            })(
                              <span>
                                <Switch
                                  style={{ marginTop: -5 }}
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  onChange={this.onOneArmModeChange}
                                  checked={this.state.oneArmMode}
                                  // disabled={
                                  //   this.props.action === "view" ||
                                  //   isEditBlocked
                                  //     ? true
                                  //     : false
                                  // }
                                  disabled={true}
                                />
                              </span>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {isUnifiedCloudGatewayVisible && (
                    <Col span={15}>
                      <Row>
                        <Col span={24}>
                          <FormItem
                            colon={false}
                            label={
                              <span>
                                {getCurrentlocaleText(
                                  "inode.unified_cloud_gateway.title.text",
                                ) + " :"}{" "}
                                <PopOverHelp
                                  isHelpOnForm={true}
                                  height={145}
                                  width={300}
                                  placement="right"
                                  helpData={{
                                    title: getCurrentlocaleText(
                                      "inode.unified_cloud_gateway.title.text",
                                    ),
                                    data: [
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "inode.unified.cloud.gateway.help.text",
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </span>
                            }
                          >
                            {getFieldDecorator("unified_cloud_gateway", {
                              initialValue: this.state
                                .unifiedCloudGatewayEnabled,
                            })(
                              <span>
                                <Switch
                                  style={{ marginTop: -5 }}
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  onChange={this.onUnifiedCloudGateway}
                                  checked={
                                    this.state.unifiedCloudGatewayEnabled
                                  }
                                  disabled={
                                    this.props.action === "view" ||
                                    isEditBlocked ||
                                    this.state.oneArmMode // Disable when oneArmMode is true
                                      ? true
                                      : false
                                  }
                                />
                              </span>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {this.checkForFeatureSupported("DATASAVER") && (
                    <Col span={24}>
                      <Row>
                        <Col span={9}>
                          <FormItem
                            colon={false}
                            label={
                              <span>
                                {getCurrentlocaleText(
                                  "inode.metered.connection.text",
                                ) + " :"}{" "}
                                <PopOverHelp
                                  isHelpOnForm={true}
                                  height={145}
                                  width={300}
                                  placement={"rightTop"}
                                  helpData={{
                                    title: getCurrentlocaleText(
                                      "inode.metered.connection.text",
                                    ),
                                    data: [
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "inode.metered.connection.help.text2",
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </span>
                            }
                          >
                            {getFieldDecorator("interface_mode", {
                              initialValue: this.state.statsMode,
                            })(
                              <span>
                                <Switch
                                  style={{ marginTop: -5 }}
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  onChange={this.onStatsModeChange}
                                  checked={this.state.statsMode}
                                  disabled={
                                    this.props.action === "view" ||
                                    isEditBlocked
                                      ? true
                                      : false
                                  }
                                />
                              </span>,
                            )}
                          </FormItem>
                        </Col>
                        <Col span={15}>
                          {this.state.statsMode && (
                            <FormItem
                              label={getCurrentlocaleText(
                                "inode.metered.connection.metrics.text",
                              )}
                            >
                              {getFieldDecorator("stat_mode", {
                                initialValue: this.state.intefaceTraffic,
                              })(
                                <InterfaceTrafficSelector
                                  val={this.state.intefaceTraffic}
                                  onChange={this.handleInterfaceTraffic}
                                  disabled={
                                    this.props.action === "view" ||
                                    isEditBlocked
                                      ? true
                                      : false
                                  }
                                />,
                              )}
                            </FormItem>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {/* THIS WILL RENDER ONLY FOR NON VIRTUAL PROFILES*/}
                  {isHeadlessModeVisible &&
                    this.checkForFeatureSupported("STANDALONE") && (
                      <Col span={24}>
                        <Row>
                          <Col span={9}>
                            <FormItem
                              colon={false}
                              label={
                                <span>
                                  {getCurrentlocaleText(
                                    "inode.headless_mode.title.text",
                                  ) + " :"}{" "}
                                  <PopOverHelp
                                    isHelpOnForm={true}
                                    height={275}
                                    width={300}
                                    placement="right"
                                    helpData={{
                                      title: getCurrentlocaleText(
                                        "org.form.headless.mode.label.text",
                                      ),
                                      data: [
                                        {
                                          subtitle: "",
                                          content: getCurrentlocaleText(
                                            "headless.help.message",
                                            {
                                              0: this.name,
                                            },
                                          ),
                                        },
                                        {
                                          subtitle: "",
                                          content: getCurrentlocaleText(
                                            "headless.help.message3",
                                            { 0: this.name },
                                          ),
                                        },
                                      ],
                                    }}
                                  />
                                </span>
                              }
                            >
                              {getFieldDecorator("headeless_mode", {
                                initialValue: this.state.headLessMode,
                              })(
                                <span>
                                  <Switch
                                    style={{ marginTop: -5 }}
                                    checkedChildren="Active"
                                    unCheckedChildren="Not Active"
                                    onChange={this.onHeadlessModeChange}
                                    checked={this.state.headLessMode}
                                    disabled={
                                      this.props.action === "view" ||
                                      isEditBlocked
                                        ? true
                                        : false
                                    }
                                  />
                                </span>,
                              )}
                            </FormItem>
                          </Col>
                          <Col span={15}>
                            {this.state.headLessMode && (
                              <FormItem
                                label={getCurrentlocaleText(
                                  "inode.headless_duration.title.text",
                                )}
                              >
                                {getFieldDecorator("max_headless_time", {
                                  initialValue: this.state.durationValue,
                                })(
                                  <DurationSelector
                                    onChange={this.onHeadlessDurationChange}
                                    minutesDuration={
                                      isDevelopmentMode() ? 5 : 60
                                    }
                                    minutesMinimum={
                                      isDevelopmentMode() ? 5 : 60
                                    }
                                    minutesMaximum={60}
                                    minutesStep={isDevelopmentMode() ? 5 : 0}
                                    hourDuration={1}
                                    hourMinimum={1}
                                    hourMaximum={24}
                                    hourStep={1}
                                    dayDuration={1}
                                    dayMinimum={1}
                                    dayMaximum={365}
                                    dayStep={1}
                                    disabled={
                                      this.props.action === "view" ||
                                      isEditBlocked
                                        ? true
                                        : false
                                    }
                                    Val={this.state.durationValue}
                                  />,
                                )}
                              </FormItem>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )}
                  {/* THIS WILL RENDER ONLY FOR NON VIRTUAL PROFILES*/}
                  {isTanDadModeVisible && (
                    <Col span={24}>
                      <Row>
                        <Col span={9}>
                          <FormItem
                            colon={false}
                            label={
                              <span>
                                {getCurrentlocaleText(
                                  "inode.tan_dad_mode.label.text",
                                ) + " :"}{" "}
                                <PopOverHelp
                                  isHelpOnForm={true}
                                  height={180}
                                  width={170}
                                  placement="right"
                                  helpData={{
                                    title: getCurrentlocaleText(
                                      "inode.tan_dad_mode.help.text",
                                    ),
                                    data: [
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "inode.tan_dad.help.message",
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </span>
                            }
                          >
                            {getFieldDecorator("tan_dad_mode", {
                              initialValue: this.state.tanDadMode,
                            })(
                              <span>
                                <Switch
                                  style={{ marginTop: -5 }}
                                  checkedChildren="Active"
                                  unCheckedChildren="Not Active"
                                  onChange={this.onTanDadModeChange}
                                  checked={this.state.tanDadMode}
                                  disabled={
                                    isClusterNode ||
                                    this.props.action === "view" ||
                                    isEditBlocked
                                      ? true
                                      : false
                                  }
                                />
                              </span>,
                            )}
                          </FormItem>
                        </Col>
                        <Col span={15}>
                          {this.state.tanDadMode && (
                            <FormItem
                              label={getCurrentlocaleText(
                                "inode.tan_dad.title.text",
                              )}
                            >
                              {getFieldDecorator("max_tandad_time", {
                                initialValue: this.state.tanDadDurationValue,
                              })(
                                <DurationSelector
                                  onChange={this.onTanDadModeDurationChange}
                                  minutesDuration={5}
                                  minutesMinimum={5}
                                  minutesMaximum={1440}
                                  minutesStep={1}
                                  hourDuration={1}
                                  hourMinimum={1}
                                  hourMaximum={24}
                                  hourStep={1}
                                  dayDuration={1}
                                  dayMinimum={1}
                                  dayMaximum={1}
                                  dayStep={1}
                                  disabled={
                                    isClusterNode ||
                                    this.props.action === "view" ||
                                    isEditBlocked
                                      ? true
                                      : false
                                  }
                                  Val={this.state.tanDadDurationValue}
                                />,
                              )}
                            </FormItem>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {this.state.isResourceLimitVisible &&
                    !this.state.unifiedCloudGateway &&
                    (this.props.AuthStore.IsPermitted("ORG:SUPPORT") ||
                      this.props.AuthStore.IsPermitted("SUPER:ADMIN")) &&
                    cGroupMemoryExisting && (
                      <Col span={24}>
                        <Row>
                          <Col span={10}>
                            <FormItem
                              colon={false}
                              label={
                                <span>
                                  {this.state.cGroupMemLoading && (
                                    <Spin
                                      indicator={loaderIcon}
                                      style={{ marginRight: 5 }}
                                    />
                                  )}
                                  {getCurrentlocaleText(
                                    "inode.c_group_mem.title.text",
                                  ) + " : "}
                                  <PopOverHelp
                                    isHelpOnForm={true}
                                    height={210}
                                    width={275}
                                    placement="right"
                                    helpData={{
                                      title: getCurrentlocaleText(
                                        "inode.c_group_mem.label.text",
                                      ),
                                      data: [
                                        {
                                          subtitle: "",
                                          content: getCurrentlocaleText(
                                            "inode.c_group_mem.title.message",
                                            {
                                              0: this.name,
                                            },
                                          ),
                                        },
                                        {
                                          subtitle: "",
                                          content: getCurrentlocaleText(
                                            "inode.c_group_mem.title.note",
                                            { 0: "Note: " },
                                          ),
                                        },
                                      ],
                                    }}
                                  />
                                </span>
                              }
                            >
                              {getFieldDecorator("c_group_memory", {
                                initialValue: this.state.cGroupMem,
                              })(
                                <Slider
                                  min={1}
                                  max={100}
                                  tooltipVisible={false}
                                  onChange={this.onChangecGroupMem}
                                  disabled={this.state.cGroupMemLoading}
                                ></Slider>,
                              )}
                            </FormItem>
                          </Col>
                          <Col
                            span={2}
                            style={{ marginTop: "47px", marginLeft: "5px" }}
                          >
                            {this.state.cGroupMem}%
                          </Col>
                        </Row>

                        {!this.state.cGroupMemConfigured && (
                          <Col span={12}>
                            <Alert
                              style={{ marginBottom: 15 }}
                              message={getCurrentlocaleText(
                                "inode.c_group_mem.title.not_config",
                              )}
                              type="warning"
                            />
                          </Col>
                        )}

                        {this.state.mem_resource_change && (
                          <Col span={12}>
                            <Alert
                              style={{ marginBottom: 15 }}
                              showIcon
                              icon={
                                <Icons
                                  type="ai"
                                  name="AiFillExclamationCircle"
                                />
                              }
                              message={getCurrentlocaleText(
                                "inode.c_group_mem.title.note",
                                { 0: "" },
                              )}
                              type="warning"
                            />
                          </Col>
                        )}
                      </Col>
                    )}
                  {this.props.AuthStore.IsPermitted("ORG:SUPPORT") && (
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col span={9}>
                              <FormItem
                                label={getCurrentlocaleText(
                                  "node.software.upgrade.text",
                                )}
                              >
                                {getFieldDecorator("upgrade_policy", {
                                  initialValue: this.state.softwareUpgrade,
                                })(
                                  <Switch
                                    checked={this.state.softwareUpgrade}
                                    style={{ marginTop: -5 }}
                                    onChange={this.changeSoftwareUpdate}
                                    checkedChildren="On"
                                    unCheckedChildren="Off"
                                    disabled={
                                      isClusterNode ||
                                      this.props.action === "view" ||
                                      (isEditBlocked &&
                                        !this.props.AuthStore.IsPermitted(
                                          "ORG:SUPPORT",
                                        ))
                                        ? true
                                        : false
                                    }
                                  />,
                                )}
                              </FormItem>
                            </Col>
                            <Col span={15}>
                              {this.state.softwareUpgrade && (
                                <FormItem
                                  label={getCurrentlocaleText(
                                    "org.form.upgrade.channel.label.text",
                                  )}
                                  colon={true}
                                >
                                  {getFieldDecorator("upgrade_policy_channel", {
                                    initialValue: !this.checkForFeatureSupported(
                                      "SOFTWAREUPGRADE",
                                    )
                                      ? "STABLE"
                                      : this.state.upgradeChannelSelected,
                                  })(
                                    <RadioGroup
                                      onChange={this.upgradeChannelOnSelect}
                                      size="small"
                                      disabled={
                                        isClusterNode ||
                                        (this.checkForFeatureSupported(
                                          "SOFTWAREUPGRADE",
                                        )
                                          ? this.props.action === "view"
                                            ? true
                                            : false
                                          : true)
                                      }
                                    >
                                      <RadioButton value={"ALPHA"}>
                                        {getCurrentlocaleText(
                                          "org.form.upgrade.channel.alpha.label.text",
                                        )}
                                      </RadioButton>
                                      <RadioButton value={"BETA"}>
                                        {getCurrentlocaleText(
                                          "org.form.upgrade.channel.beta.label.text",
                                        )}
                                      </RadioButton>
                                      <RadioButton value={"STABLE"}>
                                        {getCurrentlocaleText(
                                          "org.form.upgrade.channel.stable.label.text",
                                        )}
                                      </RadioButton>
                                    </RadioGroup>,
                                  )}
                                </FormItem>
                              )}
                            </Col>
                            {this.props.AuthStore.IsPermitted("ORG:SUPPORT") &&
                              this.state.softwareUpgrade && (
                                <Col span={12}>
                                  <FormItem
                                    label={getCurrentlocaleText(
                                      "inode.upgrade.after.label.text",
                                    )}
                                  >
                                    {getFieldDecorator("upgrade_after", {
                                      initialValue: this.state.upgradeAfter,
                                    })(
                                      <DatePicker
                                        disabledDate={this.disabledDate}
                                        showTime={{
                                          defaultValue: moment.tz(),
                                        }}
                                        style={{ width: 250 }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        placeholder="Upgrade After"
                                        onChange={this.onStartAfterChange}
                                        disabled={
                                          isClusterNode ||
                                          this.props.action === "view"
                                            ? true
                                            : false
                                        }
                                      />,
                                    )}
                                  </FormItem>
                                </Col>
                              )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {showThreatDetection && (
                    <Col span={24}>
                      <Row>
                        <Col span={9}>
                          <FormItem
                            colon={false}
                            label={
                              <span>
                                {getCurrentlocaleText("threat.detection.text")}
                                <PopOverHelp
                                  isHelpOnForm={true}
                                  height={50}
                                  width={230}
                                  placement={"right"}
                                  helpData={{
                                    data: [
                                      {
                                        subtitle: "",
                                        content: getCurrentlocaleText(
                                          "threat.detection.text.desc",
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </span>
                            }
                          >
                            {getFieldDecorator("ctd_threat_detection", {
                              initialValue: this.state.threatDetection,
                            })(
                              <Switch
                                checked={this.state.threatDetection}
                                style={{ marginTop: -5 }}
                                onChange={this.changeCtd}
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                disabled={
                                  isClusterNode ||
                                  this.props.action === "view" ||
                                  isEditBlocked
                                    ? true
                                    : false
                                }
                              />,
                            )}
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          {this.state.threatDetection && (
                            <FormItem
                              colon={true}
                              label={
                                <span>
                                  {getCurrentlocaleText(
                                    "threat.detection.sampling.text",
                                  )}
                                </span>
                              }
                            >
                              {getFieldDecorator("ctd_sampling_frequency", {
                                initialValue: this.state
                                  .samplingFrequencySelected,
                              })(
                                <>
                                  <Slider
                                    min={5000}
                                    max={100000}
                                    step={5000}
                                    tooltipVisible={false}
                                    marks={getSamplingFreqMarks()}
                                    defaultValue={
                                      this.state.samplingFrequencySelected
                                    }
                                    onChange={this.samplingFrequencyOnSelect}
                                    size="small"
                                    disabled={
                                      isClusterNode ||
                                      this.props.action === "view" ||
                                      isEditBlocked
                                        ? true
                                        : false
                                    }
                                  />
                                  <div className="">
                                    One sample will be processed for every{" "}
                                    <b className="px-5">
                                      {this.state.samplingFrequencySelected}
                                    </b>{" "}
                                    packets.
                                  </div>
                                  <div
                                    className="text-muted"
                                    style={{ lineHeight: "10px" }}
                                  >
                                    <small>
                                      {getCurrentlocaleText(
                                        "threat.detection.sampling.note",
                                      )}
                                    </small>
                                  </div>
                                </>,
                              )}
                            </FormItem>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {this.props.AuthStore.IsPermitted("DISCOVERY:ADMIN") &&
                    !this.state.isVirtual &&
                    !this.state.unifiedCloudGateway && (
                      <Col span={24}>
                        <FormItem
                          label={
                            <>
                              {getCurrentlocaleText("device.discovery.text")}
                              <div className="beta ml-15 d-inline-block">
                                <Icons
                                  type="fa"
                                  name="FaRocket"
                                  className="beta-icon"
                                />
                                {getCurrentlocaleText("beta.version")}
                              </div>
                            </>
                          }
                        >
                          {getFieldDecorator("device_discovery", {
                            initialValue: this.state.deviceDiscovery,
                          })(
                            <Switch
                              checked={this.state.deviceDiscovery}
                              onChange={this.changeDeviceDiscovery}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              disabled={
                                this.props.action === "view" ||
                                isEditBlocked ||
                                isClusterNode
                                  ? true
                                  : false
                              }
                            />,
                          )}
                        </FormItem>
                      </Col>
                    )}

                  {isMultinicVisible && (
                    <Col span={24}>
                      <FormItem
                        colon={false}
                        label={
                          <span>
                            {getCurrentlocaleText("multinic.mode.text") + " :"}{" "}
                            <PopOverHelp
                              isHelpOnForm={true}
                              height={220}
                              width={250}
                              placement="right"
                              helpData={{
                                title: getCurrentlocaleText(
                                  "multinic.mode.text",
                                ),
                                data: [
                                  {
                                    subtitle: "",
                                    content: getCurrentlocaleText(
                                      "multinic.mode.help.text",
                                    ),
                                  },
                                  {
                                    subtitle: "",
                                    content: getCurrentlocaleText(
                                      "multinic.mode.network.update.help.text",
                                    ),
                                  },
                                ],
                              }}
                            />
                          </span>
                        }
                      >
                        {getFieldDecorator("is_multinic_mode", {
                          initialValue: this.state.isMultiNICMode,
                        })(
                          <Switch
                            checked={this.state.isMultiNICMode}
                            onChange={checked => {
                              let formData = this.props.formData;
                              // Multnic Mode change warning message check
                              if (
                                (formData.multinic_mode &&
                                  formData.multinic_mode.enable) != checked
                              ) {
                                this.setState({
                                  isMultiNicChanged: true,
                                });
                              } else {
                                this.setState({
                                  isMultiNicChanged: false,
                                });
                              }
                              this.setState({ isMultiNICMode: checked });
                            }}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            disabled={true}
                            // disabled={
                            //   this.props.action === "view" ||
                            //   isEditBlocked
                            //     ? true
                            //     : false
                            // }
                          />,
                        )}
                      </FormItem>
                    </Col>
                  )}
                  {isHWMVisible && (
                    <>
                      <Col span={12}>
                        <FormItem
                          colon={false}
                          label={
                            <span>
                              {getCurrentlocaleText(
                                "inode.edit.hw.monitoring",
                              ) + " :"}{" "}
                              <PopOverHelp
                                isHelpOnForm={true}
                                height={130}
                                width={170}
                                placement="right"
                                helpData={{
                                  title: getCurrentlocaleText(
                                    "inode.hwm.help.text",
                                  ),
                                  data: [
                                    {
                                      subtitle: "",
                                      content: getCurrentlocaleText(
                                        "inode.hwm.help.message",
                                      ),
                                    },
                                  ],
                                }}
                              />
                            </span>
                          }
                        >
                          {getFieldDecorator("inode_hw_monitoring", {
                            initialValue: this.state.HwMonitoring,
                          })(
                            <Switch
                              checked={this.state.HwMonitoring}
                              onChange={this.onHWMModeChange}
                              style={{ marginTop: -5 }}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              disabled={
                                this.props.action === "view" || isEditBlocked
                              }
                            />,
                          )}
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        {this.state.HwMonitoring && (
                          <FormItem
                            label={getCurrentlocaleText("inode.hwm.title.text")}
                          >
                            {getFieldDecorator("max_hwm_time", {
                              initialValue: this.state.HWMDurationValue,
                            })(
                              <DurationSelector
                                onChange={this.onHWMDurationChange}
                                minutesDuration={5}
                                minutesMinimum={5}
                                minutesMaximum={1440}
                                minutesStep={1}
                                hourDuration={1}
                                hourMinimum={1}
                                hourMaximum={24}
                                hourStep={1}
                                dayDuration={1}
                                dayMinimum={1}
                                dayMaximum={1}
                                dayStep={1}
                                disabled={
                                  this.props.action === "view" || isEditBlocked
                                    ? true
                                    : this.inodeModel.HWMOldVal &&
                                      this.inodeModel.HWMOldVal.Duration
                                    ? true
                                    : false
                                }
                                Val={this.state.HWMDurationValue}
                              />,
                            )}
                          </FormItem>
                        )}
                      </Col>
                    </>
                  )}
                  {/**configure and unconfigure representation network automation */}
                  {isAutoRepnetVisible &&
                    this.state.isVirtual &&
                    !this.state.isVirtualEdge && (
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <FormItem
                                  {...{
                                    labelCol: { span: 9 },
                                    wrapperCol: { span: 6 },
                                  }}
                                  label={
                                    <span
                                      title={getCurrentlocaleText(
                                        "inode.cloud.route.repNet.automation.field.text",
                                      )}
                                    >
                                      {getCurrentlocaleText(
                                        "inode.cloud.route.repNet.automation.field.text",
                                      )}
                                      <PopOverHelp
                                        isHelpOnForm={true}
                                        height={160}
                                        width={260}
                                        placement="right"
                                        helpData={{
                                          title: getCurrentlocaleText(
                                            "inode.cloud.route.repNet.automation.field.text",
                                          ),
                                          data: [
                                            {
                                              subtitle: "",
                                              content: getCurrentlocaleText(
                                                "inode.cloud.route.field.help.text",
                                              ),
                                            },
                                          ],
                                        }}
                                      />
                                    </span>
                                  }
                                >
                                  {getFieldDecorator(
                                    "is_representation_network_automation",
                                    {
                                      initialValue: this.state
                                        .isRepresentationNetworkAutomation,
                                    },
                                  )(
                                    <Switch
                                      checked={
                                        this.state
                                          .isRepresentationNetworkAutomation
                                      }
                                      onChange={this.getCloudroute}
                                      checkedChildren="On"
                                      unCheckedChildren="Off"
                                      disabled={
                                        this.props.action === "view" ||
                                        isEditBlocked ||
                                        !this.props.AuthStore.IsPermitted(
                                          "CLOUDROUTE:ADMIN",
                                        )
                                      }
                                    />,
                                  )}
                                </FormItem>
                              </Col>
                              {this.state.isRepresentationNetworkAutomation && (
                                <Col span={10}>
                                  <FormItem
                                    {...{
                                      labelCol: { span: 8 },
                                      wrapperCol: { span: 12 },
                                    }}
                                    label={
                                      <span
                                        title={getCurrentlocaleText(
                                          "inode.cloud.route.cidr.field.text",
                                        )}
                                      >
                                        {getCurrentlocaleText(
                                          "inode.cloud.route.cidr.field.text",
                                        )}
                                      </span>
                                    }
                                  >
                                    {getFieldDecorator("cidr", {
                                      rules: [
                                        {
                                          required: true,
                                          message: "Please enter CIDR.",
                                        },
                                        {
                                          validator: this.checkCidr,
                                        },
                                      ],
                                      initialValue: this.state.suggestedCidr
                                        ? null
                                        : this.state.cidr,
                                    })(
                                      <Input
                                        prefix={
                                          <Icons
                                            type="ai"
                                            name="AiOutlineCalculator"
                                          />
                                        }
                                        placeholder={
                                          this.state.suggestedCidr
                                            ? this.state.cidr
                                            : getCurrentlocaleText(
                                                "inode.cloud.route.cidr.placeholder.text",
                                              )
                                        }
                                        disabled={
                                          this.props.action === "view" ||
                                          isEditBlocked ||
                                          !this.props.AuthStore.IsPermitted(
                                            "CLOUDROUTE:ADMIN",
                                          )
                                        }
                                        onChange={this.onChange}
                                        onFocus={() => {
                                          this.props.form.setFieldsValue({
                                            cidr:
                                              this.state.clearCidrValue === ""
                                                ? ""
                                                : this.state.cidr,
                                          });
                                        }}
                                      />,
                                    )}
                                  </FormItem>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )}
                </Row>
              </div>
            </Panel>
          </Collapse>
        )}
        <FormErrorMessage />
        <Modal
          title={getCurrentlocaleText("inode.one_arm_mode.warning.modal.title")}
          visible={this.state.showOneArmModeWarning}
          onOk={this.hideWarningModal}
          onCancel={this.resetOneArmMode}
          closable={false}
          maskClosable={false}
          okText={"Yes - Switch Mode"}
          cancelText={"No - Don't Switch Mode"}
        >
          {getCurrentlocaleText("inode.one_arm_mode.warning.modal.text")}
        </Modal>
      </Form>
    );
  }
}

const EditiNodeForm = Form.create()(EditNodeForm);
export default EditiNodeForm;
